import { FC, useState, useEffect, Fragment } from "react"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import barChartLoading from "../../EntranceGates/assets/barChartLoading.mp4"

import styles from "./TotalDownTime.module.scss"

interface Props {
  totalDownTime: Duration
  totalDuration: Duration
  isLoading?: boolean
}
const TotalDownTime: FC<Props> = ({ totalDuration, totalDownTime, isLoading }) => {
  const [downTimePercentage, setDownTimePercentage] = useState(0)

  //calculating total down time percentage over total picked time range
  useEffect(() => {
    // converting both down time and total time range to milliseconds (to calculate a ratio)
    const downTimeInMilliseconds = moment.duration(totalDownTime, "seconds").asMilliseconds()
    const totalTimeInMilliseconds = moment.duration(totalDuration, "seconds").asMilliseconds()
    const downTimeInPercentage = totalTimeInMilliseconds ? downTimeInMilliseconds / totalTimeInMilliseconds : 0

    setDownTimePercentage(downTimeInPercentage * 100)
  }, [totalDownTime, totalDuration])

  const noData = (!totalDuration && !totalDownTime) || (totalDownTime && downTimePercentage <= 0)

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.loadingContainer}>
          <Grid item>
            <video autoPlay={true} loop={true} width="300" height="305">
              <source src={barChartLoading} type="video/mp4" />
            </video>
          </Grid>
        </Grid>
      ) : (
        <CircularProgressbarWithChildren
          value={downTimePercentage}
          styles={buildStyles({
            textColor: "var(--grey-background-2)",
            strokeLinecap: "round",
            pathColor:
              downTimePercentage > 0 && downTimePercentage <= 40
                ? "var(--green-background-1)"
                : downTimePercentage > 40 && downTimePercentage <= 75
                ? "var(--yellow-background-1)"
                : downTimePercentage > 75 && downTimePercentage <= 100
                ? "var(--red-background-1)"
                : "var(--indigo-background-2)",
            trailColor:
              downTimePercentage > 0 && downTimePercentage <= 40
                ? "var(--green-background-2)"
                : downTimePercentage > 40 && downTimePercentage <= 75
                ? "var(--yellow-background-2)"
                : downTimePercentage > 75 && downTimePercentage <= 100
                ? "var(--red-background-2)"
                : "var(--indigo-background-2)",
          })}
        >
          <div className={styles.downTimeStatsWrapper}>
            <Typography className={styles.header} variant="label" variantColor={2} gutterBottom>
              Total Down Time
            </Typography>
            {noData ? (
              <Typography variant="a" variantColor={2}>
                No Down Time Data
              </Typography>
            ) : (
              <Fragment>
                <Typography variant="a" variantColor={2}>
                  {totalDownTime.days && totalDownTime.days > 0 ? (
                    <Fragment>
                      <span className={styles.numeric}> {totalDownTime.days} </span>
                      days{" "}
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <span className={styles.numeric}> {totalDownTime.hours}</span>hrs{" "}
                  <span className={styles.numeric}> {totalDownTime.minutes}</span>mins{" "}
                </Typography>
                <Typography variant="span" variantColor={2} className={styles.totalDuration}>
                  out of {moment.duration(totalDuration, "seconds").asHours().toFixed(0)} hour(s)
                </Typography>
              </Fragment>
            )}
          </div>
        </CircularProgressbarWithChildren>
      )}
    </div>
  )
}

export default TotalDownTime
