import { Fragment, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp"
import NotificationsActiveIcon from "@mui/icons-material/Notifications"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import { CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material"

import {
  Button,
  Menu,
  NotificationBadge,
  NotificationUtils,
  RoundedTab,
  Skeleton,
  Tabs,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../API/VisionAPI"
import NoNotifications from "../../../assets/noListData.svg?react"
import { PaginatedNotifications } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../../utils/genericHelpers"
import NotificationCard from "./NotificationCard"

import styles from "./NotificationsMenu.module.scss"

type NotificationCount = definitions["NotificationCount"]
type Notification = definitions["Notification"]

const loadingPlaceholders = new Array(12).fill(null).map((_r, i) => (
  <Grid item xs={12} key={i}>
    <Skeleton variant="rectangular" height={70} width="auto" />
  </Grid>
))

/**
 * Notifications Types
 *
 * "CD" = Camera Down
 *
 * "CVC" = Camera View Change
 *
 * "UC" = Unserved Customer
 *
 * "ND" = Node Down
 *
 */

type TabValue = "ND" | "CVC" | "CD" | "unread" | "all"

const NotificationsMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLDivElement | undefined>(null)
  const [activeTab, setActiveTab] = useState<TabValue>("all")
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false)

  const anchoringRef = useRef(null)

  const queryClient = useQueryClient()
  const notificationsContentRef = useRef<HTMLDivElement>(null)
  const isMenuOpen = Boolean(menuAnchorEl)

  const theme = useTheme()
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const { data: notificationsCount } = useQuery<NotificationCount>(
    "fetchNotificationsCount",
    VisionAPI.fetchNotificationsCount,
    {
      refetchInterval: 60000,
    }
  )

  const {
    data: paginatedNotifications,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<PaginatedNotifications, AxiosError>(
    ["fetchNotifications", activeTab],
    ({ queryKey, pageParam = 1 }) =>
      VisionAPI.fetchNotifications({
        page: pageParam,
        read: queryKey[1] === "unread" ? false : undefined,
        type: ["CVC", "CD", "ND"].includes(queryKey[1] as string) ? activeTab : undefined,
      }),
    {
      getNextPageParam: (lastPage: PaginatedNotifications) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
      refetchInterval: 30000,
      enabled: isMenuOpen,
    }
  )

  const { mutate: handleReadNotification } = useMutation(
    ({ notificationId }: { notificationId?: number }) => VisionAPI.readNotification(notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchNotifications")
        queryClient.invalidateQueries("fetchNotificationsCount")
      },
    }
  )

  const { mutate: handleMarkAllAsRead, isLoading: isMarkingAllAsRead } = useMutation(VisionAPI.markAllAsRead, {
    onSuccess: () => {
      NotificationUtils.toast("All notifications have been marked as read successfully", {
        snackBarVariant: "positive",
      })
      queryClient.invalidateQueries("fetchNotifications")
      queryClient.invalidateQueries("fetchNotificationsCount")
    },
  })

  const handleCloseNotificationsMenu = (): void => {
    setMenuAnchorEl(null)
    setShouldShowScrollButton(false)
    setActiveTab("all")
  }

  const handleToggleNotificationMenu = () => {
    if (isMenuOpen) {
      handleCloseNotificationsMenu()
    } else {
      setMenuAnchorEl(anchoringRef.current)
    }
  }
  const scrollToTop = () => {
    if (notificationsContentRef.current) {
      notificationsContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  const handleScroll = () => {
    if (notificationsContentRef?.current) {
      const scrollTop = notificationsContentRef?.current?.scrollTop
      // Show button if scrolled down
      setShouldShowScrollButton(scrollTop > 0)
    }
  }

  const tabConfig: { label: string; value: TabValue }[] = [
    { label: "All", value: "all" },
    { label: "Unread", value: "unread" },
    { label: xsScreen ? "CD" : "Cameras down", value: "CD" },
    { label: xsScreen ? "CVC" : "Cameras change", value: "CVC" },
    { label: xsScreen ? "ND" : "Nodes down", value: "ND" },
  ]

  return (
    <Fragment>
      <div className={styles.notificationIcon} onClick={(event) => handleToggleNotificationMenu()}>
        <NotificationBadge NotificationsCount={notificationsCount?.count} variant="dot">
          {isMenuOpen ? (
            <NotificationsActiveIcon className={`${styles.notificationIcon} ${styles.activeNotification}`} />
          ) : (
            <NotificationsNoneOutlinedIcon className={styles.notificationIcon} />
          )}
        </NotificationBadge>
      </div>

      <div
        ref={anchoringRef}
        className={styles.notificationMenuAnchor}
        style={{ visibility: isMenuOpen ? "visible" : "hidden" }}
      />

      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseNotificationsMenu}
        menuMaxContent
        className={styles.notificationsMenu}
        placement="auto-end"
      >
        <div className={styles.header}>
          <div className={styles.titleAndCount}>
            <Typography variant="h2-regular" variantColor={2}>
              Notifications
            </Typography>
            <NotificationBadge NotificationsCount={notificationsCount?.count} />
          </div>
          <Button onClick={() => handleMarkAllAsRead()} disabled={notificationsCount?.count === 0}>
            {isMarkingAllAsRead && <CircularProgress size={16} />}
            Mark all as read
          </Button>
        </div>
        <Tabs rounded activeTab={activeTab} className={styles.tabs}>
          {tabConfig.map(({ label, value }) => (
            <RoundedTab
              key={value}
              label={label}
              onClick={() => setActiveTab(value)}
              value={value}
              selected={activeTab === value}
              id={value}
            />
          ))}
        </Tabs>
        {isLoading ? (
          <Grid container spacing={2} className={styles.content}>
            {loadingPlaceholders}
          </Grid>
        ) : paginatedNotifications && paginatedNotifications?.pages?.length > 0 ? (
          <div
            className={styles.content}
            ref={notificationsContentRef}
            onScroll={handleScroll}
            id="notifications-container"
          >
            <InfiniteScroll
              dataLength={paginatedNotifications?.pages.reduce((acc, page) => acc + page.results.length, 0)}
              hasMore={hasNextPage ? true : false}
              next={fetchNextPage}
              loader={<CircularProgress className={styles.loadingMoreNotifications} />}
              scrollableTarget="notifications-container"
              endMessage={
                paginatedNotifications.pages[0].count > 0 ? (
                  <div className={styles.scrollEnd}>
                    <Typography variant="h3-regular" align="center">
                      You reached the bottom
                    </Typography>
                  </div>
                ) : (
                  <div className={styles.notificationPlaceholderWrapper}>
                    <NoNotifications />
                    <div>
                      <Typography variant="h3-regular">No notifications yet</Typography>
                    </div>
                  </div>
                )
              }
              scrollThreshold={0.2}
            >
              {paginatedNotifications?.pages.map((page, i) =>
                page.results.map((notification: Notification, i: number) => (
                  <NotificationCard
                    notification={notification}
                    handleCloseNotificationsMenu={handleCloseNotificationsMenu}
                    handleReadNotification={handleReadNotification}
                  />
                ))
              )}
              <div className={`${styles.scrollToTopCTA} ${shouldShowScrollButton && styles.showBtn}`}>
                <Button
                  startIcon={<ArrowCircleUpIcon className={styles.arrowUp} fontSize="small" />}
                  onClick={scrollToTop}
                  variant="secondary"
                  size="regular"
                >
                  Scroll top
                </Button>
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          <div className={styles.notificationPlaceholderWrapper}>
            <NoNotifications />
            <div>
              <Typography variant="h3-regular">No notifications yet</Typography>
            </div>
          </div>
        )}
      </Menu>
    </Fragment>
  )
}
export default NotificationsMenu
