import { FC } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogTitle, DialogContent, CircularProgress } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"

import styles from "./WarningDialog.module.scss"

interface Props {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  content?: string
  confirmationText?: string
  actionTitle?: string
  isDangerousAction?: boolean
  cancelTitle?: string
  dialogTitle?: string
  isLoading?: boolean
}

const WarningDialog: FC<Props> = ({
  onCancel,
  onConfirm,
  isOpen,
  dialogTitle,
  content,
  confirmationText,
  actionTitle,
  cancelTitle,
  isLoading,
  isDangerousAction,
}) => {
  const confirmationButtonVariant =
    isDangerousAction || actionTitle === "Delete" || confirmationText ? "dangerous" : "primary"

  return (
    <Dialog
      onClick={(event) => event.stopPropagation()}
      maxWidth="xs"
      open={isOpen}
      disableEscapeKeyDown
      onClose={onCancel}
      aria-labelledby="alert-dialog"
      aria-describedby="alert-dialog-close-entity-creation"
      classes={{
        paper: styles.wrapper,
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          padding: 0,
        }}
      >
        <div className={styles.header}>
          <Typography variant="h2-bold">{dialogTitle || "Warning"}</Typography>
          <CloseIcon onClick={onCancel} className={styles.iconContainer} />
        </div>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "10px 0px",
          paddingTop: "10px !important",
        }}
      >
        <Typography variant="p">
          {content || (confirmationText && `Are you sure you want to ${confirmationText}`)}
        </Typography>
      </DialogContent>
      <div className={styles.actionButton}>
        <Button onClick={onConfirm} variant={confirmationButtonVariant} size="large" fullWidth>
          {actionTitle || "Confirm"}
          {isLoading && <CircularProgress size={20} style={{ marginLeft: 4 }} />}
        </Button>
        <Button onClick={onCancel} variant="ghost" size="large" fullWidth>
          {cancelTitle || "Cancel"}
        </Button>
      </div>
    </Dialog>
  )
}
export default WarningDialog
