import React, { useState, FC, useEffect } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Chip, Typography } from "@synapse-analytics/synapse-ui"
import { Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import { useBranchesStore } from "../../../store"
import { definitions } from "../../../types/Generated/apiTypes"
import CorridorCameraBreakdown from "./SingleCorridor/CorridorCameraBreakdown."
import CorridorOverTime from "./SingleCorridor/CorridorOverTime"

import styles from "./SingleCorridor.module.scss"

type CounterLogs = definitions["CounterLogs"]
type CorridorCameraCount = definitions["CorridorCameraCount"]
type Corridor = definitions["Corridor"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  interval: Duration
  refs: any[]
  timeGrain: "hour" | "day"
  shouldIncludeStaff: boolean
}

const SingleCorridor: FC<Props> = ({ startDate, endDate, timeGrain, shouldIncludeStaff, interval, refs }) => {
  const [selectedCorridor, setSelectedCorridor] = useState<Corridor>()
  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const { data: corridorEntities, isLoading: corridorEntitiesLoading } = useQuery<Corridor[], AxiosError>(
    ["fetchCorridorEntities", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchCorridors({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )
  const { data: singleCorridorLogs, isLoading: singleCorridorLogsLoading } = useQuery<CounterLogs[]>(
    [
      "fetchCorridorsLogs",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      timeGrain,
      selectedCorridor?.id,
      selectedBranch,
      shouldIncludeStaff,
    ],

    ({ queryKey }) =>
      VisionAPI.fetchCorridorsCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        entity_ids: queryKey[4] as number,
        branch: queryKey[5] as number,
        include_staff: queryKey[6] as boolean,
      }),
    {
      enabled: [
        !!endDate,
        corridorEntities && corridorEntities?.length > 0,
        !!selectedCorridor?.id,
        !!selectedBranch,
      ].every((check) => check === true),
    }
  )

  const { data: cameraBreakdownLogs, isLoading: cameraBreakdownLogsLoading } = useQuery<CorridorCameraCount[]>(
    [
      "fetchCorridorCameraBreakdownLogs",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      shouldIncludeStaff,
      selectedCorridor?.id,
      selectedBranch,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCorridorsCameraBreakdownStats(queryKey[4] as number, {
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        branch: queryKey[5] as number,
      }),
    {
      enabled: [
        !!endDate,
        !!selectedBranch,
        corridorEntities && corridorEntities?.length > 0,
        !!selectedCorridor?.id,
      ].every((check) => check === true),
    }
  )

  // handling clicking on one of the corridor filters
  const handleSelectCategory = (corridor: Corridor) => {
    //checking whether clicking on another category option or just toggling (unselecting)
    if (corridor.name !== selectedCorridor?.name) {
      setSelectedCorridor(corridor)
    }
  }

  useEffect(() => {
    if (!corridorEntitiesLoading && corridorEntities && corridorEntities[0]) {
      setSelectedCorridor(Object.values(corridorEntities)[0])
    }
  }, [corridorEntities, corridorEntitiesLoading])

  let loadingChips = new Array(6).fill(null).map((_, i) => (
    <Grid item md={2} key={i}>
      <Skeleton variant="rectangular" height={26} width="auto" />
    </Grid>
  ))
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="a" gutterBottom variantColor={2} tooltip="Analytics of selected Corridor">
          Single Corridor Details
        </Typography>
      </div>
      <Typography variant="span" gutterBottom variantColor={2}>
        Corridors
      </Typography>
      <Grid container spacing={1}>
        {corridorEntitiesLoading ? (
          loadingChips
        ) : corridorEntities && corridorEntities.length > 0 ? (
          corridorEntities?.map((corridor: any, index: number) => (
            <Grid item key={index}>
              <Chip
                clickable
                onClick={() => handleSelectCategory(corridor)}
                isSelected={selectedCorridor?.name === corridor.name}
                disabled={singleCorridorLogsLoading}
              >
                {corridor.name}
              </Chip>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography variant="span" variantColor={2}>
              No corridors found
            </Typography>
          </Grid>
        )}
      </Grid>
      <CorridorOverTime
        selectedCorridor={selectedCorridor}
        timeGrain={timeGrain}
        interval={interval}
        loading={singleCorridorLogsLoading}
        logsData={singleCorridorLogs}
        reference={refs[0]}
      />
      <CorridorCameraBreakdown
        selectedCorridor={selectedCorridor}
        loading={cameraBreakdownLogsLoading}
        logsData={cameraBreakdownLogs}
        reference={refs[1]}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  )
}

export default SingleCorridor
