// https://www.freecodecamp.org/news/build-a-react-hooks-front-end-app-with-routing-and-authentication/
import axios from "axios"
import jwt_decode from "jwt-decode"

import { queryClient } from "../index"
import { setSelectedBranch } from "../store"
import { storeApi } from "../store"

export function getAuthBaseUrl() {
  const [apiProtocol, apiHostname, apiPort] = [
    storeApi.getState().apiProtocol,
    storeApi.getState().apiHostname,
    storeApi.getState().apiPort,
  ]
  return `${apiProtocol}://${apiHostname}:${apiPort}`
}

// This class contains both Auth and API calls, should be seperated moving on
class Auth {
  constructor() {
    this.axios_obj = axios.create({
      responseType: "json",
    })
  }

  getUsername = () => localStorage.getItem("username") || null

  getUserId = () => localStorage.getItem("user_id") || null

  getFirstName = () => localStorage.getItem("first_name") || null

  getLastName = () => localStorage.getItem("last_name") || null

  getFullName = () => `${this.getFirstName() || ""} ${this.getLastName() || ""}`

  getPermissions = () => JSON.parse(localStorage.getItem("permissions")) || null

  getPeopleCountPermission = () =>
    localStorage.getItem("permissions")?.includes("analytics_can_access_people_count") || false

  getMissingDataPermission = () =>
    localStorage.getItem("permissions")?.includes("analytics_can_access_missing_data") || false

  getCamerasPermission = () => localStorage.getItem("permissions")?.includes("admin_can_access_camera") || false

  getUsersPermission = () => localStorage.getItem("permissions")?.includes("admin_can_access_users") || false

  getHeatmapPermission = () => localStorage.getItem("permissions")?.includes("analytics_can_access_heatmap") || false

  getFloorPermission = () => localStorage.getItem("permissions")?.includes("admin_can_access_floor") || false

  getCarsAdminPermission = () => localStorage.getItem("permissions")?.includes("admin_can_access_cars") || false

  getCarsAnalyticsPermission = () => localStorage.getItem("permissions")?.includes("analytics_can_access_cars") || false

  getCarsLogsSecurityPermission = () =>
    localStorage.getItem("permissions")?.includes("security_can_access_cars") || false

  getMonitorPermission = () => localStorage.getItem("permissions")?.includes("admin_can_access_system_status") || false

  getRegionsPermission = () => localStorage.getItem("permissions")?.includes("admin_can_access_entities") || false

  getAPIIntegrationPermission = () =>
    localStorage.getItem("permissions")?.includes("admin_can_access_api_token") || false

  getCampaignPermission = () => localStorage.getItem("permissions")?.includes("marketing_can_access_campaign") || false

  getSecurityEventsPermissions = () =>
    localStorage.getItem("permissions")?.includes("security_can_access_events") || false

  getSecurityCarsPermissions = () => localStorage.getItem("permissions")?.includes("security_can_access_cars") || false

  getSecurityCameraStreamPermissions = () =>
    localStorage.getItem("permissions")?.includes("security_can_access_camera_stream") || false

  getMcpPermissions = () => localStorage.getItem("permissions")?.includes("analytics_can_access_mcp") || false

  getCategoriesPermissions = () => localStorage.getItem("permissions")?.includes("admin_can_access_categories") || false

  setAccessToken = (access_token) => localStorage.setItem("access_token", access_token)

  getAccessToken = () => localStorage.getItem("access_token") || null

  getRefreshToken = () => localStorage.getItem("refresh_token") || null

  getAuthHeaderValue() {
    return "Bearer " + this.getAccessToken()
  }

  getSelectedBranch = () => localStorage.getItem("selected_branch") || null

  checkIfRefreshTokenIsValid = () => {
    let currentTime = Date.now() / 1000
    let expiryDate = localStorage.getItem("refresh_expiry_date")
    return currentTime < expiryDate
  }

  getAuthHeader() {
    return { headers: { Authorization: "Bearer " + this.getAccessToken() } }
  }

  resetSelectedBranch = () => {
    localStorage.removeItem("selected_branch")
    setSelectedBranch("")
  }

  async login(credentials) {
    try {
      let res = await this.axios_obj.post(getAuthBaseUrl() + "/api/token/", credentials)
      let access_token = res.data.access
      localStorage.setItem("access_token", access_token)
      let refresh_token = res.data.refresh
      localStorage.setItem("refresh_token", refresh_token)
      const access_payload = jwt_decode(access_token)

      localStorage.setItem("permissions", JSON.stringify(access_payload.permissions))
      localStorage.setItem("access_expiry_date", access_payload.exp)
      const refresh_payload = jwt_decode(refresh_token)
      localStorage.setItem("refresh_expiry_date", refresh_payload.exp)
      localStorage.setItem("username", access_payload.username)
      localStorage.setItem("user_id", access_payload.user_id)
      localStorage.setItem("first_name", access_payload.first_name)
      localStorage.setItem("last_name", access_payload.last_name)
      localStorage.setItem("subscription_end", access_payload.subscription_end)
      // if there is a stored branch in local storage => remove it.
      if (this.getSelectedBranch()) {
        this.resetSelectedBranch()
      }

      document.cookie = `authorization=${access_token ? access_token : this.getAccessToken()}; path=/`
    } catch (e) {
      throw e
    }
  }

  logOut(redirect = false, navigate) {
    localStorage.clear()
    this.resetSelectedBranch()

    // Clear cache
    queryClient.clear()

    document.cookie = "authorization=; path=/; Max-Age=-99999999;"

    if (redirect && navigate) {
      // has to be imported for navigation to work as expected
      navigate("/login", { replace: true })
    }
  }

  setSelectedBranch = (branchId) => {
    localStorage.setItem("selected_branch", branchId)
  }

  isAuthenticated = () => {
    return this.checkIfRefreshTokenIsValid()
  }
  subscriptionEnded = () => {
    return localStorage.getItem("subscription_end") === "true" ? true : false
  }
}
const auth = new Auth()
export default auth
