import React, { useEffect } from "react"
import { useMutation } from "react-query"
import { useNavigate, useLocation } from "react-router-dom"

import { CircularProgress, Paper, Grid, Box } from "@mui/material"

import { InputText, InputPassword, Button, Typography } from "@synapse-analytics/synapse-ui"
import { useFormik } from "formik"
import * as Yup from "yup"

import LoginArt from "../../assets/loginPage.svg?react"
import { useCredentialsStore } from "../../store"
import Auth from "../../utils/auth"

import styles from "./Login.module.scss"

type LocationProps = {
  state: {
    from: Location
  }
}
export default function Login({ redirectPath }: { redirectPath: string }): React.ReactElement {
  const [loginError, setLoginError] = useCredentialsStore(
    (state: { loginError: boolean; setLoginError: (loginError: any) => void }) => [
      state.loginError,
      state.setLoginError,
    ]
  )
  const [networkError, setNetworkError] = useCredentialsStore(
    (state: { networkError: boolean; setNetworkError: (networkError: any) => void }) => [
      state.networkError,
      state.setNetworkError,
    ]
  )

  const navigate = useNavigate()
  const location = useLocation() as LocationProps
  const redirectToPath = location?.state?.from
    ? `${location?.state?.from?.pathname}${location?.state?.from?.search}`
    : redirectPath

  //change title of page to match rendered component
  useEffect(() => {
    document.title = "Azka Vision | Login"
    document.body.setAttribute("style", "background: #e0eaff;")

    //when component will unmount > change the title to the default again
    return () => {
      document.title = "Azka Vision"
      document.body.setAttribute("style", "")
    }
    //eslint-disable-next-line
  }, [])
  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  })

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => mutate(values),
  })

  const loginSubmit = async (values: { username: string; password: string }) => {
    try {
      return await Auth.login({ username: values?.username, password: values?.password }).then(() => {
        navigate(`${redirectToPath}`, { replace: true })
      })
    } catch (e) {
      if (!e.response) {
        setNetworkError(true)
      } else if (e.response.status === 401 || e.response.status === 400) {
        setLoginError(true)
      } else {
        console.log(e.response)
      }
    }
  }

  const { mutate, isLoading } = useMutation(loginSubmit)
  const hasUsernameError = Boolean(formik?.errors?.username) && formik?.touched?.username
  const hasPasswordError = Boolean(formik?.errors?.password) && formik?.touched?.password

  return (
    <Grid container spacing={0} height="100%">
      <Grid
        item
        lg={5}
        sx={{
          position: "relative",
          display: {
            lg: "block",
            xs: "none",
          },
        }}
      >
        <Typography className={styles.AV} variant="h1-bold" color="indigo" variantColor={2} align="center">
          AZKAVISION
        </Typography>
        <div>
          <LoginArt className={styles.mainPlaceholder} />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7} height="100%">
        <Paper className={styles.wrapper} elevation={0}>
          <div className={styles.content}>
            <form onSubmit={formik?.handleSubmit}>
              <Box
                sx={{
                  justifyContent: "center",
                  display: {
                    lg: "none",
                    xs: "flex",
                  },
                }}
              >
                <Typography className={styles.AV} variant="h1-bold" color="important" variantColor={2} align="right">
                  AZKAVISION
                </Typography>
              </Box>
              <div className={styles.slogan}>
                <Typography variant="h1-regular" align="left">
                  Your one channel for
                  <br />
                  analytics, and full control.
                </Typography>
              </div>
              <div className={styles.titleWrapper}>
                <Typography variant="h1-bold" gutterBottom align="left">
                  Sign In
                </Typography>
              </div>

              <div className={styles.inputs}>
                {/* username */}
                <div className={styles.labelWrapper}>
                  <InputText
                    fullWidth
                    id="username"
                    type="text"
                    label="Username"
                    placeholder="Enter your username"
                    error={hasUsernameError && formik?.errors?.username}
                    value={formik?.values?.username}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    required
                    disabled={isLoading}
                    autofocus
                  />
                </div>

                {/* password */}
                <div className={styles.labelWrapper}>
                  <InputPassword
                    fullWidth
                    id="password"
                    error={hasPasswordError && formik?.errors?.password}
                    value={formik?.values?.password}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    disabled={isLoading}
                  />
                </div>
              </div>

              {/* Login error */}
              <div className={styles.errorMessageWrapper}>
                {loginError && (
                  <Typography
                    className={styles.errorMessage}
                    variant="span"
                    color="negative"
                    variantColor={2}
                    align="center"
                  >
                    Invalid email or password
                  </Typography>
                )}

                {/* Network error */}
                {networkError && (
                  <Typography
                    className={styles.errorMessage}
                    variant="span"
                    color="negative"
                    variantColor={2}
                    align="center"
                  >
                    Could not connect to server
                  </Typography>
                )}
              </div>

              <div className={styles.submitButtonWrapper}>
                <Button
                  className={styles.submitButton}
                  variant="primary"
                  fullWidth
                  disabled={isLoading || hasUsernameError || hasPasswordError}
                  type="submit"
                  size="large"
                >
                  {isLoading ? <CircularProgress color="inherit" size={24} /> : "Sign In"}
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}
