import React, { Fragment } from "react"

import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"

import { ResponsiveHeatMap } from "@nivo/heatmap"

import GraphEmptyState from "../../../../../components/graphs/GraphEmptyState"
import { gridTheme } from "../../../../../components/graphs/gridTheme"
import barChartLoading from "../../../assets/barChartLoading.mp4"
import styles from "./heatmapGraph.modules.scss?inline"

const HeatmapGraph = (props) => {
  const { data, graphHeight, isLoading } = props

  return (
    <Fragment>
      <Box
        style={{
          height: graphHeight,
        }}
      >
        {data.length < 1 && data && !isLoading ? (
          <GraphEmptyState />
        ) : isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.loadingContainer}
          >
            <Grid item align="center">
              <video autoPlay={true} loop={true} width="300" height="200">
                <source src={barChartLoading} type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        ) : (
          <ResponsiveHeatMap
            data={data}
            theme={gridTheme}
            margin={{ top: 40, right: 0, bottom: 20, left: 0 }}
            yInnerPadding={0.15}
            enableGridX={true}
            enableGridY={true}
            tooltip={(slice) => {
              return (
                <div
                  style={{
                    background: "white",
                    color: "inherit",
                    fontSize: "inherit",
                    borderRadius: "2px",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                    padding: "5px 9px",
                  }}
                >
                  <span>{slice.cell.data.pointDate}</span>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: "3px 5px" }}>
                          <span
                            key="chip"
                            style={{
                              display: "block",
                              width: "12px",
                              height: "12px",
                              background: slice.cell.color,
                            }}
                          />
                        </td>
                        <td style={{ padding: "3px 5px" }}>Count In</td>
                        <td style={{ padding: "3px 5px" }}>
                          <strong key="value">{slice.cell.value}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }}
            axisTop={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              legend: "",
              legendOffset: 46,
            }}
            sizeVariation={{
              sizes: [0, 1.0],
            }}
            axisLeft={null}
            colors={(node) => node.data.color}
            emptyColor="#555555"
            cellComponent="circle"
            inactiveOpacity={0.25}
            borderColor={{
              from: "color",
              modifiers: [["darker", "0.4"]],
            }}
            enableLabels={false}
            legends={[]}
            hoverTarget="cell"
            motionConfig="gentle"
          />
        )}
      </Box>
    </Fragment>
  )
}

export default HeatmapGraph
