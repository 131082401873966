import React, { FC, useMemo } from "react"

import { Card, Grid } from "@mui/material"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"

import { definitions } from "../../../../types/Generated/apiTypes"
import { sumEvents } from "../../../../utils/securityUtils"
import loadingDots from "../../../EntranceGates/assets/loadingDots.gif"
import EventTypeChip from "../EventTypeChip"

import styles from "./NumericStats.module.scss"

type SecurityLogs = definitions["SecurityViolationAnalytics"]

interface Props {
  logs: SecurityLogs[]
  isLoading: boolean
}
const NumericStat: FC<Props> = ({ logs, isLoading }) => {
  const totalEvents = useMemo(() => {
    const total = sumEvents(logs)
    return total
  }, [logs])

  // loading skeletons
  let loadingPlaceholders = new Array(6).fill(null).map((r, i) => (
    <Grid item md={12} key={i}>
      <Skeleton variant="rectangular" height={36} width="auto" />
    </Grid>
  ))
  return (
    <Card className={styles.cardWrapper}>
      <div className={styles.totalEvents}>
        {isLoading ? (
          <img src={loadingDots} alt="loading-dots" width={35} style={{ marginRight: "6px" }} />
        ) : (
          <Typography variant="h1-bold" variantColor={2}>
            {totalEvents.total_events.toLocaleString()}
          </Typography>
        )}
        <Typography variant="h3-bold">Events in total</Typography>
      </div>
      <Grid container spacing={2}>
        {/* 
        Based on its index in total events object
         Event Type       :: Integer Representation
         FIRE             = 0
         VIOLENCE         = 1
         MOTION           = 2
         INTRUSION        = 3
         BLACKLIST_CAR    = 4
         BLACKLIST_PERSON = 5 */}
        {isLoading
          ? loadingPlaceholders
          : Object.entries(totalEvents).map(
              ([key, value], index) =>
                index < 6 && (
                  <Grid item md={12} key={index}>
                    <EventTypeChip isAnalytics type={index} eventCount={value} />
                  </Grid>
                )
            )}
      </Grid>
    </Card>
  )
}
export default NumericStat
