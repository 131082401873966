import React, { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import GetAppIcon from "@mui/icons-material/GetApp"
import LocalParkingIcon from "@mui/icons-material/LocalParking"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import PeopleIcon from "@mui/icons-material/People"
import TimerIcon from "@mui/icons-material/Timer"
import AmpStoriesIcon from "@mui/icons-material/WebStories"
import { Box } from "@mui/material"

import { Button, Tooltip, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { CsvBuilder } from "filefy"
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import CameraSearch from "../../components/CameraSearch"
import Search from "../../components/Search"
import EntitiesTable from "../../components/tables/EntitiesTable"
import { useBranchesStore } from "../../store"
import { EntityRowData } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import Auth from "../../utils/auth"
import { mapCameraAndRegionsToEntities } from "../../utils/entityUtils"
import NewEntity from "./components/NewEntity"

import styles from "./EntitiesAndRegions.module.scss"

type Gate = definitions["Gate"]

type Shop = definitions["Shop"]
type ShopWithCategory = Shop & { categoryId?: number; subcategoryId?: number }

type Corridor = definitions["Corridor"]

type DwellingArea = definitions["DwellingArea"]

type Parking = definitions["Parking"]

type CamerasList = definitions["CameraList"]

type Region = definitions["counterentitiy_region_serializer"]

const sectionHeight = "auto"
type TabInfo = {
  label: string
  value: string
  icon: React.ReactElement
}

type RegionPoint = {
  x: number
  y: number
}
type RegionData = {
  camera: number
  id: number
  points: RegionPoint[]
  state: 0 | 1 | 2
  dwell_thresh?: Date | number
}
interface Entity extends Object {
  id: number
  name: string
  cameras: number[]
  regions: RegionData[]
  camera_name: string[]
  inRegion: boolean
  outRegion: boolean
  type: "Entrance Gate" | "Tenant" | "Corridor" | "Dwelling Area" | "Car Parking"
}
type TableData = Entity & {
  tableData: { id: number }
}

const tabsInfo: Array<TabInfo> = [
  { label: "Entrance Gates", value: "Entrance Gate", icon: <MeetingRoomIcon className={styles.tabIcon} /> },
  { label: "Tenants", value: "Tenant", icon: <PeopleIcon className={styles.tabIcon} /> },
  { label: "Corridors", value: "Corridor", icon: <AmpStoriesIcon className={styles.tabIcon} /> },
  { label: "Dwelling Areas", value: "Dwelling Area", icon: <TimerIcon className={styles.tabIcon} /> },
  { label: "Car Parkings", value: "Car Parking", icon: <LocalParkingIcon className={styles.tabIcon} /> },
]

function EntitiesAndRegions() {
  const [query, setQuery] = useQueryParams({
    searchVal: withDefault(StringParam, ""),
    selectedCamera: NumberParam,
  })

  const [tableData, setTableData] = useState<TableData[]>([])
  const [selectedEntity, setSelectedEntity] = useState<EntityRowData>()
  const [isEdit, setIsEdit] = useState(false)
  const [openAddNewEntityDialog, setOpenAddNewEntityDialog] = useState(false)
  const [activeTab, setActiveTab] = useState<string>("Entrance Gate")
  const [searchedTableData, setSearchedTableData] = useState<TableData[]>([])

  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const handleSearch = (value: string) => {
    setQuery({ searchVal: value })
  }
  // table search
  useEffect(() => {
    setSearchedTableData(
      tableData.filter((element) => element.name.toLowerCase().includes(query.searchVal.toLowerCase()))
    )
  }, [query.searchVal, tableData])

  const handleTabChange = (tab: string): void => {
    setActiveTab(tab)
  }

  const handleOpenAddNewEntityDialog = (): void => {
    setIsEdit(false)
    setOpenAddNewEntityDialog(true)
  }

  const handleCloseAddNewEntityDialog = () => {
    setOpenAddNewEntityDialog(false)
    // reset selected entity
    setSelectedEntity(undefined)
  }

  // fetch regions
  const { data: regionsData, isLoading: regionsLoading } = useQuery<Region[], AxiosError>("fetchRegions", () =>
    VisionAPI.fetchRegions()
  )

  // fetch cameras list
  const { data: cameras, isLoading: camerasListLoading } = useQuery<CamerasList[], AxiosError>(
    ["fetchCamerasShortList", selectedBranch, activeTab],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasShortList({
        branch: queryKey[1] as number,
        services: queryKey[1] === "Car Parking" ? "car" : "",
      }),
    {
      enabled: !!selectedBranch,
    }
  )

  const { data: entranceEntitiesData, isLoading: entranceEntitiesLoading } = useQuery<Gate[], AxiosError>(
    ["fetchEntranceEntities", selectedBranch, query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchEntrances({ branch: queryKey[1] as number, camera: queryKey[2] as number }),
    {
      enabled: activeTab === "Entrance Gate" && !!selectedBranch,
    }
  )

  const { data: shopEntitiesData, isLoading: shopEntitiesLoading } = useQuery<Shop[], AxiosError>(
    ["fetchShopsEntities", selectedBranch, query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchShops({ branch: queryKey[1] as number, camera: queryKey[2] as number }),
    {
      enabled: activeTab === "Tenant" && !!selectedBranch,
    }
  )

  const { data: corridorEntitiesData, isLoading: corridorEntitiesLoading } = useQuery<Corridor[], AxiosError>(
    ["fetchCorridorEntities", selectedBranch, query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchCorridors({ branch: queryKey[1] as number, camera: queryKey[2] as number }),
    {
      enabled: activeTab === "Corridor" && !!selectedBranch,
    }
  )

  const { data: dwellingAreasData, isLoading: dwellingAreasLoading } = useQuery<DwellingArea[], AxiosError>(
    ["fetchDwellingAreasEntities", selectedBranch, query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchDwellingAreas({ branch: queryKey[1] as number, camera: queryKey[2] as number }),
    {
      enabled: activeTab === "Dwelling Area" && !!selectedBranch,
    }
  )

  const { data: carParkingData, isLoading: carParkingLoading } = useQuery<Parking[], AxiosError>(
    ["fetchCarParkingEntities", query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchCarParking({ camera: queryKey[1] as number }),
    {
      enabled: activeTab === "Car Parking",
    }
  )

  const { data: carParkingRegionsData, isLoading: carParkingRegionsLoading } = useQuery<Region[], AxiosError>(
    ["fetchCarParkingRegions", query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchCarParkingRegions({ camera: queryKey[1] as number }),
    {
      enabled: activeTab === "Car Parking",
    }
  )

  // Use useMemo to format Regions
  const regions = useMemo(() => {
    let regionsObj: {
      [key: string]: Region
    } = {}
    regionsData?.forEach((region) => (regionsObj[region?.id!] = { ...region }))
    return regionsObj
  }, [regionsData])

  // Use useMemo to format Gates
  const entranceEntities = useMemo(() => {
    let entranceEntityObj: {
      [key: string]: Gate
    } = {}
    entranceEntitiesData?.forEach((gate: Gate) => (entranceEntityObj[gate.id!] = { ...gate }))
    return entranceEntityObj
  }, [entranceEntitiesData])

  // Use useMemo to format Shops
  const shopEntities = useMemo(() => {
    let shopEntityObj: {
      [key: number]: ShopWithCategory
    } = {}
    shopEntitiesData?.forEach(
      (shop: Shop) =>
        (shopEntityObj[shop.id!] = { ...shop, categoryId: shop.category, subcategoryId: shop.subcategory })
    )
    return shopEntityObj
  }, [shopEntitiesData])

  // Use useMemo to format Dwelling Area
  const dwellingAreas = useMemo(() => {
    let dwellingAreaEntityObj: {
      [key: string]: DwellingArea
    } = {}
    dwellingAreasData?.forEach(
      (dwellingArea: DwellingArea) => (dwellingAreaEntityObj[dwellingArea.id!] = { ...dwellingArea })
    )
    return dwellingAreaEntityObj
  }, [dwellingAreasData])

  // Use useMemo to format corridor
  const corridorEntities = useMemo(() => {
    let corridorEntityObj: {
      [key: string]: Corridor
    } = {}
    corridorEntitiesData?.forEach((entity: Corridor) => (corridorEntityObj[entity.id!] = { ...entity }))
    return corridorEntityObj
  }, [corridorEntitiesData])

  // Use useMemo to format car parking regions
  const carParkingRegions = useMemo(() => {
    let carParkingRegionsObj: {
      [key: string]: Region
    } = {}
    carParkingRegionsData?.forEach((region) => (carParkingRegionsObj[region.id!] = { ...region }))
    return carParkingRegionsObj
  }, [carParkingRegionsData])

  // Use useMemo to format car parking entities
  const carParking = useMemo(() => {
    let carParkingObj: {
      [key: string]: Parking
    } = {}
    carParkingData?.forEach((carParking: Parking) => (carParkingObj[carParking.id!] = { ...carParking }))
    return carParkingObj
  }, [carParkingData])

  // Use useMemo to format cameras object
  const camerasObj = useMemo(() => {
    let formattedCamerasObj: { [key: string]: CamerasList } = {}
    cameras?.forEach((camera: CamerasList) => {
      formattedCamerasObj[camera.id!] = { ...camera }
    })
    return formattedCamerasObj
  }, [cameras])

  // global table loading state , if any of them is true => isLoading = true
  const isLoading = [
    camerasListLoading,
    carParkingLoading,
    carParkingRegionsLoading,
    regionsLoading,
    corridorEntitiesLoading,
    entranceEntitiesLoading,
    shopEntitiesLoading,
    dwellingAreasLoading,
  ].some((element) => element === true)

  // setting table data for each tab
  useEffect(() => {
    switch (activeTab) {
      case "Entrance Gate":
        setTableData(mapCameraAndRegionsToEntities(entranceEntities, camerasObj, regions, "Entrance Gate"))
        break
      case "Tenant":
        setTableData(mapCameraAndRegionsToEntities(shopEntities, camerasObj, regions, "Tenant"))
        break
      case "Corridor":
        setTableData(mapCameraAndRegionsToEntities(corridorEntities, camerasObj, regions, "Corridor"))
        break
      case "Dwelling Area":
        setTableData(mapCameraAndRegionsToEntities(dwellingAreas, camerasObj, regions, "Dwelling Area"))
        break
      case "Car Parking":
        setTableData(mapCameraAndRegionsToEntities(carParking, camerasObj, carParkingRegions, "Car Parking"))
        break
      default:
        setTableData([])
    }
  }, [
    camerasObj,
    carParking,
    corridorEntities,
    dwellingAreas,
    entranceEntities,
    regions,
    carParkingRegions,
    shopEntities,
    activeTab,
  ])

  const handleExportCSV = () => {
    const builder = new CsvBuilder(`Entities Table.csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    if (searchedTableData || tableData) {
      const dataToExport = searchedTableData ? searchedTableData : tableData
      for (const entry of dataToExport) {
        csvFormattedData.push([entry.name, entry.camera_name.join("/")])
      }
    }
    builder.setColumns(["Entity", "Cameras"]).addRows(csvFormattedData).exportFile()
  }

  const handleOpenEdit = (rowData: EntityRowData) => {
    setSelectedEntity(rowData)
    setIsEdit(true)
    setOpenAddNewEntityDialog(true)
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography
          variant="h2-regular"
          tooltip="Get All Entities Data"
          tooltipPlacement="right"
          tooltipIconSize={22}
          gutterBottom
          variantColor={2}
        >
          Entities & Regions
        </Typography>
      </div>
      <div className={styles.header}>
        <Search
          handleSearch={handleSearch}
          searchValue={query.searchVal}
          placeholder="Search by entity name"
          topHeader={false}
          size={235}
        />
        <CameraSearch
          setSelectedCamera={(camera) => setQuery({ selectedCamera: camera })}
          selectedCamera={query.selectedCamera as number}
        />
      </div>
      <div className={styles.verticalMargin} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={styles.tabsAndButtons}>
          <Tabs value={activeTab}>
            {/*Grouping Tabs */}
            {tabsInfo.map((tab) => (
              <Tab
                icon={() => tab.icon}
                label={tab.label}
                onClick={() => handleTabChange(tab.value)}
                value={tab.value}
                selected={activeTab === tab.value}
                id={tab.value}
              />
            ))}
          </Tabs>
          <div className={styles.tableButtons}>
            <Button
              variant="secondary"
              className={styles.exportButton}
              onClick={() => handleExportCSV()}
              startIcon={<GetAppIcon fontSize="small" />}
            >
              Export
            </Button>
            <Box
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                },
              }}
            >
              {Auth.getRegionsPermission() ? (
                <Button
                  variant="primary"
                  onClick={() => handleOpenAddNewEntityDialog()}
                  startIcon={<AddCircleIcon fontSize="small" />}
                  disabled={isLoading || !selectedBranch}
                >
                  Add Entity
                </Button>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                display: { md: "none", xs: "block" },
              }}
            >
              {Auth.getRegionsPermission() ? (
                <Tooltip title="You can only add entities from desktop." placement="top">
                  <Button
                    onClick={() => handleOpenAddNewEntityDialog()}
                    variant="primary"
                    disabled
                    startIcon={<AddCircleIcon fontSize="small" />}
                  >
                    Add Entity
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
            </Box>
          </div>
        </div>

        <EntitiesTable
          title="Entities Table"
          data={searchedTableData ? searchedTableData : tableData}
          maxBodyHeight={sectionHeight}
          type={"Entities Table"}
          openEdit={handleOpenEdit}
          isLoading={isLoading}
        />
      </div>
      <NewEntity
        open={openAddNewEntityDialog}
        handleClose={handleCloseAddNewEntityDialog}
        edit={isEdit}
        entityData={isEdit ? selectedEntity : undefined}
        activeTab={activeTab}
      />
    </div>
  )
}

export default EntitiesAndRegions
