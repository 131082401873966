import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Grid } from "@mui/material"

import { Switch, Typography } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import { useBranchesStore } from "../../../../store"
import { definitions } from "../../../../types/Generated/apiTypes"
import AvgCountWeek from "../../../EntranceGates/components/AvgCountWeek"
import HourlyData from "../../../EntranceGates/components/HourlyData"
import NumericStats from "./NumericStats"

import styles from "./AreaDetails.module.scss"

type DwellingAreaDailyCounts = definitions["DwellingAreaDailyCounts"]
type DwellingAreaWeekDaysCounts = definitions["DwellingAreaWeekDaysCounts"]
type HourlyAVGCounts = definitions["DwellingAreaHourlyAVGCounts"]
type DwellingAreaAnalytics = definitions["DwellingAreaAnalytics"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  timeGrain: "hour" | "day"
  interval: Duration
  setLoadingState: (isLoading: boolean) => void
}
interface MatchParams {
  id?: string
}

const AreaDetails: FC<Props> = ({ startDate, endDate, timeGrain, interval, setLoadingState }) => {
  let params: MatchParams = useParams()
  const hourlyDataRef = useRef(null)

  const [shouldIncludeStaff, setShouldIncludeStaff] = useState(false)
  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  // The data returned by the query, representing area daily counts
  const { data: dwellingDailyCounts, isLoading: dwellingDailyCountsLoading } = useQuery<DwellingAreaDailyCounts[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      "fetchDwellingDailyCounts",
      // Start date of the logs (converted to string)
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (converted to string)
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true
      shouldIncludeStaff,
      // ID of the selected branch (number)
      parseInt(params!.id!),
    ],
    // Function to fetch the area counts based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreaDailyCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        id: queryKey[4] as number,
      }),
    {
      enabled: !!endDate,
    }
  )

  // The data returned by the query, representing the hourly average logs for a specific dwelling area
  const { data: areaHourlyAvgLogs, isLoading: avgLogsLoading } = useQuery<HourlyAVGCounts[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      // Identifier for the query (useful for debugging and cache management)
      "fetchAreaHourlyAvgLogs",
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true
      shouldIncludeStaff,
      // ID of the specific dwelling area (parsed to integer)
      parseInt(params!.id!),
    ],
    // Function to fetch the hourly average counts for the specified dwelling area based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreaHourlyAvgCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        id: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  // The data returned by the query, representing the week days logs for a specific dwelling area
  const { data: areaWeekDaysLogs, isLoading: areaWeekDaysLogsLoading } = useQuery<DwellingAreaWeekDaysCounts[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      // Identifier for the query (useful for debugging and cache management)
      "fetchAreaWeekDaysLogs",
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true
      shouldIncludeStaff,
      // ID of the specific dwelling area (parsed to integer)
      parseInt(params!.id!),
    ],
    // Function to fetch the hourly average counts for the specified dwelling area based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchDwellingWeekDaysCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        id: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  const { data: dwellingAnalytics, isLoading: isDwellingAnalyticsLoading } = useQuery<DwellingAreaAnalytics[]>(
    [
      "fetchDwellingAreasAnalytics",
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true
      shouldIncludeStaff,
      // selected branch id
      selectedBranch,
      // ID of the specific dwelling area (parsed to integer)
      parseInt(params!.id!),
    ],
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreasAnalytics({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: queryKey[3] as boolean,
        branch: queryKey[4] as number,
        entity: queryKey[5] as number,
      }),
    {
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  useEffect(() => {
    if (
      [dwellingDailyCountsLoading, avgLogsLoading, areaWeekDaysLogsLoading, isDwellingAnalyticsLoading].some(
        (element) => element === true
      )
    ) {
      setLoadingState(true)
    } else {
      setLoadingState(false)
    }
  }, [setLoadingState, dwellingDailyCountsLoading, avgLogsLoading, areaWeekDaysLogsLoading, isDwellingAnalyticsLoading])

  const handleSwitchStaffInclusion = (event: ChangeEvent<HTMLInputElement>) => {
    setShouldIncludeStaff(event.target.checked)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="a" variantColor={2}>
          Stats
        </Typography>
        <Switch checked={shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
      </div>
      <Grid container spacing={1.5}>
        <Grid item md={3} xs={12}>
          {/* Numeric Stat cards  */}
          <NumericStats
            dwellingDailyCounts={dwellingDailyCounts}
            dwellingAreaAnalytics={dwellingAnalytics}
            isLoading={!!dwellingDailyCountsLoading}
          />
        </Grid>
        <Grid item md={9} xs={12}>
          {/* Daily Data component */}
          <HourlyData
            timeGrain={timeGrain}
            loading={dwellingDailyCountsLoading}
            singleDwellingData={dwellingDailyCounts!}
            isSingleDwelling
            reference={hourlyDataRef}
            interval={interval!}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 8 }}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AvgCountWeek
            logsData={areaWeekDaysLogs!}
            loading={!!areaWeekDaysLogsLoading}
            interval={interval!}
            isDwellingArea
            key="week-day-average"
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AvgCountWeek
            logsData={areaHourlyAvgLogs!}
            loading={!!avgLogsLoading}
            interval={interval!}
            hourlyAvg
            isDwellingArea
            key="area-hourly-average"
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default AreaDetails
