import React, { FC, useState, useRef, useEffect, Fragment } from "react"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import UndoIcon from "@mui/icons-material/Undo"
import { Grid, Button } from "@mui/material"

import { FormikProps } from "formik"

import { INewEntity } from "../../../types/Custom/Interfaces"
import SingleCameraPoint from "../../Floors/components/floorSetup/SingleCameraPoint"
import noPoints from "../assets/noPoints.svg"
import BoardSidebar from "./BoardSidebar"

import styles from "./RegionsArtboard.module.scss"

interface Props {
  formik: FormikProps<INewEntity>
  selectedCamera: number | undefined
  edit?: boolean
}
type Points = {
  x: number
  y: number
}

const RegionsArtboard: FC<Props> = ({ formik, selectedCamera, edit }) => {
  const [selectedRegion, setSelectedRegion] = useState(formik.values.EntityType === "Dwelling Area" ? 2 : 0) //0 -> in, 1 -> out, 2 -> dwelling,rest depending on state field
  const [isDrawing, setIsDrawing] = useState(edit ? false : true)
  // points drawn inside the actual drwaing artboard
  const [points, setPoints] = useState<Points[]>([])
  // initializing refs for each canvas(main) ; for control and drawing
  // drawing (main) canvas ref
  const drawingCanvasRef = React.useRef<HTMLCanvasElement>(null)
  const carouselRef = useRef<any>()

  const [firstIndexes, setFirstIndexes] = useState({
    areaOut: 1,
    areaDwelling: 2,
  })

  // listening to changes in region type or selected camera, whenever it changes
  // pickup latest point , to continue drawing from last point in that region type
  const handleDeletion = (selectedRegion: number) => {
    setPoints([])
    setSelectedRegion(selectedRegion)
  }
  useEffect(() => {
    const camerasRegions = formik.values.CamerasRegions
    const regionsObj = camerasRegions[`${selectedCamera}`].regions
    if (regionsObj[selectedRegion].points.length > 0) {
      setPoints([...regionsObj[selectedRegion].points])
    } else {
      setPoints([])
    }
    //eslint-disable-next-line
  }, [selectedCamera, selectedRegion, isDrawing])

  // whenever points change (newly drawn points)
  // update values in formik object
  useEffect(() => {
    // updating values of regions object of that specific camera in formik when points array change
    const camerasRegions = formik.values.CamerasRegions
    const regionsObj = camerasRegions[`${selectedCamera}`].regions
    const newRegionObj = { ...regionsObj[selectedRegion], points: points }
    regionsObj[selectedRegion] = newRegionObj

    camerasRegions[`${selectedCamera!}`].regions = regionsObj
    // at least one region with at least 3 points drawn is required
    camerasRegions[`${selectedCamera!}`].isFulfilled = regionsObj.some((region) => region.points.length > 2)
    formik.setFieldValue("CamerasRegions", camerasRegions)
    // eslint-disable-next-line
  }, [points, selectedCamera])

  // empty regions placeholder (before drawing anything yet)
  useEffect(() => {
    // specifying the image drawn onto to be = camera sample frame
    const noPointsYet = new Image()
    noPointsYet.src = noPoints
    const regionsToDraw = formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions
    // drawing empty region thumbnail placeholder if no points are drawn yet
    noPointsYet.onload = () => {
      regionsToDraw.forEach((region, index) => {
        const regionCanvas = region?.ref?.current
        const regionCtx = regionCanvas ? regionCanvas?.getContext("2d") : undefined
        if (regionCtx) {
          if (!region.points || (region.points && region.points.length === 0)) {
            regionCtx.clearRect(0, 0, regionCanvas!.width, regionCanvas!.height)
            regionCtx.drawImage(noPointsYet, 0, 0, regionCanvas!.width, regionCanvas!.height)
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  // drawing on canvases logic (main and sidebar)
  useEffect(() => {
    // specifying the image drawn onto to be = camera sample frame
    const image = new Image()
    const noPointsYet = new Image()
    image.src = `${formik?.values.CamerasRegions[`${selectedCamera}`]?.camera?.sample_frame}`
    noPointsYet.src = noPoints
    const regionsToDraw = formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions
    image.onload = () => {
      const canvas = drawingCanvasRef.current
      const ctx = canvas!.getContext("2d")
      if (ctx) {
        ctx.drawImage(image, 0, 0, canvas!.width, canvas!.height)
        // guiding grid lines
        if (isDrawing) {
          ctx.strokeStyle = "white"
          ctx.lineWidth = 0.7
          const cellSize = 80
          for (var c = 1; c < canvas!.width / cellSize; c++) {
            ctx.beginPath()
            ctx.moveTo(c * cellSize, 0)
            ctx.lineTo(c * cellSize, canvas!.height)
            ctx.stroke()
          }

          for (var r = 1; r < canvas!.height / cellSize; r++) {
            ctx.beginPath()
            ctx.moveTo(0, r * cellSize)
            ctx.lineTo(canvas!.width, r * cellSize)
            ctx.stroke()
          }
        }
        ctx.strokeStyle = "var(--neutral-text-enabled)"
        ctx.lineWidth = 0.6

        regionsToDraw.forEach((region, index) => {
          const regionCanvas = region.ref?.current
          const regionCtx = regionCanvas ? regionCanvas?.getContext("2d") : undefined
          if (regionCtx) {
            regionCtx.strokeStyle = "var(--neutral-text-enabled)"
            if (region.points && region.points.length > 0) {
              regionCtx.drawImage(image, 0, 0, regionCanvas!.width, regionCanvas!.height)
              // specifying fill color to be green in case of area in regions
              const fillStyle =
                region.state === 0
                  ? "rgba(128, 206, 128, 0.6)"
                  : region.state === 1
                  ? "rgba(255, 0, 0, 0.6)"
                  : "rgba(74, 78, 161, 0.6)"
              ctx.fillStyle = fillStyle
              regionCtx.fillStyle = fillStyle
              // start a new path (main canvas and area in thumbnail)
              ctx.beginPath()
              regionCtx.beginPath()
              // begin a new sub-path at the point specified by the given (x, y) coordinates.
              ctx.moveTo(region.points[0].x, region.points[0].y)
              regionCtx.moveTo(region.points[0].x, region.points[0].y)
              // add a straight line to the current sub-path by connecting the sub-path's last point to the specified (x, y) coordinates.
              for (let i = 0; i < region.points.length; i++) {
                ctx.lineTo(region.points[i].x, region.points[i].y)
                regionCtx.lineTo(region.points[i].x, region.points[i].y)
              }
              // attempts to add a straight line from the current point to the start of the current sub-path.
              // This method doesn't draw anything to the canvas directly.
              ctx.closePath()
              regionCtx.closePath()
              //  (outlines) the current or given path with the current stroke style.
              ctx.stroke()
              regionCtx.stroke()
              //  fills the current or given path with the current fillStyle.
              ctx.fill()
              regionCtx.fill()
            } else {
              regionCtx.clearRect(0, 0, regionCanvas!.width, regionCanvas!.height)
              regionCtx.drawImage(noPointsYet, 0, 0, regionCanvas!.width, regionCanvas!.height)
            }
          }
        })
      }
    }
  }, [formik.values.CamerasRegions, selectedCamera, points, isDrawing])

  const addNewRegionLayer = (regionType: number): number => {
    const allCamerasRegions = formik.values.CamerasRegions
    const cameraRegionsArray = formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions
    const newRegionObj = {
      camera: +selectedCamera!,
      state: regionType,
      points: [],
      ref: React.createRef<HTMLCanvasElement>(),
    }
    const lastRegionIndex = cameraRegionsArray.map((region) => region.state).lastIndexOf(regionType)
    cameraRegionsArray.splice(lastRegionIndex + 1, 0, newRegionObj)
    allCamerasRegions[`${selectedCamera}`].regions = cameraRegionsArray
    formik.setFieldValue("CamerasRegions", allCamerasRegions)
    const outIndex = cameraRegionsArray.findIndex((element) => element.state === 1)
    const dwellIndex = cameraRegionsArray.findIndex((element) => element.state === 2)
    setFirstIndexes({
      areaOut: outIndex,
      areaDwelling: dwellIndex,
    })
    return lastRegionIndex
  }

  const handleAddNewLayer = (region: number) => {
    const lastIndex = addNewRegionLayer(region)
    // set regionType to that new index which is this index + 1
    setSelectedRegion(lastIndex + 1)
    // make sure points are cleared
    setPoints([])
    // move to the newly created region in sidebar carousel
    if (carouselRef !== null) {
      carouselRef.current.slickGoTo(lastIndex - 1)
    }
    setIsDrawing(true)
  }

  return (
    <div className={styles.artBoard}>
      <div className={styles.drawingTools}>
        {formik.values.EntityType !== "Dwelling Area" && (
          <Fragment>
            <Button className={styles.drawingButton} onClick={() => handleAddNewLayer(0)}>
              <span className={styles.buttonContent}>
                <AddCircleIcon />
                Add Area In
              </span>
            </Button>
            <Button className={styles.drawingButton} onClick={() => handleAddNewLayer(1)}>
              <span className={styles.buttonContent}>
                <AddCircleIcon />
                Add Area Out
              </span>
            </Button>
          </Fragment>
        )}

        {formik.values.EntityType !== "Car Parking" && (
          <Button className={styles.drawingButton} onClick={() => handleAddNewLayer(2)}>
            <span className={styles.buttonContent}>
              <AddCircleIcon />
              Add Area Dwelling
            </span>
          </Button>
        )}
        <Button
          className={styles.drawingButton}
          style={{ padding: "8px 32px" }}
          onClick={() => setPoints((prevPoints) => prevPoints.slice(0, prevPoints.length - 1))}
        >
          <span className={styles.buttonContent}>
            <UndoIcon />
            Undo
          </span>
        </Button>
        <Button
          style={{
            background: isDrawing ? "var(--green-background-1)" : "var(--purple-background-1)",
            borderColor: isDrawing ? "var(--positive-border-active)" : "var(--purple-border)",
          }}
          className={styles.drawingCta}
          onClick={() => setIsDrawing((prevValue) => !prevValue)}
        >
          {isDrawing ? "Finish Drawing" : "Start Drawing"}
        </Button>
      </div>
      <Grid container>
        <BoardSidebar
          formik={formik}
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
          onDelete={handleDeletion}
          selectedCamera={selectedCamera!.toString()}
          isDrawing={isDrawing}
          carouselRef={carouselRef}
          firstIndexes={firstIndexes}
          setFirstIndexes={setFirstIndexes}
        />
        <Grid item md={10} style={{ padding: 28, position: "relative" }}>
          <canvas
            ref={drawingCanvasRef}
            onClick={(e) => {
              const newPoint = {
                x: Math.round(e.clientX - drawingCanvasRef!.current!.getBoundingClientRect().x),
                y: Math.round(e.clientY - drawingCanvasRef!.current!.getBoundingClientRect().y),
              }
              isDrawing && setPoints([...points, newPoint])
            }}
            className={styles.drawingBoard}
            width={formik.values.EntityType === "Car Parking" ? 1280 : 736}
            height={formik.values.EntityType === "Car Parking" ? 720 : 416}
          />
          {isDrawing && (
            <div>
              {points.length > 0 &&
                points.map((point, i) => (
                  <SingleCameraPoint
                    mappingType="camera"
                    key={i}
                    cameraMappingCoor={{ y_camera_resized: point.y, x_camera_resized: point.x }}
                    coorsPosition={i + 1}
                    isEntities={true}
                    pointColor={
                      formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions[selectedRegion]?.state === 0
                        ? "rgba(128, 206, 128, 0.6)"
                        : formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions[selectedRegion]?.state === 1
                        ? "rgba(255, 0, 0, 0.6)"
                        : "rgba(74, 78, 161, 0.6)"
                    }
                  />
                ))}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
export default RegionsArtboard
