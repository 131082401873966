import React, { FC, useState } from "react"

import { Card, Grid } from "@mui/material"
import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

import { Typography } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import SlidingData from "../../../components/SlidingData"
import { INewEntity } from "../../../types/Custom/Interfaces"
import CameraPreview from "./CameraPreview"
import RegionsArtboard from "./RegionsArtboard"

import styles from "./RegionCreator.module.scss"

function Alert(props: any) {
  return <MuiAlert elevation={6} variant={"filled"} {...props} />
}

interface Props {
  formik: FormikProps<INewEntity>
  edit?: boolean
}
const RegionCreator: FC<Props> = ({ formik, edit }) => {
  const [selectedCamera, setSelectedCamera] = useState(formik?.values?.ValidCameras[0]?.id)
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)

  // closing snackbar
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return
    }
    setOpenErrorSnackbar(false)
  }

  return (
    <Card className={styles.entityCreatorCard}>
      <Typography variant="label" variantColor={2} gutterBottom>
        Regions Of Interest
      </Typography>
      {/* Regions Art Board , Selected Camera from valid cameras section down below is sent to this component */}
      <RegionsArtboard formik={formik} selectedCamera={selectedCamera} key={selectedCamera} edit={edit} />
      {/* Valid Cameras mapped into CameraPreview Component, selected highlighted in blue */}
      {/* Grid of mapped camera previews OR Sliding Data  if number of selected cameras < 5 use GRID*/}
      {formik.values.ValidCameras.length + formik.values.invalidCameras.length < 5 ? (
        <Grid container style={{ width: "100%" }}>
          {formik.values.ValidCameras.map((camera, index) => (
            <Grid
              key={index}
              item
              md={3}
              onClick={() => setSelectedCamera(camera.id)}
              style={{ width: "100%", boxSizing: "border-box" }}
            >
              <CameraPreview
                camera={{ label: camera?.name, value: camera?.id! }}
                isDrawingSelection
                selectedCamera={selectedCamera}
                isFulfilled={formik.values.CamerasRegions[`${camera.id}`].isFulfilled}
                margin={index === 0 || index === formik.values.ValidCameras.length - 1 ? false : true}
              />
            </Grid>
          ))}
          {formik.values.invalidCameras.map((camera, index) => (
            <Grid
              key={index + "_invalid"}
              item
              md={3}
              style={{ width: "100%", boxSizing: "border-box" }}
              onClick={() => setOpenErrorSnackbar(true)}
            >
              <CameraPreview
                camera={{ label: camera?.name, value: camera?.id! }}
                isDrawingSelection
                margin={index + 1 === formik.values.invalidCameras.length ? false : true}
                entityType={formik.values.EntityType === "Car Parking" ? "Car Parking" : ""}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        // if number of valid cameras >= 5 use carousel
        <SlidingData
          type="drawingBoard"
          data={formik.values.ValidCameras}
          count={formik.values.ValidCameras?.length + formik.values.invalidCameras.length}
          dots={false}
          infinite={false}
          slidesToScroll={1}
          slidesToShow={4}
        >
          {formik.values.ValidCameras.map((camera, index) => (
            <div key={index} onClick={() => setSelectedCamera(camera.id)} style={{ boxSizing: "border-box" }}>
              <CameraPreview
                camera={{ label: camera?.name, value: camera?.id! }}
                isDrawingSelection
                selectedCamera={selectedCamera}
                isFulfilled={formik.values.CamerasRegions[`${camera.id}`].isFulfilled}
                margin
              />
            </div>
          ))}
          {formik.values.invalidCameras.map((camera, index) => (
            <div
              key={index + "_invalid"}
              style={{ boxSizing: "border-box" }}
              onClick={() => setOpenErrorSnackbar(true)}
            >
              <CameraPreview
                camera={{ label: camera?.name, value: camera?.id! }}
                isDrawingSelection
                margin
                entityType={formik.values.EntityType === "Car Parking" ? "Car Parking" : ""}
              />
            </div>
          ))}
        </SlidingData>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openErrorSnackbar}
        autoHideDuration={1000}
        onClose={handleSnackbarClose}
      >
        <div>
          <Alert onClose={handleSnackbarClose} severity="warning">
            Camera must have a valid sample frame to draw regions
          </Alert>
        </div>
      </Snackbar>
    </Card>
  )
}
export default RegionCreator
