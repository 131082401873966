import React, { useState, Fragment } from "react"
import { useMutation } from "react-query"

import FeedbackIcon from "@mui/icons-material/Feedback"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  TextField,
} from "@mui/material"

import { Button, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { useFormik } from "formik"
import * as Yup from "yup"

import { VisionAPI } from "../API/VisionAPI"
import { definitions } from "../types/Generated/apiTypes"

import styles from "./Feedback.module.scss"

type SupportEmailRequest = definitions["SupportEmailRequest"]

const FeedBack = () => {
  const [openTicketingDialog, setOpenTicketingDialog] = useState(false)

  const validationSchema = Yup.object({
    subject: Yup.string().max(50, "Must be 50 characters or less").required("Required"),
    message: Yup.string()
      .required("Message is required")
      .min(1, "Message can not be empty")
      .max(500, "Message is too long - Must be 500 characters or less"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
  })

  const handleClickOpenTicketingDialog = () => setOpenTicketingDialog(true)

  const handleCloseTicketingDialog = () => setOpenTicketingDialog(false)

  const { mutate: reportProblem, isLoading } = useMutation(
    (values: SupportEmailRequest) => VisionAPI.reportProblem(values),
    {
      onSuccess: () => {
        NotificationUtils.toast("Your message has been successfully submitted.", {
          snackBarVariant: "positive",
        })
        handleCloseTicketingDialog()
        formik.resetForm()
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => reportProblem(values),
  })

  return (
    <Fragment>
      <IconButton
        className={styles.feedBack}
        edge="end"
        onClick={() => handleClickOpenTicketingDialog()}
        size="large"
        color="secondary"
        sx={{
          "&.MuiIconButton-root": {
            borderRadius: "50%",
            padding: "12px",
          },
        }}
      >
        <FeedbackIcon
          className={styles.feedbackIcon}
          sx={{
            color: "rgba(0, 0, 0, 0.54)",
          }}
        />
      </IconButton>

      <Dialog open={openTicketingDialog} onClose={handleCloseTicketingDialog}>
        <DialogTitle id="form-dialog-title">Report a Problem</DialogTitle>
        <DialogContent>
          <DialogContentText>To report a problem, please fill in all the mandatory fields below.</DialogContentText>
          {isLoading ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={styles.loadingContainer}
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <React.Fragment>
              <TextField
                id="subject"
                margin="dense"
                label="Subject"
                type="text"
                variant="outlined"
                fullWidth
                value={formik.values.subject}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              />
              <TextField
                id="email"
                margin="dense"
                label="Email Address"
                type="email"
                variant="outlined"
                fullWidth
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                id="message"
                margin="dense"
                label="Message"
                type="text"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={formik.values.message}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTicketingDialog} variant="secondary">
            Cancel
          </Button>
          <Button onClick={formik.submitForm} variant="primary" disabled={!formik.isValid}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default FeedBack
