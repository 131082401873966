// TODO:: Refactor this component into a generic component
import { FC, useState, useEffect, useMemo, Fragment } from "react"

import FaceIcon from "@mui/icons-material/Face"
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import WcIcon from "@mui/icons-material/Wc"
import { Box, Card, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { Typography } from "@synapse-analytics/synapse-ui"

import { definitions } from "../../types/Generated/apiTypes"
import { convertLogsDataToGateStats, replaceMissingData } from "../../utils/counterUtils"
import { sortAndColorizeLogs } from "../../utils/genericHelpers"
import ButtonSwitch from "../Buttons/ButtonSwitch"
import GraphEmptyState from "../graphs/GraphEmptyState"
import PaginatedBarGraph from "../graphs/PaginatedBarGraph"
import CountTable from "../tables/CountTable"

import styles from "./PaginatedBarGraphCard.module.scss"

type Gate = definitions["Gate"]
type GatesObj = {
  [id: number]: Gate
}
type MissingData = definitions["MissingSliceCounterLog"]
type CounterLogs = definitions["CounterLogs"]
type CategoryCount = definitions["CategoryCount"]
type BranchesAnalytics = definitions["BranchesAnalytics"]

type SubCategoryCount = definitions["SubCategoryCount"]
type CorridorsCounts = definitions["CorridorAgeGenderCount"]
type CarGatesCounts = definitions["CarCountsByGate"]

interface Props {
  logsData?: CounterLogs[]
  categoriesLogsData?: CategoryCount[]
  subcategoriesLogsData?: SubCategoryCount[]
  corridorsData?: CorridorsCounts[]
  carsGatesData?: CarGatesCounts[]
  branchesData?: BranchesAnalytics[]
  entranceEntities?: GatesObj
  loading: boolean
  reference?: any
  startDate?: string
  endDate?: string
  isTenants?: boolean
  isCorridorsPerformance?: boolean
  isSubcategory?: boolean
  isCarGates?: boolean
  isBranchesAnalytics?: boolean
  noCategorySelected?: boolean
  shouldIncludeMissingData?: boolean
  missingData?: MissingData[]
}
type GraphData = {
  id?: string
  entity?: string
  entity_name?: string
  "Count In"?: number
  "Count Out"?: number
  "Missing Count In"?: number
  "Missing Count Out"?: number
  male_count?: number
  female_count?: number
  adult_count?: number
  child_count?: number
  color?: string
  count?: number
}

const PaginatedBarGraphCard: FC<Props> = ({
  logsData,
  loading,
  reference,
  endDate,
  startDate,
  isTenants,
  isCorridorsPerformance,
  categoriesLogsData,
  subcategoriesLogsData,
  corridorsData,
  isSubcategory,
  noCategorySelected,
  entranceEntities,
  shouldIncludeMissingData,
  missingData,
  carsGatesData,
  branchesData,
  isCarGates,
  isBranchesAnalytics,
}) => {
  const theme = useTheme()
  const xSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [currentPage, setCurrentPage] = useState(1)

  const [activeTab, setActiveTab] = useState(0)
  const [activeStatsTab, setActiveStatsTab] = useState(xSmallScreen ? 1 : 0)

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const barGraphData: GraphData[] | CategoryCount[] | SubCategoryCount[] = useMemo(() => {
    if (loading || !endDate) return []

    let transformedData = []

    if (entranceEntities && logsData) {
      const replacedLogs = shouldIncludeMissingData
        ? replaceMissingData(logsData, missingData, entranceEntities)
        : logsData
      transformedData = convertLogsDataToGateStats(entranceEntities, replacedLogs)
    } else if (corridorsData) {
      transformedData = corridorsData
    } else if (carsGatesData) {
      transformedData = sortAndColorizeLogs(carsGatesData, "count")
    } else if (branchesData) {
      transformedData = sortAndColorizeLogs(branchesData, "total_count_in_sum")
    }

    if (isSubcategory && subcategoriesLogsData) {
      transformedData = subcategoriesLogsData
    } else if (categoriesLogsData) {
      transformedData = categoriesLogsData
    }

    return transformedData
  }, [
    loading,
    corridorsData,
    carsGatesData,
    branchesData,
    endDate,
    isSubcategory,
    subcategoriesLogsData,
    categoriesLogsData,
    entranceEntities,
    logsData,
    shouldIncludeMissingData,
    missingData,
  ])
  const maxPages = useMemo(() => Math.ceil(barGraphData.length / 10), [barGraphData])

  // resetting page number on changing filters changes
  useEffect(() => {
    if (!!startDate && !!endDate) {
      setCurrentPage(1)
    }
  }, [activeStatsTab, endDate, startDate, activeTab, shouldIncludeMissingData])

  return (
    <Fragment>
      <Card
        className={styles.cardWrapper}
        sx={{
          margin: isSubcategory || isTenants || isCorridorsPerformance || isBranchesAnalytics ? 0 : "12px 0px",
          minHeight: "410px",
        }}
        ref={reference}
      >
        <Fragment>
          <Fragment>
            <div className={styles.header}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: {
                    xl: "auto",
                    xs: "min-content",
                  },
                }}
              >
                <Typography variant="h2-bold" variantColor={2} noWrap className={styles.cardTitle}>
                  {isTenants
                    ? isSubcategory
                      ? "Subcategories"
                      : "Categories"
                    : isCorridorsPerformance
                    ? "Corridors"
                    : isCarGates
                    ? "Car counts by gate"
                    : isBranchesAnalytics
                    ? "Branches"
                    : "Gates"}{" "}
                  {!isCarGates && "performance "}
                  {activeStatsTab === 0 && !loading && barGraphData.length > 10 && `: Page ${currentPage}/${maxPages}`}
                </Typography>

                {(isTenants || isCorridorsPerformance) && activeStatsTab === 0 && (
                  <ButtonSwitch
                    activePage={activeTab}
                    pages={["Gender", "Age"]}
                    handleSelectButton={setActiveTab}
                    disabled={noCategorySelected === true || loading === true ? true : false}
                    pagesIcons={[<WcIcon />, <FaceIcon />]}
                  />
                )}
              </Box>

              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                <ButtonSwitch
                  activePage={activeStatsTab}
                  pages={["Graph", "Table"]}
                  handleSelectButton={setActiveStatsTab}
                  disabled={noCategorySelected || loading}
                  pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
                />
              </Box>
            </div>
            {shouldIncludeMissingData && (
              <Box display="flex" alignItems="center" mb={activeStatsTab === 1 ? 6 : 0}>
                <span className={styles.colorSquare} />
                <Typography variant="p">Missing data generated by the admin</Typography>
              </Box>
            )}
            {isTenants && noCategorySelected ? (
              <GraphEmptyState noCategorySelected={true} />
            ) : activeStatsTab === 0 ? (
              <PaginatedBarGraph
                barGraphData={barGraphData}
                isSubcategory={isSubcategory}
                isLoading={[loading].some((element) => element === true)}
                setCurrentPage={setCurrentPage}
                isCorridorsPerformance={isCorridorsPerformance}
                isCarGates={isCarGates}
                isBranchesAnalytics={isBranchesAnalytics}
                key={`${barGraphData} ${shouldIncludeMissingData ? "_missingDataIncluded" : ""} ${
                  isTenants || isCorridorsPerformance ? (activeTab === 0 ? "_gender" : "_age") : ""
                }`}
                includeMissing={shouldIncludeMissingData}
                ageGenderKeys={
                  isTenants || isCorridorsPerformance
                    ? activeTab === 0
                      ? ["male_count", "female_count"]
                      : ["adult_count", "child_count"]
                    : false
                }
              />
            ) : (
              <CountTable
                data={barGraphData}
                loading={loading}
                isGatesTable={!isTenants}
                isTenants={!!isTenants}
                isSubcategory={!!isSubcategory}
                isCorridorsPerformance={!!isCorridorsPerformance}
                isCarGates={!!isCarGates}
                isBranchesAnalytics={isBranchesAnalytics}
                shouldIncludeMissingData={shouldIncludeMissingData}
              />
            )}
          </Fragment>
        </Fragment>
      </Card>
    </Fragment>
  )
}
export default PaginatedBarGraphCard
