import { useContext, useEffect, useState } from "react"

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import PercentIcon from "@mui/icons-material/Percent"
import { CircularProgress } from "@mui/material"

import { Button, InputText, SingleDayPicker, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { SwiperSlide } from "swiper/react"

import SwiperContainer from "../../../../../components/SwiperContainer"
import TimePicker from "../../../../../components/TimePicker"
import { IMissingData, UpdateAction } from "../../../../../types/Custom/Interfaces"
import { MissingDataContext } from "../../MissingDataContext/MissingDataContext"
import EntityBox from "../components/EntityBox"
import MissingDataTable from "./MissingDataTable"

import styles from "./SecondStep.module.scss"

const SecondStep = () => {
  const {
    selectedEntities,
    selectedEntity,
    startDate,
    startTime,
    endDate,
    endTime,
    isLoadingEstimation,
    isFetchingEstimation,
    missingData,
    fillingErrors,
    monthsForAverage,
    setMonthsForAverage,
    handleSelectEntityToFill,
    handleSetDefaultSelectedEntity,
    handleStartDateChange,
    handleStartTimeChange,
    handleEndDateChange,
    handleEndTimeChange,
    handleAutoFill,
    handleIncreaseOrDecreaseLogs,
    checkIfEditEntity,
  } = useContext<IMissingData>(MissingDataContext)
  const [isIncrease, setIsIncrease] = useState(true)
  const [percentage, setPercentage] = useState(0)

  const handleToggleIncreaseDecrease = () => {
    setIsIncrease((prevValue) => !prevValue)
  }
  const handleChangeData = () => {
    const action: UpdateAction = isIncrease ? "increase" : "decrease"
    handleIncreaseOrDecreaseLogs(action, percentage)
  }
  useEffect(() => {
    handleSetDefaultSelectedEntity()
  }, [handleSetDefaultSelectedEntity, selectedEntities])

  // Disable accessing past dates in date picker
  const disableDatesBeforeStart = (date: moment.Moment): boolean => {
    // Disable dates before the start date, but allow the start date itself
    return moment(date)?.isSameOrAfter(startDate, "day")
  }

  useEffect(() => {
    setPercentage(0)
  }, [isFetchingEstimation])
  return (
    <div className={styles.wrapper}>
      <Typography variant="a" gutterBottom>
        Select one entity
      </Typography>
      <SwiperContainer slidesPerView={5} spaceBetweenSlides={8}>
        {selectedEntities.map(
          (entity, index) =>
            entity && (
              <SwiperSlide key={index}>
                <EntityBox
                  entity={entity}
                  handleSelectEntity={handleSelectEntityToFill}
                  isSelected={selectedEntity === entity}
                  isFilled={!!missingData[entity?.id!] || checkIfEditEntity(entity?.id!)}
                  isErrorFilling={!!fillingErrors[entity?.id!]}
                />
              </SwiperSlide>
            )
        )}
      </SwiperContainer>
      {!!fillingErrors[selectedEntity?.id!] && (
        <div className={styles.error}>
          <ErrorOutlineIcon className={styles.errorIcon} />
          <Typography variant="label" variantColor={2} color="negative">
            {endDate.isSame(startDate, "day") && endTime.isSameOrBefore(startTime, "hour")
              ? "End time must occur after start time"
              : "This entity has already been auto-filled with the same date before. You can change the date for this entity to proceed."}
          </Typography>
        </div>
      )}
      <Typography variant="a" className={styles.datePickerTitle}>
        Pick date and time range
      </Typography>
      <Typography variant="span" variantColor={2} gutterBottom>
        By picking date & time range data will be filled automatically using average data as a reference.
      </Typography>
      <div className={styles.pickersAndAction}>
        <div className={styles.pickers}>
          {/* start date and time pickers */}
          <SingleDayPicker date={startDate} onDateChange={handleStartDateChange} />
          <TimePicker time={startTime} handleTimeChange={handleStartTimeChange} onlyHours />
          <ChevronRightIcon className={styles.arrow} />
          {/* end date and time pickers */}
          <SingleDayPicker date={endDate} onDateChange={handleEndDateChange} isOutsideRange={disableDatesBeforeStart} />
          <TimePicker time={endTime} handleTimeChange={handleEndTimeChange} onlyHours />
        </div>
        {/* Autofill Button*/}
        <div className={styles.autofillContainer}>
          <InputText
            hideDescription
            variant="filled"
            size={120}
            isNotchedLabel
            label="Average Months"
            type="number"
            min={1}
            value={monthsForAverage}
            handleChange={(e) => {
              const targetValue = e.target as HTMLInputElement
              setMonthsForAverage(targetValue.valueAsNumber)
            }}
          />
          <Button variant="primary" onClick={handleAutoFill} size="large" disabled={isLoadingEstimation}>
            <span style={{ marginRight: 8 }}>{!!missingData[selectedEntity?.id!] ? "Re" : "Auto"}fill</span>
            <div
              className={styles.loaderWrapper}
              style={{
                visibility: isLoadingEstimation ? "visible" : "hidden",
              }}
            >
              <CircularProgress size={10} />
            </div>
          </Button>
        </div>
      </div>
      {/* Increase or decrease counts by a percentage (only when table is filled) */}
      {!!missingData[selectedEntity?.id!] && (
        <div className={styles.tableAction}>
          <Typography variant="a">{selectedEntity?.name} entity</Typography>
          <div className={styles.changePercentageActions}>
            <Button
              variant="secondary"
              size="large"
              onClick={handleToggleIncreaseDecrease}
              className={styles.actionItem}
            >
              {isIncrease ? <ArrowUpwardIcon fontSize="medium" /> : <ArrowDownwardIcon fontSize="medium" />}
            </Button>
            <InputText
              hideDescription
              variant="filled"
              size={56}
              type="number"
              min={0}
              value={percentage}
              handleChange={(e) => {
                const targetValue = e.target as HTMLInputElement
                setPercentage(targetValue.valueAsNumber)
              }}
            />
            <PercentIcon fontSize="small" className={styles.percentIcon} />
            <Button variant="secondary" size="large" onClick={handleChangeData}>
              {isIncrease ? "Increase" : "Decrease"} data
            </Button>
          </div>
        </div>
      )}
      <MissingDataTable />
    </div>
  )
}
export default SecondStep
