import { FC, useState, useEffect } from "react"

import { InputText } from "@synapse-analytics/synapse-ui"

import { definitions } from "../types/Generated/apiTypes"

type Tag = definitions["Tag"]
interface Props {
  options?: Tag[]
  formik: any
  selectedTags?: any[]
  placeholder: string
  label?: string
  isLoading?: boolean
  description?: string
  size?: number
  fullWidth?: boolean
}

const TagsList: FC<Props> = ({
  options,
  formik,
  placeholder,
  description,
  label,
  isLoading,
  selectedTags,
  fullWidth,
  size = 320,
}) => {
  const [tagEnteredValue, setTagEnteredValue] = useState("")
  const [tags, setTags] = useState<Array<string>>([])

  useEffect(() => {
    if (options && !isLoading) setTags(options.map((tag: Tag) => tag.name))
  }, [options, isLoading])

  const handleAddNewTag = (newTag: string) => {
    setTags((prevValue) => [...prevValue, newTag])
    formik?.setFieldValue("tags", [...formik.values.tags, newTag])
  }

  return (
    <InputText
      key={isLoading ? "Tags Loading" : "Tags Select"}
      id="tags-standard"
      label={label}
      value={tagEnteredValue}
      loading={isLoading}
      selectedValues={selectedTags}
      multiple
      horizontalScroll={false}
      size={size}
      fullWidth={fullWidth}
      options={tags}
      setSelectedValues={(selectedOptions) => {
        formik?.setFieldValue("tags", selectedOptions)
      }}
      placeholder={placeholder}
      description={description}
      acceptNewValues
      handleAddNewValue={handleAddNewTag}
      handleChange={(event) => {
        setTagEnteredValue(event.target.value as string)
      }}
    />
  )
}

export default TagsList
