import React, { FC, useState, useEffect, Fragment } from "react"
import { useQuery } from "react-query"

import { CircularProgress, Grid } from "@mui/material"

import { NotificationUtils, Tab, Tabs } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { Mark } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { dayMarks, DynamicHeatmapMarks } from "../../../utils/heatmapUtils"
import HeatmapTimeline from "../components/HeatmapTimeline"

import styles from "./HeatmapLogs.module.scss"

type FloorPlanVersionList = definitions["FloorPlanVersionList"]
type HeatMapHourly = definitions["HeatMapHourly"] & { version_no?: number }
interface Props {
  startDate?: string
  endDate?: string
  loadingFloorVersions: boolean
  getFloorLoadingState: (loading: boolean) => void
  floorPlanVersions: FloorPlanVersionList[]
  floorId?: number
}

const HeatmapLogs: FC<Props> = ({
  floorPlanVersions,
  loadingFloorVersions,
  startDate,
  endDate,
  getFloorLoadingState,
  floorId,
}) => {
  const [marks, setMarks] = useState<Mark[]>()
  const [firstLogs, setFirstLogs] = useState<HeatMapHourly[]>()
  const [secondLogs, setSecondLogs] = useState<HeatMapHourly[]>()
  const [timeGrain, setTimeGrain] = useState<"hourly" | "daily">("hourly")

  useEffect(() => {
    if (startDate && endDate) {
      const dateDiff = moment(endDate!).diff(moment(startDate!), "days")
      // daily
      if (timeGrain === "hourly") {
        setMarks(dayMarks!)
      } else {
        // Monthly
        const marks = DynamicHeatmapMarks(dateDiff + 1)
        setMarks(marks!)
      }
    }
  }, [startDate, endDate, timeGrain])

  const fetchSingleVersion = async () => {
    const data = await VisionAPI.fetchHeatmapVersionLogs({
      floor_version_id: floorPlanVersions[0].id as number,
      from_date: startDate as string,
      to_date: endDate as string,
    })
    const allRes1 = data?.map((res: HeatMapHourly) => {
      return { ...res, version_no: floorPlanVersions[0].version_no }
    })

    setFirstLogs(allRes1)
    setSecondLogs([])
    return allRes1
  }

  const fetchMultipleVersions = async () => {
    const versionOneEndDate = moment(floorPlanVersions[0].archived_at)?.format("YYYY-MM-DD")

    const res1 = await VisionAPI.fetchHeatmapVersionLogs({
      floor_version_id: floorPlanVersions[0].id as number,
      from_date: startDate as string,
      to_date: versionOneEndDate,
    })
    const res2 = await VisionAPI.fetchHeatmapVersionLogs({
      floor_version_id: floorPlanVersions[1].id as number,
      from_date: versionOneEndDate,
      to_date: endDate as string,
    })

    const allRes1 = res1?.map((res: HeatMapHourly) => {
      return { ...res, version_no: floorPlanVersions[0].version_no }
    })

    setFirstLogs(allRes1)
    const allRes2 = res2?.map((res: HeatMapHourly) => {
      return { ...res, version_no: floorPlanVersions[1].version_no }
    })
    setSecondLogs(allRes2)

    return [...allRes1, ...allRes2]
  }

  const fetchHeatmapVersionLogs = async () => {
    if (floorPlanVersions?.length === 1) {
      return fetchSingleVersion()
    } else {
      return fetchMultipleVersions()
    }
  }

  // fetch heatmap for floor plan versions
  const { data: heatmapLogs, isFetching: isLoadingLogs } = useQuery(
    "fetchHeatmapVersionLogs",
    fetchHeatmapVersionLogs,
    {
      onSettled: (data) => {
        if (data && data.length < 1) {
          NotificationUtils.toast("Floor's heatmap logs not found for selected date range", {
            snackBarVariant: "warning",
          })
        }
      },
      enabled:
        startDate && !!endDate && !loadingFloorVersions && floorPlanVersions && floorPlanVersions?.length > 0
          ? true
          : false,
    }
  )

  useEffect(() => {
    if (getFloorLoadingState) getFloorLoadingState(isLoadingLogs)
    //eslint-disable-next-line
  }, [isLoadingLogs, getFloorLoadingState])

  return (
    <Fragment>
      <Tabs value={timeGrain} className={styles.tabs}>
        <Tab
          label="Heatmap per hour"
          onClick={() => setTimeGrain("hourly")}
          value="hourly"
          selected={timeGrain === "hourly"}
          id="0"
        />
        <Tab
          label="Heatmap per day"
          onClick={() => setTimeGrain("daily")}
          value="daily"
          selected={timeGrain === "daily"}
          id="1"
        />
      </Tabs>

      {loadingFloorVersions || isLoadingLogs ? (
        <Grid container alignItems="center" direction="row" justifyContent="center" className={styles.loadingWrapper}>
          <Grid item alignItems="center">
            <CircularProgress
              size={70}
              className={styles.loading}
              style={{ color: "var(--important-background-default-1)" }}
            />
          </Grid>
        </Grid>
      ) : floorPlanVersions && floorPlanVersions.length > 0 && heatmapLogs && heatmapLogs.length > 0 ? (
        <HeatmapTimeline
          timeGrain={timeGrain}
          firstLogs={firstLogs!}
          secondLogs={secondLogs!}
          marks={marks! && marks!}
          floorPlanVersions={floorPlanVersions}
          heatmapLogs={heatmapLogs!}
          key={`Heatmap_Of_${floorId}_${timeGrain}`}
        />
      ) : (
        <Placeholder selectionType="Floor" />
      )}
    </Fragment>
  )
}

export default HeatmapLogs
