import { FC, useState } from "react"

import EditIcon from "@mui/icons-material/EditOutlined"

import { Button, Tag, Typography } from "@synapse-analytics/synapse-ui"

import { definitions } from "../../../types/Generated/apiTypes"
import EditNode from "./EditNode"

import styles from "./NodeCard.module.scss"

type Node = definitions["NodeUpdateRetrieve"]

interface Props {
  node: Node
}
const NodeCard: FC<Props> = ({ node }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleOpenEditNode = () => setIsDialogOpen(true)
  const handleCloseEditNode = () => setIsDialogOpen(false)

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.infoSection}>
          <Typography variant="h2-bold" noWrap>
            {node.name}
          </Typography>
          <Button startIcon={<EditIcon fontSize="small" />} onClick={handleOpenEditNode}>
            Edit Name
          </Button>
        </div>
        <div className={styles.healthAndVersion}>
          <Tag variant={node?.connected ? "default" : "negative"} size="regular">
            {node?.connected ? "Connected" : "Not Connected"}
          </Tag>
          {node?.core_version && (
            <Typography variant="p" variantColor={2}>
              {node?.core_version}
            </Typography>
          )}
        </div>
      </div>

      <div className={styles.infoSection}>
        <div className={styles.info}>
          <Typography variant="h3-bold" variantColor={2}>
            Slug
          </Typography>
          <Typography variant="h3-bold">{node.slug}</Typography>
        </div>
        <div className={styles.info}>
          <Typography variant="h3-bold" variantColor={2}>
            Cameras count
          </Typography>
          <Typography variant="h3-bold">{node.cameras_count}</Typography>
        </div>
      </div>
      {/* <div className={styles.info}>
        <Typography variant="h3-bold" variantColor={2} gutterBottom>
          Cameras services
        </Typography>
        <Grid container spacing={1.25}>
          {node.services?.map((service) => (
            <Grid item>
              <NodeService service={service} />
            </Grid>
          ))}
        </Grid>
      </div> */}

      {isDialogOpen && <EditNode handleClose={handleCloseEditNode} open={isDialogOpen} node={node} />}
    </div>
  )
}
export default NodeCard
