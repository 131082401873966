import React, { useState, useEffect, Fragment, ChangeEvent } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined"
import { CircularProgress, Grid, useMediaQuery } from "@mui/material"

import {
  Button,
  Chip,
  Typography,
  Skeleton,
  Select,
  InputChangeEvent,
  NotificationUtils,
} from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { BooleanParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import Search from "../../components/Search"
import ServicesFilter from "../../components/ServicesFilter"
import WarningDialog from "../../components/WarningDialog"
import { useDebounceSearch } from "../../hooks/useDebouncedSearch"
import { useBranchesStore } from "../../store"
import { CameraServicesFilterOptions, PaginatedCamerasList } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import {
  defaultFilterStatus,
  defaultServiceFilterOptions,
  FilterStatusParamConfig,
  ServiceFilterOptionsParamConfig,
} from "../../utils/queryParamCustomConfigs"
import CameraPlaceholder from "./assets/cameraPlaceholder.svg"
import NewCamera from "./components/CameraAddEdit"
import CameraCard from "./components/CameraCard"

import styles from "./CamerasList.module.scss"

type CameraPaginated = definitions["PaginatedCamerasList"]
type CameraHealthStats = definitions["CamerasHealthStatistics"]
type NodesList = definitions["NodeUpdateRetrieve"]

const CamerasList = () => {
  const [query, setQuery] = useQueryParams({
    servicesMode: withDefault(StringParam, "or"),
    searchValue: withDefault(StringParam, ""),
    selectedNode: withDefault(StringParam, ""),
    filterActive: BooleanParam,
    activeWithNoServicesFilter: BooleanParam,
    filterStatus: withDefault(FilterStatusParamConfig, defaultFilterStatus),
    selectedServices: withDefault(ServiceFilterOptionsParamConfig, defaultServiceFilterOptions),
  })

  const [isAddCameraOpen, setIsAddCameraOpen] = useState(false)
  const [filteredCamerasIds, setFilteredCamerasIds] = useState("")
  const [selectedCameras, setSelectedCameras] = useState<number[]>([])
  const [isSelectMode, setIsSelectMode] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)
  const queryClient = useQueryClient()

  const debouncedSearchValue = useDebounceSearch(query.searchValue, 500)

  const lgScreen = useMediaQuery("(max-width:1280px)")
  const xLargeScreen = useMediaQuery("(min-width:1900px)")

  const cameraListLimit = xLargeScreen ? 12 : 8

  // handle click on one of camera status filters
  const handleCameraStatusFilter = (filterType: "healthy" | "attention" | "down") => {
    switch (filterType) {
      case "healthy":
        setQuery({
          filterStatus: {
            camerasHealthy: !query.filterStatus.camerasHealthy,
            camerasDown: false,
            camerasAttention: false,
          },
        })
        break
      case "attention":
        setQuery({
          filterStatus: {
            camerasHealthy: false,
            camerasDown: false,
            camerasAttention: !query.filterStatus.camerasAttention,
          },
        })
        break
      case "down":
        setQuery({
          filterStatus: {
            camerasHealthy: false,
            camerasDown: !query.filterStatus.camerasDown,
            camerasAttention: false,
          },
        })
        break
    }
  }

  // search cameras with debounce
  const handleSearch = (value: string) => {
    setQuery({ searchValue: value })
  }

  const resetCameraSelection = () => setSelectedCameras([])

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  const { data: healthStats, isLoading: healthStatsLoading } = useQuery<CameraHealthStats>(
    ["fetchCamerasHealthStats", selectedBranch],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasHealthStats({
        branch: queryKey[1] as number,
      }),
    {
      refetchInterval: 60000,
      enabled: !!selectedBranch,
    }
  )

  const { data: nodesList, isLoading: nodesListLoading } = useQuery<NodesList[], AxiosError>(
    ["fetchNodesList", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchNodesList({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  const {
    data: paginatedCameraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<PaginatedCamerasList, AxiosError>(
    [
      "fetchCamerasPaginated",
      selectedBranch,
      debouncedSearchValue,
      cameraListLimit,
      query.activeWithNoServicesFilter ? { none: true } : query.selectedServices,
      filteredCamerasIds,
      query.selectedNode,
      query.servicesMode,
      query.activeWithNoServicesFilter || query.filterActive,
    ],
    ({ queryKey, pageParam = 1 }) =>
      VisionAPI.fetchCamerasPaginated({
        branch: queryKey[1] as number,
        search: queryKey[2] as string,
        limit: queryKey[3] as number,
        services: queryKey[4] as CameraServicesFilterOptions,
        ids: queryKey[5] as string,
        node: queryKey[6] as string,
        services_filter_type: queryKey[7] as "or" | "and",
        active: queryKey[8] as boolean,
        ordering: "-created_at,-id",
        page: pageParam,
      }),
    {
      enabled: !!selectedBranch,
      getNextPageParam: (lastPage: PaginatedCamerasList) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
    }
  )
  const { mutate: deleteSelectedCameras, isLoading: isLoadingCamerasDeletion } = useMutation(
    (selectedCameras: number[]) => VisionAPI.deleteMultipleCameras({ cameras: selectedCameras }),
    {
      onSuccess: async () => {
        await queryClient?.invalidateQueries("fetchCamerasPaginated")
        NotificationUtils.toast("Cameras deleted successfully", {
          snackBarVariant: "positive",
        })
        handleCloseDeleteDialog()
        resetCameraSelection()
      },
    }
  )

  // setting cameras id endpoint filter string to include selected ids (depending on camera status selected)
  useEffect(() => {
    let camerasIds = ""
    if (healthStats && !healthStatsLoading) {
      if (query.filterStatus.camerasHealthy === true) {
        camerasIds = healthStats.up.join(",")
      }
      if (query.filterStatus.camerasDown === true) {
        camerasIds = healthStats.down.join(",")
      }
      if (query.filterStatus.camerasAttention === true) {
        camerasIds = healthStats.needs_attention.join(",")
      }
    }

    setFilteredCamerasIds(camerasIds)
  }, [healthStats, healthStatsLoading, query.filterStatus])

  const handleClose = () => {
    setIsAddCameraOpen(false)
  }

  const handleOpenAddCamera = () => {
    setIsAddCameraOpen(true)
  }

  const handleChangeServices = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({
      selectedServices: {
        ...query.selectedServices,
        [event.target.id]: event.target.checked,
      },
    })
  }

  const handleServicesMode = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery({ servicesMode: event.target.value as "or" | "and" })
  }

  const handleSelectNode = (event: InputChangeEvent) => {
    setQuery({ selectedNode: event.target.value as string })
  }

  const triggerSelectMode = () => setIsSelectMode(true)

  const stopSelectMode = () => setIsSelectMode(false)

  const toggleCameraSelection = (cameraId: number) => {
    setSelectedCameras((prevSelectedCameras) => {
      if (prevSelectedCameras.includes(cameraId)) {
        // Camera already selected, so remove it
        return prevSelectedCameras.filter((id) => id !== cameraId)
      } else {
        // Camera not selected, so add it
        return [...prevSelectedCameras, cameraId]
      }
    })
  }

  const toggleSelectMode = () => {
    if (isSelectMode) {
      resetCameraSelection()
    } else {
      triggerSelectMode()
    }
  }

  const handleSelectAllCameras = () => {
    if (paginatedCameraData && !isLoading) {
      const allCameras = paginatedCameraData?.pages.flatMap((page) => page.results).map((camera) => camera.id as number)
      setSelectedCameras(allCameras)
    }
  }

  const handleDeleteSelectedCameras = () => {
    deleteSelectedCameras(selectedCameras)
  }

  const handleActiveFilterClick = (filter: "active" | "inactive" | "activeWithNoServices") => {
    if (filter === "inactive") {
      setQuery({ filterActive: query.filterActive === false ? null : false, activeWithNoServicesFilter: false })
    } else if (filter === "active") {
      setQuery({ filterActive: query.filterActive === true ? null : true, activeWithNoServicesFilter: false })
    } else if (filter === "activeWithNoServices") {
      setQuery({ filterActive: null, activeWithNoServicesFilter: !query.activeWithNoServicesFilter })
    }
  }

  // toggle select mode on/off based on number of selected cameras
  // if there are selected cameras -> select mode is on , otherwise off
  useEffect(() => {
    if (selectedCameras?.length > 0) {
      triggerSelectMode()
    } else {
      stopSelectMode()
    }
  }, [selectedCameras])

  // loading skeletons
  let loadingPlaceholders = new Array(8).fill(null).map((r, i) => (
    <Grid item md={lgScreen ? 6 : 4} sm={6} xs={12} xl={xLargeScreen ? 3 : 4} key={i}>
      <Skeleton variant="rectangular" height={536} width="auto" />
    </Grid>
  ))

  const healthyCamerasCount = healthStats?.up && healthStats?.up?.length
  const downCamerasCount = healthStats?.down && healthStats?.down?.length
  const unHealthyCamerasCount = healthStats?.needs_attention && healthStats?.needs_attention?.length

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Take control of all cameras and manage it easily"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Cameras List
      </Typography>

      {/* search cameras */}
      <div className={styles.header}>
        <div className={styles.headerItems}>
          <div className={styles.searchCameras}>
            <Search
              placeholder="E.g. CAMERA: 1,TAG: ACTIVE"
              handleSearch={handleSearch}
              searchValue={query.searchValue}
              topHeader={false}
              type="cameras"
            />
          </div>

          <ServicesFilter
            selectedServices={query.selectedServices}
            handleChangeServices={handleChangeServices}
            handleServicesMode={handleServicesMode}
            servicesMode={query.servicesMode as "and" | "or"}
          />
          <Select
            id="node"
            placeholder="Nodes"
            loading={nodesListLoading}
            optionsWithValues={
              nodesList && Array.isArray(nodesList)
                ? [{ id: "", name: "All Nodes" }, ...nodesList]?.map((node) => {
                    return {
                      label: node.name,
                      value: node.id!,
                    }
                  })
                : [
                    {
                      label: "All Nodes",
                      value: "",
                    },
                  ]
            }
            value={parseInt(query.selectedNode) || ""}
            handleChange={handleSelectNode}
            disabled={nodesListLoading}
            size={140}
            menuProps={{
              menuMaxContent: true,
            }}
          />
        </div>
        <Button
          onClick={handleOpenAddCamera}
          variant="primary"
          startIcon={<AddCircleIcon fontSize="small" />}
          className={styles.addBtn}
        >
          Add New Camera
        </Button>
      </div>

      <div className={styles.filtersRow}>
        {/* quick filters */}
        <Grid container spacing={1}>
          <Grid item>
            <Chip
              removable={query.filterStatus.camerasHealthy}
              clickable
              onClick={() => handleCameraStatusFilter("healthy")}
              onRemove={() => null}
              isSelected={query.filterStatus.camerasHealthy}
              disabled={!healthyCamerasCount}
            >
              <LabelOutlinedIcon
                className={styles.chipIcon}
                fontSize="small"
                sx={{
                  color: !!healthyCamerasCount ? "var(--green-background-1)" : "",
                }}
              />
              {healthStatsLoading ? (
                <CircularProgress size={12} className={styles.loading} />
              ) : (
                healthyCamerasCount ?? ""
              )}{" "}
              Healthy
            </Chip>
          </Grid>
          <Grid item>
            <Chip
              removable={!!query.filterStatus.camerasAttention}
              clickable
              onClick={() => handleCameraStatusFilter("attention")}
              onRemove={() => null}
              isSelected={!!query.filterStatus.camerasAttention}
              disabled={!unHealthyCamerasCount}
            >
              <LabelOutlinedIcon
                className={styles.chipIcon}
                fontSize="small"
                sx={{
                  color: !!unHealthyCamerasCount ? "var(--brown-background-1)" : "",
                }}
              />
              {healthStatsLoading ? (
                <CircularProgress size={12} className={styles.loading} />
              ) : (
                unHealthyCamerasCount ?? ""
              )}{" "}
              Unhealthy
            </Chip>
          </Grid>
          <Grid item>
            <Chip
              removable={!!query.filterStatus.camerasDown}
              clickable
              onClick={() => handleCameraStatusFilter("down")}
              onRemove={() => null}
              isSelected={!!query.filterStatus.camerasDown}
              disabled={!downCamerasCount}
            >
              <LabelOutlinedIcon
                className={styles.chipIcon}
                fontSize="small"
                sx={{
                  color: !!downCamerasCount ? "var(--red-background-1)" : "",
                }}
              />
              {healthStatsLoading ? <CircularProgress size={12} className={styles.loading} /> : downCamerasCount ?? ""}{" "}
              Down
            </Chip>
          </Grid>
          <Grid item>
            <Chip
              removable={query.filterActive === false}
              clickable
              onClick={() => handleActiveFilterClick("inactive")}
              onRemove={() => null}
              isSelected={query.filterActive === false}
            >
              Inactive
            </Chip>
          </Grid>
          <Grid item>
            <Chip
              removable={!!query.filterActive}
              clickable
              onClick={() => handleActiveFilterClick("active")}
              onRemove={() => null}
              isSelected={!!query.filterActive}
            >
              Active
            </Chip>
          </Grid>
          <Grid item>
            <Chip
              removable={!!query.activeWithNoServicesFilter}
              clickable
              onClick={() => handleActiveFilterClick("activeWithNoServices")}
              onRemove={() => null}
              isSelected={!!query.activeWithNoServicesFilter}
            >
              Active with no services
            </Chip>
          </Grid>
        </Grid>
        <div className={styles.selectedCameras}>
          <Typography variant="p" noWrap>
            {selectedCameras?.length} Cameras selected
          </Typography>
          <Typography
            variant="label"
            color="important"
            variantColor={2}
            noWrap
            className={styles.selectAll}
            onClick={handleSelectAllCameras}
          >
            | Select all
          </Typography>
          <Typography
            variant="label"
            color={!selectedCameras?.length ? "neutral" : "negative"}
            variantColor={2}
            noWrap
            className={styles.deleteAll}
            style={{ pointerEvents: !selectedCameras?.length ? "none" : "auto" }}
            onClick={handleOpenDeleteDialog}
          >
            | Delete selected
          </Typography>
        </div>
        <Button
          variant={isSelectMode ? "secondary" : "primary"}
          size="small"
          className={styles.toggleSelectMode}
          onClick={toggleSelectMode}
        >
          {isSelectMode ? "Cancel selection" : "Select cameras"}
        </Button>
      </div>

      {/* add new camera */}
      {isAddCameraOpen && <NewCamera handleClose={handleClose} isOpen />}

      {/*  search cameras */}
      {paginatedCameraData && paginatedCameraData?.pages?.length > 0 ? (
        <InfiniteScroll
          dataLength={paginatedCameraData?.pages.reduce((acc, page) => acc + page.results.length, 0)}
          hasMore={hasNextPage ? true : false}
          next={fetchNextPage}
          loader={<CircularProgress className={styles.loadingMoreCameras} />}
          endMessage={
            paginatedCameraData.pages[0].count > 0 ? (
              <Typography variant="h3-regular" align="center">
                You reached the bottom
              </Typography>
            ) : (
              <div className={styles.CameraPlaceholderWrapper}>
                <img src={CameraPlaceholder} className={styles.cameraPlaceholder} alt="No cameras found" />
                <div>
                  <Typography variant="h2-bold">No Cameras Found.</Typography>
                </div>
              </div>
            )
          }
          scrollThreshold={0.9}
        >
          <Grid container spacing={2} style={{ display: "flex" }}>
            {/* cameras list */}
            {paginatedCameraData?.pages.map((page, i) => (
              <React.Fragment key={i}>
                {page.results.map((camera: CameraPaginated, i: number) => (
                  <Grid key={i} item md={lgScreen ? 6 : 4} sm={6} xs={12} xl={xLargeScreen ? 3 : 4}>
                    <CameraCard
                      handleSelectToggle={toggleCameraSelection}
                      isSelectMode={isSelectMode}
                      isSelected={selectedCameras.includes(camera.id!)}
                      hasSelect
                      camera={camera}
                      key={camera.id}
                      healthStats={healthStats}
                    />
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        </InfiniteScroll>
      ) : (
        <Grid container spacing={2} style={{ display: "flex" }}>
          {isLoading && <Fragment>{loadingPlaceholders}</Fragment>}
        </Grid>
      )}
      <WarningDialog
        isOpen={isDeleteDialogOpen}
        isLoading={isLoadingCamerasDeletion}
        actionTitle="Delete"
        content="Be aware by deleting these cameras, this action can't be undone."
        onConfirm={handleDeleteSelectedCameras}
        onCancel={handleCloseDeleteDialog}
        dialogTitle={`Delete ${selectedCameras?.length} selected cameras?`}
      />
    </Fragment>
  )
}

export default CamerasList
