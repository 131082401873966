import React, { FC, useState, useEffect, Fragment } from "react"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import GraphEmptyState from "../../../../components/graphs/GraphEmptyState"
import barChartLoading from "../../../EntranceGates/assets/barChartLoading.mp4"

import styles from "./TotalTimeSpentBar.module.scss"

interface Props {
  totalTimeSpent: Duration
  isLoading: boolean
  refCountsTime: React.MutableRefObject<any>
}

const totalDuration = {
  days: 0,
  hours: 24,
  minutes: 0,
}
const TotalTimeSpentBar: FC<Props> = ({ totalTimeSpent, isLoading, refCountsTime }) => {
  const [totalTimeSpentPercentage, setTotalTimeSpentPercentage] = useState(0)
  //calculating total down time percentage over total picked time range
  useEffect(() => {
    // converting both down time and total time range to milliseconds (to calculate a ratio)
    const totalTimeSpentInMilliSeconds = moment.duration(totalTimeSpent, "seconds").asMilliseconds()
    const totalTimeInMilliseconds = moment.duration(totalDuration, "seconds").asMilliseconds()
    const totalTimeSpentPercentage = totalTimeInMilliseconds
      ? totalTimeSpentInMilliSeconds / totalTimeInMilliseconds
      : 0
    setTotalTimeSpentPercentage(totalTimeSpentPercentage * 100)
  }, [totalTimeSpent])
  return (
    <Grid item xs={12} md={3}>
      <div className={styles.wrapper} ref={refCountsTime}>
        {!isLoading && ((!totalTimeSpent && !totalDuration) || (totalTimeSpent && totalTimeSpentPercentage <= 0)) ? (
          <GraphEmptyState isAverageTimeSpentInside={true} />
        ) : isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.loadingContainer}
          >
            <Grid item>
              <video autoPlay={true} loop={true} width="300" height="200">
                <source src={barChartLoading} type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        ) : (
          <CircularProgressbarWithChildren
            value={totalTimeSpentPercentage}
            styles={buildStyles({
              textColor: "var(--grey-background-2)",
              strokeLinecap: "round",
              pathColor: "var(--indigo-background-1)",
              trailColor: "var(--indigo-background-2)",
            })}
          >
            <div className={styles.downTimeStatsWrapper}>
              <Typography variant="label" variantColor={2} className={styles.header}>
                Average Time Spent Inside
              </Typography>
              <Typography variant="a" variantColor={2} color="indigo">
                {totalTimeSpent.days && totalTimeSpent.days > 0 ? (
                  <Fragment>
                    <span className={styles.numeric}>{totalTimeSpent.days}</span>
                    day{totalTimeSpent.days > 1 ? "s" : ""}{" "}
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
                <span className={styles.numeric}> {totalTimeSpent.hours}</span>hrs{" "}
                <span className={styles.numeric}> {totalTimeSpent.minutes}</span>mins{" "}
              </Typography>
              <Typography variant="span" variantColor={2} className={styles.totalDuration}>
                out of 24 hours
              </Typography>
            </div>
          </CircularProgressbarWithChildren>
        )}
      </div>
    </Grid>
  )
}

export default TotalTimeSpentBar
