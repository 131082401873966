import { FC, useState, useEffect, Fragment, ChangeEvent } from "react"

import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import { Box, Card, Grid } from "@mui/material"

import { Select, Typography } from "@synapse-analytics/synapse-ui"

import ButtonSwitch from "../../../components/Buttons/ButtonSwitch"
import DailyLineGraph from "../../../components/graphs/dailyLineGraph"
import HourlyLineGraph from "../../../components/graphs/hourlyLineGraph"
import CountTable from "../../../components/tables/CountTable"
import { definitions } from "../../../types/Generated/apiTypes"
import { convertCarsDataToHourlyLineGraph, calculateCarsSumPerTime } from "../../../utils/carsUtils"
import { convertDataToHourlyLineGraph, calculateSumPerTime } from "../../../utils/counterUtils"
import { convertDailyDwellingToTableLogs, convertDwellingCountsToLineGraph } from "../../../utils/dwellingUtils"
import { convertShopsDataToHourlyLineGraph, getEntityLogs } from "../../../utils/shopUtils"

import styles from "./HourlyData.module.scss"

type Gate = definitions["Gate"]
type GatesObj = {
  [id: number]: Gate
}
type CounterLogs = definitions["CounterLogs"]
type CarCounterLogs = definitions["CarCounterLogs"]
type MissingData = definitions["MissingSliceCounterLog"]
type DwellingAreaDailyCounts = definitions["DwellingAreaDailyCounts"]

interface Props {
  timeGrain: "hour" | "day"
  logsData?: CounterLogs[]
  tenantData?: CounterLogs[]
  carsData?: CarCounterLogs[]
  missingData?: MissingData[]
  singleDwellingData?: DwellingAreaDailyCounts[]
  entranceEntities?: GatesObj
  loading: boolean
  reference: any
  isTenantDetails?: Boolean
  tenantId?: number
  interval?: Duration
  dayToFilter?: number | null
  shouldIncludeMissingData?: boolean
  isGates?: boolean
  isCars?: boolean
  isSingleDwelling?: boolean
}
type graphPoint = {
  x: Date
  y: number
}
type GraphData = {
  id: string
  data: graphPoint[]
}
type tableData = {
  timestamp: Date | string
  "Count In"?: number
  "Count Out"?: number
  Difference?: number
  "Male Count"?: number
  "Female Count"?: number
  isMissing?: boolean
  dwellingValue?: number
  missingInfo?: {
    fileName: string
    entities: string
    countIn: number
    countOut: number
  }[]
}
const HourlyData: FC<Props> = ({
  timeGrain,
  loading,
  logsData,
  carsData,
  missingData,
  singleDwellingData,
  reference,
  isCars,
  isGates,
  isSingleDwelling,
  isTenantDetails,
  tenantId,
  tenantData,
  interval,
  dayToFilter,
  entranceEntities,
  shouldIncludeMissingData,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [lineGraphData, setLineGraphData] = useState<GraphData[]>([
    {
      id: "",
      data: [{ x: new Date(), y: 0 }],
    },
  ])
  const [countTableData, setCountTableData] = useState<tableData[]>()
  const [dataType, setDataType] = useState<"Gates counts" | "Dwelling counts" | "Dwelling time">(
    isSingleDwelling ? "Dwelling counts" : "Gates counts"
  )

  // This useEffect handles the data processing and updates for rendering line graphs and count tables based on different scenarios.
  // It checks loading status and processes data accordingly for tenant-related, car-related, and general logs-related scenarios.
  // The processed data is then combined with missing logs (if available) and sorted before updating the state variables.

  useEffect(() => {
    // Check if data is still loading
    if (loading) return

    // Helper function to process and set data for tenant-related scenarios
    const processTenantData = () => {
      setLineGraphData(convertShopsDataToHourlyLineGraph(getEntityLogs(tenantData, tenantId!)))
      setCountTableData(calculateSumPerTime(getEntityLogs(tenantData, tenantId!)))
    }

    // Helper function to process and set data for dwelling-related scenarios
    const processDwellingData = () => {
      setLineGraphData(convertDwellingCountsToLineGraph(singleDwellingData!, dataType))
      setCountTableData(
        convertDailyDwellingToTableLogs(singleDwellingData!, dataType as "Dwelling counts" | "Dwelling time")
      )
    }

    // Helper function to process and set data for car-related scenarios
    const processCarsData = () => {
      setLineGraphData(convertCarsDataToHourlyLineGraph(carsData))
      setCountTableData(calculateCarsSumPerTime(carsData))
    }

    // Check conditions for different data scenarios
    if (isTenantDetails && tenantData) {
      // Tenant-related scenario
      processTenantData()
    } else if (isCars && carsData) {
      // Car-related scenario
      processCarsData()
    } else if (isSingleDwelling && singleDwellingData) {
      processDwellingData()
    } else if (logsData) {
      // Gates Logs related scenario
      const logsSumPerTimestamp = calculateSumPerTime(logsData, dayToFilter, entranceEntities, missingData)
      const lineGraphData = convertDataToHourlyLineGraph(logsSumPerTimestamp, dataType)

      // Set line graph data with combined logs
      setLineGraphData(lineGraphData)

      // Set table data with combined logs
      setCountTableData(logsSumPerTimestamp)
    }
  }, [
    logsData,
    loading,
    isTenantDetails,
    tenantId,
    tenantData,
    dayToFilter,
    carsData,
    isCars,
    missingData,
    entranceEntities,
    shouldIncludeMissingData,
    dataType,
    isSingleDwelling,
    singleDwellingData,
  ])
  const handleDataTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDataType(event.target.value as "Gates counts" | "Dwelling counts" | "Dwelling time")
  }
  const dataOptions = isGates
    ? ["Gates counts", "Dwelling counts", "Dwelling time"]
    : ["Dwelling counts", "Dwelling time"]

  return (
    <Card className={styles.wrapper} ref={reference}>
      <Grid
        container
        className={styles.header}
        style={{
          marginBottom: shouldIncludeMissingData ? 0 : "16px",
        }}
      >
        <Grid item xs={12} md={6} className={styles.titleAndSelect}>
          <Typography variant="h2-bold" variantColor={2} noWrap textTransform="lowercase">
            {timeGrain === "hour" ? "Hourly " : "Daily "}
            {dataType}
            {activeTab === 0 ? " line graph" : " table"}
          </Typography>
          {(isGates || isSingleDwelling) && (
            <div className={styles.dataSelect}>
              <Select options={dataOptions} value={dataType} handleChange={handleDataTypeChange} />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          <ButtonSwitch
            activePage={activeTab}
            pages={["Graph", "Table"]}
            handleSelectButton={setActiveTab}
            disabled={loading === true ? true : false}
            pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
          />
        </Grid>
      </Grid>
      {shouldIncludeMissingData && (
        <Box display="flex" alignItems="center" mb={activeTab === 1 ? 6 : 0}>
          <span className={styles.colorSquare} />
          <Typography variant="p">Missing data generated by the admin</Typography>
        </Box>
      )}
      {activeTab === 0 ? (
        <Fragment>
          {timeGrain === "hour" && !isSingleDwelling ? (
            <HourlyLineGraph
              data={lineGraphData}
              graphHeight={isTenantDetails ? 420 : 350}
              isLoading={loading}
              noCheckBox={isTenantDetails || dataType !== "Gates counts"}
              hasGenderAge={isTenantDetails}
            />
          ) : (
            <DailyLineGraph
              data={lineGraphData}
              graphHeight={isTenantDetails ? 420 : 350}
              isLoading={loading}
              noCheckBox={isTenantDetails || dataType !== "Gates counts"}
              interval={interval}
              hasGenderAge={isTenantDetails || isSingleDwelling}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          <CountTable
            data={countTableData!}
            loading={loading}
            timeGrain={timeGrain}
            isTenantDetails={!!isTenantDetails}
            shouldIncludeMissingData={shouldIncludeMissingData}
            dwellingType={dataType !== "Gates counts" ? (dataType as "Dwelling counts" | "Dwelling time") : undefined}
          />
        </Fragment>
      )}
    </Card>
  )
}
export default HourlyData
