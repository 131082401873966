import { FC, Fragment, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"

import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop"
import { CircularProgress, Grid } from "@mui/material"

import { Button, RoundedTab, Skeleton, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import NoEvents from "../../../assets/noListData.svg?react"
import { PaginatedCarsLogs, PaginatedSecurityEvents } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../../utils/genericHelpers"
import EventCard from "./EventCard"

import styles from "./LiveFeed.module.scss"

type CounterEventPaginated = definitions["SecurityViolationList"]
type CarEventPaginated = definitions["CarCounterLogRetrieve"]
interface Props {
  activeTab: number
  handleChangeActiveTab: (tab: number) => void
}

const logsLimit = 5
const weekAgo = moment().subtract(7, "days")
const endDate = moment()

const loadingPlaceholders = new Array(logsLimit).fill(null).map((_r, i) => (
  <Grid item xs={12} key={i}>
    <Skeleton variant="rectangular" height={135} width="auto" />
  </Grid>
))
const LiveFeed: FC<Props> = ({ activeTab, handleChangeActiveTab }) => {
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false)
  const feedContainerRef = useRef<HTMLDivElement>(null)

  const scrollToTop = () => {
    if (feedContainerRef.current) {
      feedContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  const handleScroll = () => {
    if (feedContainerRef.current) {
      const scrollTop = feedContainerRef.current.scrollTop
      setShouldShowScrollButton(scrollTop > 0) // Show button if scrolled down
    }
  }

  const {
    data: paginatedSecurityEvents,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<PaginatedSecurityEvents | PaginatedCarsLogs, AxiosError>(
    [
      "fetchSecurityLogs",
      logsLimit,
      activeTab === 0 ? weekAgo.format("YYYY-MM-DD") : weekAgo.toISOString(),
      activeTab === 0 ? endDate.format("YYYY-MM-DD") : endDate.toISOString(),
    ],
    async ({ queryKey, pageParam = 1 }) =>
      activeTab === 0
        ? VisionAPI.fetchSecurityEventsLogsPaginated({
            page: pageParam as number,
            limit: queryKey[1] as number,
            from_date: queryKey[2] as string,
            to_date: queryKey[3] as string,
          })
        : VisionAPI.fetchCarLogsList({
            page: pageParam as number,
            limit: queryKey[1] as number,
            start_dt: queryKey[2] as string,
            end_dt: queryKey[3] as string,
          }),
    {
      getNextPageParam: (lastPage: PaginatedSecurityEvents | PaginatedCarsLogs) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
      refetchInterval: 30000,
    }
  )

  return (
    <div className={styles.wrapper}>
      <Tabs rounded activeTab={activeTab} className={styles.tabs}>
        <RoundedTab
          label="Security stream"
          onClick={() => handleChangeActiveTab(0)}
          value={0}
          selected={activeTab === 0}
          id="0"
        />
        <RoundedTab
          label="Cars stream"
          onClick={() => handleChangeActiveTab(1)}
          value={1}
          selected={activeTab === 1}
          id="1"
        />
      </Tabs>
      {isLoading ? (
        <Grid container spacing={2} className={styles.content}>
          {loadingPlaceholders}
        </Grid>
      ) : paginatedSecurityEvents && paginatedSecurityEvents?.pages?.length > 0 ? (
        <div className={styles.content} ref={feedContainerRef} onScroll={handleScroll} id="feed-container">
          <InfiniteScroll
            dataLength={paginatedSecurityEvents?.pages.reduce((acc, page) => acc + page.results.length, 0)}
            hasMore={hasNextPage ? true : false}
            next={fetchNextPage}
            loader={<CircularProgress className={styles.loadingMoreEvents} />}
            scrollableTarget="feed-container"
            endMessage={
              paginatedSecurityEvents.pages[0].count > 0 ? (
                <div className={styles.scrollEnd}>
                  <Typography variant="h3-regular" align="center">
                    You reached the bottom
                  </Typography>
                </div>
              ) : (
                <div className={styles.eventsPlaceholderWrapper}>
                  <NoEvents />
                  <div>
                    <Typography variant="h2-bold" className={styles.boldTitle}>
                      No feed yet
                    </Typography>
                  </div>
                </div>
              )
            }
            scrollThreshold={0.5}
          >
            <Grid container spacing={2}>
              {/* cameras list */}
              {paginatedSecurityEvents?.pages.map((page, i) => (
                <Fragment key={i}>
                  {page.results.map((eventLog: CounterEventPaginated & CarEventPaginated, i: number) => (
                    <Grid container key={i} item xs={12}>
                      <EventCard eventLog={eventLog} isCarEvent={activeTab === 1} />
                    </Grid>
                  ))}
                </Fragment>
              ))}
              {shouldShowScrollButton && (
                <div className={styles.scrollToTopCTA}>
                  <Button
                    startIcon={<VerticalAlignTopIcon className={styles.arrowUp} />}
                    onClick={scrollToTop}
                    variant="secondary"
                    size="large"
                  >
                    Scroll top
                  </Button>
                </div>
              )}
            </Grid>
          </InfiniteScroll>
        </div>
      ) : (
        <div className={styles.eventsPlaceholderWrapper}>
          <NoEvents />
          <div>
            <Typography variant="h2-bold" className={styles.boldTitle}>
              No feed yet
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}
export default LiveFeed
