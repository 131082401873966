// TODO:: Refactor this component into a generic component
import { useState, useEffect, Fragment } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box } from "@mui/material"
import { useMediaQuery, Button } from "@mui/material"
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles"

import { ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"
import { intervalToDuration } from "date-fns"

import AdultIcon from "../../assets/adultsIcon.svg?react"
import ChildrenIcon from "../../assets/childrenIcon.svg?react"
import FemaleIcon from "../../assets/femaleIcon.svg?react"
import MaleIcon from "../../assets/maleIcon.svg?react"
import barGraphLoading from "../../screens/EntranceGates/assets/barChartLoading.mp4"
import GraphTooltip from "../GraphTooltip"
import GraphEmptyState from "./GraphEmptyState"
import { gridTheme } from "./gridTheme"

import styles from "./PaginatedBarGraph.module.scss"

const PaginatedBarGraph = (props) => {
  const {
    isLoading,
    setCurrentPage,
    isCar,
    cameraDownTime,
    isSubcategory,
    isCarWeekdayAnalytics,
    isCorridorsCamera,
    isCorridorsPerformance,
    includeMissing,
    isCarGates,
    isBranchesAnalytics,
    barGraphData = [],
    ageGenderKeys,
  } = props

  const theme = useTheme()
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"))

  const [data, setData] = useState(barGraphData)
  const [sliceStIndex, setSliceStIndex] = useState(0)
  const [sliceEndIndex, setSliceEndIndex] = useState(10)

  const colors = {
    male_count: "var(--surface-background-blue-light-purple)",
    female_count: "var(--pink-background-2)",
    adult_count: "var(--purple-background-2)",
    child_count: "var(--teal-background-2)",
    "Missing Count In": "var(--red-background-1)",
    "Count In": "var(--indigo-background-2)",
  }

  const getColor = (bar) => colors[bar.id]

  const hasAgeData = barGraphData?.filter(
    (element) => element.adult_count && element.adult_count !== 0 && element.child_count && element.child_count !== 0
  )

  const hasGenderData = barGraphData?.filter(
    (element) => element.male_count && element.male_count !== 0 && element.female_count && element.female_count !== 0
  )

  const isEmpty =
    !barGraphData ||
    (barGraphData && barGraphData.length < 1) ||
    (ageGenderKeys && ageGenderKeys?.includes("adult_count") && !hasAgeData) ||
    (ageGenderKeys && ageGenderKeys?.includes("male_count") && !hasGenderData)

  // on change between age/gender , reset start and end slicing indexes
  useEffect(() => {
    setSliceStIndex(0)
    setSliceEndIndex(10)
  }, [isLoading])

  // if data length is greater than 10 , allow slicing and pagination
  useEffect(() => {
    if (barGraphData && barGraphData.length > 10) {
      setData(barGraphData.slice(sliceStIndex, sliceEndIndex))
    }
  }, [sliceStIndex, sliceEndIndex, barGraphData])

  // if data length is greater than 10 , allow slicing and pagination
  const handleRightPagination = () => {
    setSliceStIndex(sliceEndIndex)
    if (sliceEndIndex + 10 >= barGraphData.length) {
      setSliceEndIndex(barGraphData.length)
    } else {
      setSliceEndIndex((prevSliceEndIndex) => {
        return prevSliceEndIndex + 10
      })
    }
    setCurrentPage((prevValue) => prevValue + 1)
  }

  const handleLeftPagination = () => {
    if (sliceStIndex - 10 <= 0) {
      setSliceEndIndex(sliceStIndex)
      setSliceStIndex(0)
    } else {
      setSliceEndIndex(sliceStIndex)
      setSliceStIndex((prevSliceStIndex) => {
        return prevSliceStIndex - 10
      })
    }
    setCurrentPage((prevValue) => prevValue - 1)
  }

  return (
    <Fragment>
      {isLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.loadingContainer}>
          <Grid item>
            <video autoPlay={true} loop={true} width="300" height="200">
              <source src={barGraphLoading} type="video/mp4" />
            </video>
          </Grid>
        </Grid>
      ) : !isEmpty ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Box
            className={styles.graphContainer}
            sx={{
              height: { lg: isSubcategory ? 400 : 300, xs: ageGenderKeys ? 415 : 300 },
            }}
          >
            {barGraphData.length > 10 && (
              <div className={styles.barPaginateWrapper}>
                <Button
                  className={styles.barChartPaginateBTN}
                  onClick={handleLeftPagination}
                  disabled={sliceStIndex === 0 ? true : false}
                >
                  <ChevronLeftIcon />
                </Button>
              </div>
            )}
            <ResponsiveBar
              theme={gridTheme}
              data={barGraphData.length > 10 ? data : barGraphData}
              keys={
                ageGenderKeys
                  ? ageGenderKeys
                  : cameraDownTime || isCarWeekdayAnalytics
                  ? ["duration"]
                  : isCarGates
                  ? ["count"]
                  : isBranchesAnalytics
                  ? ["total_count_in_sum"]
                  : includeMissing
                  ? ["Count In", "Missing Count In"]
                  : ["Count In"]
              }
              indexBy={
                ageGenderKeys && !isCorridorsCamera && !isCorridorsPerformance
                  ? isSubcategory
                    ? "sub_category"
                    : "category"
                  : cameraDownTime || isCarWeekdayAnalytics
                  ? "date"
                  : isCorridorsCamera
                  ? "camera"
                  : isCorridorsPerformance
                  ? "entity_name"
                  : isBranchesAnalytics
                  ? "branch_name"
                  : "entity"
              }
              margin={{
                top: xLargeScreen ? 60 : 40,
                right: barGraphData.length > 10 ? 35 : -20,
                bottom: xLargeScreen ? 60 : smallScreen ? 150 : 130,
                left: smallScreen ? 40 : isCarWeekdayAnalytics ? 84 : cameraDownTime || isBranchesAnalytics ? 75 : 35,
              }}
              padding={
                ageGenderKeys
                  ? 0.6
                  : barGraphData.length < 10 && !isCarWeekdayAnalytics
                  ? 0.8
                  : isCarWeekdayAnalytics
                  ? 0.6
                  : 0.6
              }
              valueScale={{ type: "linear" }}
              colors={ageGenderKeys || includeMissing ? getColor : ({ data }) => data.color}
              animate={true}
              enableLabel={false}
              tooltip={(slice) => {
                const interval =
                  (cameraDownTime || isCarWeekdayAnalytics) && intervalToDuration({ start: 0, end: slice.value * 1000 })
                // for gender and age values , capitalize first letter
                const genderAgeIndexValue = ageGenderKeys
                  ? slice.label.charAt(0).toUpperCase() + slice.label.slice(1)
                  : ""
                return includeMissing ? (
                  <GraphTooltip
                    barGraphSlice={slice}
                    missingInfo={slice.data.missingInfo}
                    entity={slice.indexValue}
                    key={slice.id}
                  />
                ) : (
                  <div>
                    <span>{slice.data[slice.value]}</span>
                    <table
                      style={{
                        background: "white",
                        color: "inherit",
                        fontSize: "inherit",
                        borderRadius: "2px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                        padding: "5px 9px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ padding: "3px 5px" }}>
                            <span
                              key="chip"
                              style={{
                                display: "block",
                                width: "12px",
                                height: "12px",
                                background: slice.color,
                              }}
                            />
                          </td>
                          <td style={{ padding: "3px 5px" }}>
                            {cameraDownTime
                              ? `Down Duration in ${slice.indexValue} : `
                              : isCarWeekdayAnalytics
                              ? `Average Time Spent Inside in ${slice.indexValue}s: `
                              : ageGenderKeys
                              ? `${genderAgeIndexValue.replace("_", " ")} :`
                              : isCar
                              ? `${slice.indexValue} Duration :`
                              : isBranchesAnalytics
                              ? `${slice.indexValue} - Total Count : `
                              : slice.label + " : "}
                          </td>
                          <td style={{ padding: "3px 5px" }}>
                            <strong key="value">
                              {cameraDownTime || isCarWeekdayAnalytics
                                ? ` ${interval.days * 24 + interval.hours} Hour(s) and ${interval.minutes} Minute(s) `
                                : slice.value}{" "}
                              {isCar && "Minute(s)"}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 18,
                tickRotation: xLargeScreen ? 0 : -45,
                legend: "",
                legendPosition: "middle",
                legendOffset: 0,
                format: (value) => {
                  return value?.length >= 15 ? value?.slice(0, 13) + ".." : value
                },
              }}
              enableGridX={false}
              enableGridY={true}
              minValue={0}
              gridYValues={5}
              axisLeft={{
                tickValues: 5,
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                format: (value) => {
                  if (cameraDownTime || isCarWeekdayAnalytics) {
                    const interval = intervalToDuration({ start: 0, end: value * 1000 })
                    return `${interval.days * 24 + interval.hours}:${interval.minutes} hr(s)`
                  }
                },
              }}
              labelSkipWidth={0}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              motionStiffness={90}
              motionDamping={15}
              legends={[]}
              groupMode={isCarWeekdayAnalytics ? "grouped" : "stacked"}
            />
            {barGraphData.length > 10 && (
              <div className={styles.barPaginateWrapper}>
                <Button
                  className={styles.barChartPaginateBTN}
                  onClick={handleRightPagination}
                  disabled={sliceEndIndex >= barGraphData.length ? true : false}
                >
                  <ChevronRightIcon />
                </Button>
              </div>
            )}
          </Box>
          {ageGenderKeys && ageGenderKeys.length > 0 && (
            <div className={styles.legendContainer}>
              {ageGenderKeys?.includes("male_count") ? (
                <Fragment>
                  <Typography className={`${styles.legend} ${styles.maleLegend}`} variant="span">
                    <MaleIcon className={styles.legendIcon} />
                    Male
                  </Typography>
                  <Typography className={`${styles.legend} ${styles.femaleLegend}`} variant="span">
                    <FemaleIcon className={styles.legendIcon} />
                    Female
                  </Typography>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="span" className={`${styles.legend} ${styles.childLegend}`}>
                    <ChildrenIcon className={styles.legendIcon} />
                    Children
                  </Typography>
                  <Typography variant="span" className={`${styles.legend} ${styles.adultLegend}`}>
                    <AdultIcon className={styles.legendIcon} />
                    Adults
                  </Typography>
                </Fragment>
              )}
            </div>
          )}
        </div>
      ) : (
        <GraphEmptyState />
      )}
    </Fragment>
  )
}
export default PaginatedBarGraph
