import React, { FC, useState, useEffect, Fragment } from "react"

import TrendingDownIcon from "@mui/icons-material/TrendingDown"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { Card, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { Typography } from "@synapse-analytics/synapse-ui"

import GenderAgeCard from "../../../components/GenderAgeCard"
import loadingDots from "../assets/loadingDots.gif"

import styles from "./StatsCard.module.scss"

interface Props {
  title: string
  numericStat: number
  historicalStat?: number
  femaleStat?: number
  maleStat?: number
  unknownGenderStat?: number
  childCount?: number
  adultCount?: number
  unknownAgeStat?: number
  dwellAndEnter?: number
  dwellAndLeft?: number
  stoodAndEnter?: number
  stoodAndLeft?: number
  categoryFairShare?: number
  subcategoryFairShare?: number
  fullHeight?: boolean
  marginBottom?: boolean
  loading: boolean
  reference: any
  includeFairShare?: boolean
  includeDwelling?: boolean
  isSecondCard?: boolean
  unit?: string
}
const StatsCard: FC<Props> = ({
  title,
  numericStat,
  historicalStat,
  femaleStat,
  unknownGenderStat,
  maleStat,
  fullHeight,
  marginBottom,
  childCount,
  adultCount,
  unknownAgeStat,
  dwellAndEnter,
  dwellAndLeft,
  stoodAndEnter,
  stoodAndLeft,
  categoryFairShare,
  subcategoryFairShare,
  loading,
  reference,
  includeFairShare,
  includeDwelling,
  isSecondCard,
  unit,
}) => {
  const [differenceInPercentage, setDifferenceInPercentage] = useState<number>(0)
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))
  //calculate the percentage of increase/decrease
  useEffect(() => {
    if (historicalStat && historicalStat !== 0) {
      setDifferenceInPercentage(((numericStat - historicalStat) / historicalStat) * 100)
    }
  }, [numericStat, historicalStat])

  return (
    <Fragment>
      <Card
        ref={reference}
        className={styles.statsCard}
        style={{
          height:
            (fullHeight && fullHeight === true) || smallScreen
              ? "100%"
              : includeFairShare
              ? "50%"
              : !isSecondCard
              ? "80%"
              : "20%",
          marginBottom: marginBottom ? 12 : 0,
          justifyContent: fullHeight && fullHeight === true ? "flex-start" : "center",
          width: smallScreen ? "100%" : "auto",
        }}
      >
        <div style={{ padding: 12, marginBottom: 2 }}>
          <Typography variant="p" align="center" variantColor={2} className={styles.cardTitle}>
            {title}
          </Typography>
          <div className={styles.countContent}>
            {loading ? (
              <img src={loadingDots} alt="loading-dots" width={40} />
            ) : (
              <Fragment>
                <Typography variant="h2-bold" align="center" noWrap variantColor={2} className={styles.countNumber}>
                  {numericStat > 0 && numericStat !== Infinity
                    ? `${numericStat.toLocaleString()} ${unit ?? ""}`
                    : "No Data"}
                </Typography>
                {differenceInPercentage !== 0 &&
                differenceInPercentage * -1 !== Infinity &&
                differenceInPercentage !== Infinity &&
                historicalStat &&
                numericStat &&
                numericStat !== 0 &&
                historicalStat !== 0 ? (
                  <div className={styles.changeWrapper}>
                    <Typography
                      variant="p"
                      align="center"
                      variantColor={2}
                      color={differenceInPercentage > 0 ? "positive" : "negative"}
                    >
                      {differenceInPercentage > 0
                        ? differenceInPercentage.toFixed(0)
                        : (differenceInPercentage * -1).toFixed(0)}
                      %
                      {differenceInPercentage > 0 ? (
                        <TrendingUpIcon style={{ marginLeft: 4 }} />
                      ) : (
                        <TrendingDownIcon style={{ marginLeft: 4 }} />
                      )}
                    </Typography>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}
              </Fragment>
            )}
          </div>
          {loading ? (
            <div></div>
          ) : (
            historicalStat !== undefined &&
            numericStat !== 0 &&
            historicalStat !== 0 && (
              <Typography variant="p" align="center" variantColor={2}>
                Last Week {historicalStat.toLocaleString()}
              </Typography>
            )
          )}
        </div>
        {!isSecondCard && (
          <GenderAgeCard
            adultCount={adultCount}
            childCount={childCount}
            unknownGenderStat={unknownGenderStat}
            femaleStat={femaleStat}
            maleStat={maleStat}
            unknownAgeStat={unknownAgeStat}
            dwellAndEnter={dwellAndEnter}
            dwellAndLeft={dwellAndLeft}
            stoodAndEnter={stoodAndEnter}
            stoodAndLeft={stoodAndLeft}
            categoryFairShare={categoryFairShare}
            subcategoryFairShare={subcategoryFairShare}
            loading={loading}
            includeFairShare={includeFairShare}
            includeDwelling={includeDwelling}
          />
        )}
      </Card>
    </Fragment>
  )
}
export default StatsCard
