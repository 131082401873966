import React, { useState, FC, useEffect, Fragment, useMemo } from "react"
import { useQuery } from "react-query"

import { Card, Grid } from "@mui/material"

import { InputText, InputChangeEvent, InputOptionValue } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"
import { FormikProps } from "formik"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import { useBranchesStore } from "../../../store"
import { INewEntity, CamerasRegions, EntityRowData, RegionsObject } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import CameraPlaceholder from "../../Cameras/assets/cameraPlaceholder.svg"
import CameraPreview from "./CameraPreview"

import styles from "./CamerasSelection.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]
type CamerasList = definitions["CameraList"]

interface Props {
  formik: FormikProps<INewEntity>
  entityData?: EntityRowData
  edit?: boolean
}
const CamerasSelection: FC<Props> = ({ formik, entityData, edit }) => {
  const [selectedCameras, setSelectedCameras] = useState<InputOptionValue[]>(formik.values.SelectedCameras)
  const [autoCompleteValue, setAutoCompleteValue] = useState("")
  const [validCameras, setValidCameras] = useState<Camera[]>(formik.values.ValidCameras)
  const [invalidCameras, setInvalidCameras] = useState<Camera[]>(formik.values.invalidCameras)
  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  // fetch cameras list
  const { data: cameras = [], isLoading: isCamerasListLoading } = useQuery<CamerasList[]>(
    ["fetchCamerasShortList", selectedBranch, formik.values.EntityType === "Car Parking" ? "car" : "counter"],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasShortList({ branch: queryKey[1] as number, services: queryKey[2] as string }),
    {
      enabled: !!selectedBranch,
    }
  )

  // Use useMemo to format camerasObj
  const modifiedCameras = useMemo(() => {
    return cameras?.map((camera: CamerasList) => {
      return {
        label: camera.name,
        value: camera.id ?? "",
      }
    })
  }, [cameras])

  const handleAddValidCamera = (newCamera: Camera) => {
    if (!validCameras.includes(newCamera)) setValidCameras((prevValue) => [...prevValue, newCamera])
  }

  const handleAddInvalidCamera = (newCamera: Camera) => {
    if (!invalidCameras.includes(newCamera)) setInvalidCameras((prevValue) => [...prevValue, newCamera])
  }

  const handleRemoveValidCamera = (cameraToRemove: InputOptionValue) => {
    setValidCameras((cameras) => cameras?.filter((camera) => camera.name !== cameraToRemove.label))
  }

  const handleRemoveInvalidCamera = (cameraToRemove: InputOptionValue) => {
    setInvalidCameras((cameras) => cameras?.filter((camera) => camera.name !== cameraToRemove.label))
  }

  const handleCameraUnSelect = (cameraToRemove: InputOptionValue) => {
    setSelectedCameras((cameras) => cameras?.filter((camera) => camera.label !== cameraToRemove.label))
  }

  const handleAutoCompleteChange = (event: InputChangeEvent) => {
    setAutoCompleteValue(event.target.value as string)
  }

  const handleChangeCameras = (selectedCameras: InputOptionValue[]) => {
    setSelectedCameras(selectedCameras)
    formik.setFieldValue("SelectedCameras", selectedCameras)
  }

  useEffect(() => {
    formik.setFieldValue("ValidCameras", validCameras)
    //setting the default starting value for formik field of cameras regions
    let camerasRegions: CamerasRegions = {}
    const existingRegions = formik.values.CamerasRegions
    for (let camera of validCameras) {
      // those arrays are initialized for the sake of extracting and using region points
      // that has been drawn before and fetched from DB
      const existingRegionsArray: RegionsObject[] = []
      // to put each point in its right place
      // which camera does it belong to ?
      // which state (array) it does belong to
      if (edit && entityData) {
        let regionsArray = entityData.regions
        for (const region of regionsArray) {
          if (region.camera === camera.id) {
            // calculate dwelling threshold in form of minutes:seconds
            // which is a form accepted by material ui time picker used to pick up dwelling threshold time in board sidebar
            const dwellingDuration = intervalToDuration({
              start: 0,
              end: (+region!.dwell_thresh! ? +region!.dwell_thresh! : 0) * 1000,
            })
            const dwellingThreshold = new Date()
            dwellingThreshold.setMinutes(dwellingDuration!.minutes!, dwellingDuration.seconds)
            existingRegionsArray.push({
              ...region,
              dwell_thresh: dwellingThreshold,
              ref: React.createRef<HTMLCanvasElement>(),
            })
          }
        }
        // checking if already created regions doesn't have a dwelling region or in or out
        // then add at least one dwelling/in/out region object to give user the option to add those regions if they weren't added before
        const containsAreaIn = existingRegionsArray.map((e) => e.state).indexOf(0)
        const containsAreaOut = existingRegionsArray.map((e) => e.state).indexOf(1)
        const containsDwelling = existingRegionsArray.map((e) => e.state).indexOf(2)
        if (containsAreaIn < 0 && camera.id) {
          existingRegionsArray.push({
            camera: camera.id,
            state: 0,
            points: [],
            ref: React.createRef<HTMLCanvasElement>(),
          })
        }
        if (containsAreaOut < 0 && camera.id) {
          existingRegionsArray.push({
            camera: camera.id,
            state: 1,
            points: [],
            ref: React.createRef<HTMLCanvasElement>(),
          })
        }
        if (containsDwelling < 0 && camera.id) {
          existingRegionsArray.push({
            camera: camera.id,
            state: 2,
            points: [],
            ref: React.createRef<HTMLCanvasElement>(),
          })
        }
        // sort regions array to have the right order of regions in sidebar
        existingRegionsArray.sort((a, b) => a.state - b.state)
      }

      if (camera.id) {
        camerasRegions[`${camera.id}`] = {
          camera: camera,
          regions: existingRegions[`${camera.id}`]
            ? existingRegions[`${camera.id}`].regions
            : edit
            ? [...existingRegionsArray]
            : [
                { camera: camera.id, state: 0, points: [], ref: React.createRef<HTMLCanvasElement>() },
                { camera: camera.id, state: 1, points: [], ref: React.createRef<HTMLCanvasElement>() },
                { camera: camera.id, state: 2, points: [], ref: React.createRef<HTMLCanvasElement>() },
              ],
          isFulfilled: entityData?.cameras.includes(camera.id) ?? existingRegions[`${camera.id}`]?.isFulfilled,
        }
      }
    }
    formik.setFieldValue("CamerasRegions", camerasRegions)
    //eslint-disable-next-line
  }, [validCameras])
  useEffect(() => {
    formik.setFieldValue("SelectedCameras", selectedCameras)
    //eslint-disable-next-line
  }, [selectedCameras])
  useEffect(() => {
    formik.setFieldValue("invalidCameras", invalidCameras)
    //eslint-disable-next-line
  }, [invalidCameras])

  return (
    <Fragment>
      <Card className={styles.camerasSelectionCard}>
        <InputText
          value={autoCompleteValue}
          id="SelectedCameras"
          label="Select Cameras Viewing This Entity"
          optionsWithValues={modifiedCameras}
          multiple={!!cameras}
          selectedValues={selectedCameras}
          placeholder="Search For A Camera"
          handleChange={handleAutoCompleteChange}
          loading={isCamerasListLoading || !cameras}
          setSelectedValues={handleChangeCameras}
          size={830}
          variant="filled"
          hideDescription
          menuProps={{
            menuMaxContent: true,
          }}
        />
        {/* if no cameras are selected yet , show placeholder */}
        <Fragment>
          {selectedCameras && selectedCameras.length > 0 ? (
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              {selectedCameras.map((camera, index) => (
                <Grid item md={4} xs={12} key={camera.value}>
                  <CameraPreview
                    camera={camera}
                    addValidCamera={handleAddValidCamera}
                    removeValidCamera={handleRemoveValidCamera}
                    addInvalidCamera={handleAddInvalidCamera}
                    removeInvalidCamera={handleRemoveInvalidCamera}
                    unSelectCamera={handleCameraUnSelect}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className={styles.CameraPlaceholderWrapper}>
              <div>
                <img src={CameraPlaceholder} className={styles.cameraPlaceholder} alt="No Camera Selected" />
              </div>
            </div>
          )}
        </Fragment>
      </Card>
    </Fragment>
  )
}
export default CamerasSelection
