// Split url into two parts , persona and screen
// E.g. analytics-gates-live => persona: analytics , screen: gates-live
import { routes } from "../routes/routes"
import Auth from "./auth"

// used to extract current active persona and screen
export const splitPersonaAndScreen = (url: string): { persona: string; screen: string } => {
  const [persona, ...screenUrl] = url.split("-")

  const screen = screenUrl.join("-")

  return { persona, screen }
}

// checking if passed route(screen) is active or not
// by comparing that given route with current active screen
// to highlight the active screen item in the sidebar
export const checkActiveScreen = (activeScreen: string, route: string) => {
  // get the actual screen route not the whole parent/child route
  // E.g => heatmap-floor/analytics-heatmap-live
  const pagePathName = route?.split("/").reverse()
  // E.g => ["analytics-heatmap-live","heatmap-floor"] reversed and splitted by "/"
  const routeWithoutPersona = splitPersonaAndScreen(pagePathName[0])
  // split analytics-heatmap-live into persona : analytics , screen: heatmap-live
  return activeScreen === routeWithoutPersona.screen
}
// get first authorized screen for the given persona
// called when toggling personas and when first deciding landing screen.
export const getFirstAuthorizedScreen = (persona: string): string => {
  if (persona === "analytics") {
    if (Auth.getPeopleCountPermission()) {
      return `/${routes?.gates}/${routes?.liveGates}`
    } else if (Auth.getHeatmapPermission()) {
      return `/${routes?.heatmapLive}`
    } else if (Auth.getCarsAnalyticsPermission()) {
      return `/${routes?.carsAnalytics}`
    } else if (Auth.getMcpPermissions()) {
      return `/${routes?.floorsMCP}`
    }
  } else if (persona === "admin") {
    if (Auth.getCamerasPermission()) {
      return `/${routes?.cameraList}`
    } else if (Auth.getFloorPermission()) {
      return `/${routes?.floors}`
    } else if (Auth.getCategoriesPermissions()) {
      return `/${routes?.categoriesList}`
    } else if (Auth.getRegionsPermission()) {
      return `/${routes?.entitiesAndRegions}`
      // FIXME:: ADD Nodes Permissions
    } else if (Auth.getCamerasPermission()) {
      return `/${routes?.nodesList}`
    } else if (Auth.getUsersPermission()) {
      return `/${routes?.users}`
    } else if (Auth.getAPIIntegrationPermission()) {
      return `/${routes?.apiIntegration}`
    } else if (Auth.getCarsAdminPermission()) {
      return `/${routes?.carsList}`
    }
  } else if (persona === "marketing") {
    if (Auth.getCampaignPermission()) {
      return `/${routes?.campaign}`
    }
  } else if (persona === "security") {
    if (Auth.getSecurityCameraStreamPermissions()) {
      return `/${routes?.liveStreams}`
    } else if (Auth.getSecurityEventsPermissions()) {
      return `/${routes?.securityIncidents}`
    } else if (Auth.getSecurityCarsPermissions()) {
      return `/${routes?.carsLogs}`
    }
  }
  return `/${routes?.noPermissions}`
}
