import React, { FC, useEffect, useState } from "react"

import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

import placeholder from "../assets/NoHistoryLogs.svg"

import styles from "./Placeholder.module.scss"

function Alert(props: any) {
  return <MuiAlert elevation={6} variant={"filled"} {...props} />
}
interface Props {
  alertMessage?: string
  openSnackbar?: boolean
  selectionType?: string
}
const Placeholder: FC<Props> = ({ alertMessage, selectionType, openSnackbar }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (openSnackbar) {
      setOpen(openSnackbar)
    }
  }, [openSnackbar])

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  return (
    <div className={styles.placeholderWrapper}>
      <div>
        <img src={placeholder} className={styles.placeholder} alt="No Floor/Camera selected" />
      </div>
      {alertMessage && (
        <div>
          <Snackbar
            open={open}
            classes={{ anchorOriginBottomCenter: styles.snackBar }}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <div>
              <Alert severity="warning" onClose={handleClose}>
                {alertMessage}
              </Alert>
            </div>
          </Snackbar>
        </div>
      )}
    </div>
  )
}
export default Placeholder
