import React, { FC, useEffect, useState, Fragment } from "react"
import { useQuery } from "react-query"

import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { CircularProgress } from "@mui/material"

import { Typography, Chip, InputOptionValue } from "@synapse-analytics/synapse-ui"
import clsx from "clsx"

import { VisionAPI } from "../../../API/VisionAPI"
import { definitions } from "../../../types/Generated/apiTypes"
import cameraAttentionPlaceholder from "../assets/cameraAttention.svg"
import cameraDownPlaceholder from "../assets/cameraDown.svg"
import cameraHealthyPlaceholder from "../assets/cameraHealthy.svg"
import check from "../assets/check.png"

import styles from "./CameraPreview.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]
type CameraHealth = definitions["CameraHealth"]
interface Props {
  camera: InputOptionValue
  addValidCamera?: (validCamera: Camera) => void
  removeValidCamera?: (cameraToRemove: InputOptionValue) => void
  addInvalidCamera?: (validCamera: Camera) => void
  removeInvalidCamera?: (cameraToRemove: InputOptionValue) => void
  unSelectCamera?: (cameraToRemove: InputOptionValue) => void
  isDrawingSelection?: boolean
  selectedCamera?: number
  isFulfilled?: boolean
  margin?: boolean
  entityType?: string
}
const CameraPreview: FC<Props> = ({
  camera,
  addValidCamera,
  removeValidCamera,
  addInvalidCamera,
  removeInvalidCamera,
  isDrawingSelection,
  selectedCamera,
  isFulfilled,
  unSelectCamera,
  margin,
  entityType,
}) => {
  const [invalidAnimation, setInvalidAnimation] = useState(false)

  const { data: cameraData, isLoading: cameraDataLoading } = useQuery<Camera>(
    ["fetchSingleCamera", camera?.value],
    ({ queryKey }) => VisionAPI.fetchSingleCamera(queryKey[1] as number),
    {
      enabled: !!camera?.value,
    }
  )
  const { data: camerasHealth, isLoading: camerasHealthLoading } = useQuery<CameraHealth>(
    ["fetchCameraHealth", camera.value],
    ({ queryKey }) => VisionAPI.fetchCameraHealth(queryKey[1] as number),
    {
      enabled: !!camera.value,
    }
  )
  useEffect(() => {
    if (!cameraDataLoading && cameraData) {
      if (cameraData.sample_frame) {
        !isDrawingSelection && addValidCamera && addValidCamera(cameraData)
      } else {
        !isDrawingSelection && addInvalidCamera && addInvalidCamera(cameraData)
      }
    }
    return () => {
      if (!cameraDataLoading && cameraData) {
        if (cameraData.sample_frame) {
          !isDrawingSelection && removeValidCamera && removeValidCamera(camera)
        } else {
          !isDrawingSelection && removeInvalidCamera && removeInvalidCamera(camera)
        }
      }
    }
    //eslint-disable-next-line
  }, [cameraData, cameraDataLoading])
  const handleInvalidCameraClick = () => {
    setInvalidAnimation(true)
    setTimeout(() => {
      setInvalidAnimation(false)
    }, 300)
  }
  return (
    <div
      className={clsx(styles.cameraCard, invalidAnimation && styles.shakeAnimation)}
      style={{
        cursor:
          !cameraDataLoading && cameraData && !cameraData.sample_frame && isDrawingSelection
            ? "not-allowed"
            : isDrawingSelection
            ? "pointer"
            : "auto",
        minHeight: entityType === "Car Parking" ? 215 : 130,
        marginBottom: isDrawingSelection ? "" : "16px",
        margin: isDrawingSelection && margin ? "0px 4px" : "",
      }}
      onClick={() => {
        if (!cameraDataLoading && cameraData && !cameraData.sample_frame && isDrawingSelection) {
          handleInvalidCameraClick()
        }
      }}
    >
      {cameraDataLoading ? (
        <CircularProgress size={40}></CircularProgress>
      ) : cameraData?.sample_frame ? (
        <div
          className={styles.previewCard}
          style={{
            border: isDrawingSelection
              ? camera.value === selectedCamera
                ? "5px solid var(--indigo-background-1)"
                : "5px solid var(--greyScale-background-3)"
              : "",
            boxSizing: "border-box",
            borderRadius: "4px",
          }}
        >
          <Typography
            variant={isDrawingSelection ? "label" : "h3-bold"}
            className={styles.cameraName}
            title={cameraData.name}
          >
            {cameraData.name.length >= 14 ? cameraData.name.slice(0, 12) + ".." : cameraData.name}
            {/* camera up indicator */}
            {!camerasHealthLoading && camerasHealth && camerasHealth.health !== null && (
              <img
                src={
                  camerasHealth.health === 1
                    ? cameraHealthyPlaceholder
                    : camerasHealth.health < 1 && camerasHealth.health > 0
                    ? cameraAttentionPlaceholder
                    : camerasHealth.health === 0
                    ? cameraDownPlaceholder
                    : ""
                }
                alt="cameras_health_indicator"
                style={{ marginLeft: 10 }}
              />
            )}
          </Typography>
          {!isDrawingSelection && (
            <CheckBoxIcon
              className={styles.validCamera}
              onClick={() => (unSelectCamera ? unSelectCamera(camera) : null)}
            />
          )}
          <div style={{ height: "100%", width: "100%", top: 0, left: 0 }}>
            <img
              src={cameraData.sample_frame}
              alt="camera_sample_frame"
              height="100%"
              width="100%"
              style={{
                borderRadius: !isDrawingSelection ? 4 : "",
                top: 0,
                left: 0,
              }}
            />
            {isFulfilled && (
              <img src={check} alt="success" style={{ position: "absolute", top: "27%", left: "33%", height: 70 }} />
            )}
          </div>

          <Chip size="small" className={styles.activeStatus} isSelected={cameraData.active}>
            {cameraData.active ? "Active" : "Inactive"}
          </Chip>
        </div>
      ) : (
        <Fragment>
          {cameraData && (
            <Typography
              variant={isDrawingSelection ? "label" : "h3-bold"}
              className={styles.cameraName}
              title={cameraData.name}
            >
              {cameraData?.name.length >= 14 ? cameraData?.name.slice(0, 12) + ".." : cameraData?.name}
              {/* camera down indicator */}
              {!camerasHealthLoading && camerasHealth && camerasHealth.health !== null && (
                <img
                  src={
                    camerasHealth.health === 1
                      ? cameraHealthyPlaceholder
                      : camerasHealth.health < 1 && camerasHealth.health > 0
                      ? cameraAttentionPlaceholder
                      : camerasHealth.health === 0
                      ? cameraDownPlaceholder
                      : ""
                  }
                  alt="cameras_health_indicator"
                  style={{ marginLeft: 10 }}
                />
              )}
            </Typography>
          )}
          {!isDrawingSelection && (
            <CheckBoxIcon
              className={styles.validCamera}
              onClick={() => (unSelectCamera ? unSelectCamera(camera) : null)}
            />
          )}
          <Typography variant="label" variantColor={2} className={styles.noFrame}>
            No Frame Captured
          </Typography>
          {cameraData && (
            <Chip size="small" className={styles.activeStatus} isSelected={cameraData.active}>
              {cameraData.active ? "Active" : "Inactive"}
            </Chip>
          )}
        </Fragment>
      )}
    </div>
  )
}
export default CameraPreview
