import React, { FC, useState, useEffect, Fragment } from "react"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import TimerIcon from "@mui/icons-material/Timer"
import { Grid, Tooltip } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"

import { Typography } from "@synapse-analytics/synapse-ui"
import clsx from "clsx"
import { FormikProps } from "formik"

import VerticalSlidingData from "../../../components/VerticalSlidingData"
import { INewEntity } from "../../../types/Custom/Interfaces"
import { RegionsObject } from "../../../types/Custom/Interfaces"

import styles from "./BoardSidebar.module.scss"

type indexesObject = {
  areaOut: number
  areaDwelling: number
}
interface Props {
  setSelectedRegion: (regionNumber: number) => void
  selectedRegion: number
  formik: FormikProps<INewEntity>
  onDelete: (regionType: number) => void
  selectedCamera: string
  isDrawing: boolean
  carouselRef: any
  setFirstIndexes: (indexesObject: indexesObject) => void
  firstIndexes: indexesObject
}

const BoardSidebar: FC<Props> = ({
  setSelectedRegion,
  selectedRegion,
  formik,
  onDelete,
  selectedCamera,
  isDrawing,
  carouselRef,
  firstIndexes,
  setFirstIndexes,
}) => {
  const [invalidAnimation, setInvalidAnimation] = useState(false)
  const [timePickerOpen, setTimePickerOpen] = useState(false)
  const [dwellValue, setDwellValue] = useState<Date | number>()
  const [dwellIndex, setDwellIndex] = useState<number>()
  const handleDwellThresholdChange = (index: number, newDateValue: Date | number | null) => {
    const allCamerasRegions = formik.values.CamerasRegions
    const cameraRegionsArray = formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions
    let regionObj: RegionsObject = cameraRegionsArray[index]
    regionObj = { ...regionObj, dwell_thresh: newDateValue || new Date().setMinutes(0, 5) }
    cameraRegionsArray[index] = regionObj
    allCamerasRegions[`${selectedCamera}`].regions = [...cameraRegionsArray]
    formik.setFieldValue("CamerasRegions", allCamerasRegions)
  }
  const removeLayer = (index: number) => {
    const allCamerasRegions = formik.values.CamerasRegions
    const cameraRegionsArray = formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions

    cameraRegionsArray.splice(index, 1)
    allCamerasRegions[`${selectedCamera}`].regions = cameraRegionsArray
    formik.setFieldValue("CamerasRegions", allCamerasRegions)
    const outIndex = cameraRegionsArray.findIndex((element) => element.state === 1)
    const dwellIndex = cameraRegionsArray.findIndex((element) => element.state === 2)
    setFirstIndexes({
      areaOut: outIndex,
      areaDwelling: dwellIndex,
    })
    onDelete(index)
  }
  // to click one more time on next button after being enabled
  // to scroll downwards when a new dwelling region is added after the last one
  useEffect(() => {
    if (
      selectedRegion ===
      formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions.length -
        (formik.values.EntityType === "Car Parking" ? 2 : 1)
    ) {
      if (carouselRef !== null) {
        carouselRef.current.slickGoTo(
          formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions.length -
            (formik.values.EntityType === "Car Parking" ? 2 : 1)
        )
      }
    }
    // eslint-disable-next-line
  }, [selectedRegion, carouselRef])

  useEffect(() => {
    setInvalidAnimation(true)
    return () => {
      setInvalidAnimation(false)
    }

    // eslint-disable-next-line
  }, [formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions])

  // set first indexes for each region
  // this is useful in case of editing regions
  useEffect(() => {
    const cameraRegionsArray = formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions
    const outIndex = cameraRegionsArray.findIndex((element) => element.state === 1)
    const dwellIndex = cameraRegionsArray.findIndex((element) => element.state === 2)
    setFirstIndexes({
      areaOut: outIndex,
      areaDwelling: dwellIndex,
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Grid item md={2} className={styles.sideBar}>
        <VerticalSlidingData
          data={formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions}
          count={formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions.length}
          dots={false}
          infinite={false}
          slidesToScroll={3}
          slidesToShow={3}
          type="drawing board"
          carouselRef={carouselRef}
        >
          {formik?.values?.CamerasRegions[`${selectedCamera}`]?.regions.map((region, index) => (
            <div
              className={clsx(styles.sideBarItem, invalidAnimation && styles.newItem)}
              key={`${index}_${region.state}`}
              style={{
                opacity:
                  ((region.state === 0 || region.state === 1) && formik.values.EntityType === "Dwelling Area") ||
                  (region.state === 2 && formik.values.EntityType === "Car Parking")
                    ? 0.4
                    : 1,
              }}
            >
              <Typography variant="span" gutterBottom className={styles.regionTitle}>
                {region.state === 0
                  ? `Area In (${index + 1})`
                  : region.state === 1
                  ? `Area Out (${
                      index > 1 ? (index === firstIndexes.areaOut ? "1" : index - firstIndexes.areaOut + 1) : "1"
                    })`
                  : `Area Dwelling (${
                      index > 2 || formik.values.EntityType === "Dwelling Area"
                        ? index === firstIndexes.areaDwelling
                          ? "1"
                          : index - firstIndexes.areaDwelling + 1
                        : "1"
                    })`}
              </Typography>
              <div
                className={styles.thumbnailContainer}
                style={{
                  border:
                    isDrawing && selectedRegion === index
                      ? `3px solid ${
                          region.state === 0
                            ? "rgba(128, 206, 128, 0.6)"
                            : region.state === 1
                            ? "rgba(255, 0, 0, 0.6)"
                            : "rgba(74, 78, 161, 0.6)"
                        }`
                      : "",
                  width: formik.values.EntityType === "Car Parking" ? 245 : 137,
                  height: formik.values.EntityType === "Car Parking" ? 140 : 80,
                }}
              >
                <canvas
                  ref={region.ref}
                  className={styles.regionThumbnail}
                  style={{
                    outline: isDrawing && selectedRegion === index ? "0px" : "",
                    cursor:
                      ((region.state === 0 || region.state === 1) && formik.values.EntityType !== "Dwelling Area") ||
                      (region.state === 2 && formik.values.EntityType !== "Car Parking")
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={() => {
                    if (
                      ((region.state === 0 || region.state === 1) && formik.values.EntityType !== "Dwelling Area") ||
                      (region.state === 2 && formik.values.EntityType !== "Car Parking")
                    ) {
                      setSelectedRegion(index)
                    }
                  }}
                  width={formik.values.EntityType === "Car Parking" ? 1280 : 736}
                  height={formik.values.EntityType === "Car Parking" ? 720 : 416}
                />
                {region.points.length > 0 && selectedRegion === index && (
                  <Tooltip title="Delete region" placement="right-end">
                    <IconButton
                      aria-label={`delete_${index}`}
                      className={styles.overlayButton}
                      onClick={() => {
                        if (index !== 0 && index !== firstIndexes.areaOut && index !== firstIndexes.areaDwelling) {
                          removeLayer(index)
                          setSelectedRegion(index - 1)
                        } else {
                          onDelete(index)
                        }
                      }}
                      size="large"
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                {region.points.length > 0 && region.state === 2 && selectedRegion === index && (
                  <Tooltip
                    title="Select dwelling time threshold in minutes : seconds (default 5 seconds)"
                    placement="right"
                  >
                    <IconButton
                      aria-label={`extra_${index}`}
                      className={styles.overlayButton}
                      style={{
                        bottom: 5,
                      }}
                      onClick={() => {
                        //  specify dwelling time
                        setTimePickerOpen(true)
                        setDwellValue(region.dwell_thresh)
                        setDwellIndex(index)
                      }}
                      size="large"
                    >
                      <TimerIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </VerticalSlidingData>
        {dwellIndex && (
          <MobileTimePicker
            ampm={false}
            sx={{ display: "none" }}
            open={timePickerOpen}
            onClose={() => {
              setTimePickerOpen(false)
              setDwellValue(undefined)
              setDwellIndex(undefined)
            }}
            openTo="minutes"
            key={dwellIndex}
            views={["minutes", "seconds"]}
            format="mm:ss"
            value={dwellValue || new Date().setMinutes(0, 5)}
            onAccept={(date) => handleDwellThresholdChange(dwellIndex, date)}
          />
        )}
      </Grid>
    </Fragment>
  )
}
export default BoardSidebar
