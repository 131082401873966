import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import moment from "moment"
import queryString from "query-string"

type DateType = moment.Moment | Date

interface UseDateQueryParams {
  defaultStartDate?: DateType
  defaultEndDate?: DateType
  isDayPicker?: boolean
}

/**
 * Calculates start and end dates based on query params
 * & updates the params according to changes in the page
 * NOTE: all expected date formats are ISO date formats
 * @returns [startDate, setStartDate, endDate, setEndDate]
 */
export function useDateQuery({ defaultStartDate, defaultEndDate, isDayPicker = false }: UseDateQueryParams = {}): [
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>,
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>
] {
  const navigate = useNavigate()
  const location = useLocation()

  // fetches query search params from URL
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search])

  // Helper to convert DateType to moment.Moment
  const convertToMoment = (date: DateType | undefined, defaultDate: moment.Moment): moment.Moment => {
    if (date) {
      return moment(date)
    }
    return defaultDate
  }

  const paramName = isDayPicker ? "day" : "startDate"

  // Use query params or defaults for start date or day
  const [startDate, setStartDate] = useState<moment.Moment | null>(
    queryParams[paramName] && typeof queryParams[paramName] === "string"
      ? moment(queryParams[paramName])
      : convertToMoment(defaultStartDate, moment().subtract(7, "days"))
  )

  // Use query params or defaults for end date if not using day picker
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    !isDayPicker && queryParams.endDate && typeof queryParams.endDate === "string"
      ? moment(queryParams.endDate)
      : convertToMoment(defaultEndDate, moment())
  )

  // Merge existing query parameters with the new ones
  const mergedQueryParams = useMemo(() => {
    const params = { ...queryParams, [paramName]: startDate?.toISOString() }
    if (!isDayPicker) {
      params.endDate = endDate?.toISOString()
    } else {
      delete params.endDate
    }
    return params
  }, [endDate, queryParams, startDate, isDayPicker, paramName])

  // Modify the URL when the dates or page value are changed
  useEffect(() => {
    const dateQuery = queryString.stringify(mergedQueryParams)
    navigate(
      {
        pathname: location.pathname,
        search: dateQuery,
      },
      {
        replace: true,
      }
    )
  }, [location.pathname, mergedQueryParams, navigate])

  return [
    startDate,
    setStartDate,
    isDayPicker ? null : endDate,
    isDayPicker ? ((() => {}) as Dispatch<SetStateAction<moment.Moment | null>>) : setEndDate,
  ]
}
