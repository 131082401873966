import React, { useState, useEffect, Fragment, useRef } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"

import { Button, DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"

import { useDateQuery } from "../../hooks/useDateQuery"
import { exportGraphs } from "../../utils/genericHelpers"
import CorridorStatistics from "./components/CorridorStatistics"

import styles from "./Corridors.module.scss"

const Corridors = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>("day")
  const [interval, setInterval] = useState<Duration>()
  const cardsRefs = {
    corridorsPerformanceRef: useRef(),
    corridorOverTimeRef: useRef(),
    corridorBreakdown: useRef(),
  }

  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <Typography
            variant="h2-regular"
            tooltip="All you need to know about corridors analytics ."
            tooltipPlacement="right"
            tooltipIconSize={22}
            gutterBottom
            variantColor={2}
          >
            Corridors
          </Typography>
          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              disableFuture
            />
            <Button
              startIcon={<GetAppIcon fontSize="medium" />}
              variant="secondary"
              size="large"
              onClick={() => exportGraphs(cardsRefs, startDate, endDate, "Corridor")}
              disabled={!startDate || !endDate}
            >
              Export
            </Button>
          </div>
        </div>
        <CorridorStatistics
          startDate={startDate}
          endDate={endDate}
          interval={interval!}
          timeGrain={timeGrain!}
          refs={cardsRefs}
        />
      </div>
    </Fragment>
  )
}
export default Corridors
