import React, { FC, useState, useEffect } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"
import { Card, IconButton } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"

import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateSumPerTime, convertToHeatmapChartLabels } from "../../../utils/counterUtils"
import HeatmapGraph from "../components/AdvancedAnalytics/components/heatmapGraph"

import styles from "./TotalCountsHeatmap.module.scss"

type CounterLogs = definitions["CounterLogs"]

interface Props {
  logsData: CounterLogs[]
  loading: boolean
  interval: Duration
  reference: any
}
type HeatmapPoint = {
  x: string
  y: number
  pointDate: string
}
type HeatmapData = {
  id: string
  data: HeatmapPoint[]
}
const TotalCountsHeatmap: FC<Props> = ({ loading, logsData, interval, reference }) => {
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([
    {
      id: "",
      data: [{ x: "", y: 0, pointDate: "" }],
    },
  ])
  const [showExport, setShowExport] = useState(false)

  useEffect(() => {
    if (loading === false && logsData) {
      setHeatmapData(convertToHeatmapChartLabels(calculateSumPerTime(logsData)))
      if (logsData.length > 0 && (interval!.days! >= 7 || interval!.months! >= 1)) {
        setShowExport(true)
      } else {
        setShowExport(false)
      }
    } else {
      setShowExport(false)
    }
  }, [logsData, loading, interval])
  const handleExportCSV = () => {
    const builder = new CsvBuilder(`Total Counts Heatmap Data.csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    if (!loading && heatmapData)
      heatmapData.map((row) => {
        const arrayOfPoints: string[] = []
        row.data.map((element) => arrayOfPoints.push(element.y.toString()))
        return csvFormattedData.push([row.id, ...arrayOfPoints])
      })
    builder
      .setColumns(["Week \\ Day", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"])
      .addRows(csvFormattedData)
      .exportFile()
  }
  return (
    <Card className={styles.wrapper} ref={reference}>
      <div className={styles.header}>
        <Typography variant="h2-bold" variantColor={2}>
          Total counts per day in calendar graph
        </Typography>
        {showExport && (
          <IconButton onClick={() => handleExportCSV()} className={styles.exportBtn} size="large">
            <GetAppIcon />
          </IconButton>
        )}
      </div>
      {interval && interval!.days! < 7 && interval!.months! < 1 ? (
        <GraphEmptyState isRangeWarning={true} />
      ) : (
        <HeatmapGraph data={heatmapData} graphHeight={350} isLoading={loading} />
      )}
    </Card>
  )
}
export default TotalCountsHeatmap
