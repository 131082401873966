import React, { FC, useState, useEffect, Fragment } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import HighlightOffIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, MobileStepper, useMediaQuery, Tooltip } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Slide from "@mui/material/Slide"
import { SlideProps } from "@mui/material/Slide/Slide"
import { useTheme } from "@mui/material/styles"

import { Typography, Button, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { useFormik, FormikProps } from "formik"
import * as Yup from "yup"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import { useBranchesStore } from "../../../store"
import { INewEntity, EntityRowData } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import CamerasSelection from "./CamerasSelection"
import EntityForm from "./EntityForm"
import RegionCreator from "./RegionCreator"

import styles from "./NewEntity.module.scss"

type Category = definitions["Category"]
type Region = definitions["counterentitiy_region_serializer"]

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
interface Props {
  open: boolean
  handleClose: () => void
  edit?: boolean
  entityData?: EntityRowData
  activeTab?: string
}

const NewEntity: FC<Props> = ({ open, handleClose, edit, entityData, activeTab }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [isCancelMessageOpen, setIsCancelMessageOpen] = useState(false)
  const theme = useTheme()

  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

  const validationFormik = () => {
    return Yup.object({
      EntityType: Yup.string().required("Entity Type is required"),
      EntityName: Yup.string().required("Entity name is required"),
      EntityCategory: Yup.number().when("EntityType", {
        is: "Tenant",
        then: Yup.number().required("Entity Category is required"),
      }),
      EntitySubCategory: Yup.number().when("EntityType", {
        is: "Tenant",
        then: Yup.number().required("Entity Sub-Category is required"),
      }),
      EntityArea: Yup.number().when("EntityType", {
        is: (EntityType: string) => EntityType === "Tenant" || EntityType === "Corridor",
        then: Yup.number().required("Entity Area is required"),
      }),
    })
  }

  const queryClient = useQueryClient()

  const { data: categories, isLoading: isLoadingCategories } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories
  )

  // a method to extract camera names and ids into one object from row data
  const getSelectedCameras = () => {
    const selectedCameras = []
    const camerasIds = entityData?.cameras
    const cameraNames = entityData?.camera_name
    if (cameraNames && camerasIds) {
      for (let i = 0; i < cameraNames.length; i++) {
        selectedCameras.push({
          label: cameraNames[i].replace(" / ", ""),
          value: camerasIds[i],
        })
      }
    }
    return selectedCameras
  }

  const formik: FormikProps<INewEntity> = useFormik<INewEntity>({
    initialValues: {
      EntityType: edit ? entityData?.type! : activeTab ? activeTab : "Entrance Gate",
      EntityName: entityData?.name,
      EntityCategory: entityData?.category,
      EntitySubCategory: entityData?.subcategory,
      EntityArea: entityData?.area,
      SelectedCameras: edit ? getSelectedCameras() : [],
      ValidCameras: [],
      invalidCameras: [],
      CamerasRegions: {},
      EntityId: entityData?.id,
      branch: selectedBranch,
    },
    enableReinitialize: true,
    validationSchema: validationFormik,
    onSubmit: (values) => addEntity(values),
  })
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // closing confirmation message pop up
  const handleTriggerCloseConfirmation = () => {
    setIsCancelMessageOpen(true)
  }

  const handleCancelCloseConfirmation = () => {
    setIsCancelMessageOpen(false)
  }

  //after confirm to close , close dialog and reset states
  const handleCloseDialog = () => {
    // Empty All States of all the chosen inputs
    handleClose()
    setIsCancelMessageOpen(false)
    setActiveStep(0)
    formik.resetForm()
    handleCancelCloseConfirmation()
  }

  const hasMinimumFulfillment = () => {
    const camerasRegionsObj = formik.values.CamerasRegions

    return Object.values(camerasRegionsObj).some((cameraRegion) => cameraRegion.isFulfilled)
  }

  const handleAddNewEntity = async (values: INewEntity) => {
    let regionsArray: Region[] = []
    const camerasRegionsObj = values.CamerasRegions
    for (let camera in camerasRegionsObj) {
      // deconstruction of all regions to be in one array
      const regions = camerasRegionsObj[camera].regions
      for (const region of regions) {
        if (region.points.length > 2) {
          regionsArray.push({
            camera: region.camera,
            state: region.state,
            points: region.points,
            dwell_thresh:
              region.state === 2 && region.dwell_thresh && typeof region.dwell_thresh !== "number"
                ? region.dwell_thresh.getMinutes() * 60 + region.dwell_thresh.getSeconds()
                : undefined,
          })
        }
      }
    }
    switch (values.EntityType) {
      case "Entrance Gate": {
        if (edit) {
          return VisionAPI.updateGate(values.EntityId as number, {
            name: values.EntityName as string,
            branch: values.branch,
            regions: !values.onlyFormFields ? regionsArray : undefined,
          })
        } else {
          return VisionAPI.addGate({
            name: values.EntityName as string,
            regions: regionsArray,
            branch: values.branch,
          })
        }
      }
      case "Tenant": {
        if (edit) {
          return VisionAPI.updateShop(values.EntityId as number, {
            name: values.EntityName as string,
            area: values.EntityArea,
            regions: !values?.onlyFormFields ? regionsArray : undefined,
            category: parseInt(values.EntityCategory || "") as number,
            subcategory: parseInt(values.EntitySubCategory || "") as number,
            branch: values.branch,
          })
        } else {
          return VisionAPI.addShop({
            name: values.EntityName as string,
            area: values.EntityArea,
            category: parseInt(values.EntityCategory || "") as number,
            subcategory: parseInt(values.EntitySubCategory || "") as number,
            regions: regionsArray,
            branch: values.branch,
          })
        }
      }
      case "Corridor": {
        if (edit) {
          return VisionAPI.updateCorridor(values.EntityId as number, {
            name: values.EntityName as string,
            branch: values.branch,
            regions: !values.onlyFormFields ? regionsArray : undefined,
          })
        } else {
          return VisionAPI.createCorridor({
            name: values.EntityName as string,
            branch: values.branch,
            regions: regionsArray,
          })
        }
      }
      case "Car Parking": {
        if (edit) {
          return VisionAPI.updateCarParking(values.EntityId as number, {
            name: values.EntityName as string,
            branch: values.branch,
            regions: !values?.onlyFormFields ? regionsArray : undefined,
          })
        } else {
          return VisionAPI.addCarParking({
            name: values.EntityName as string,
            branch: values.branch,
            regions: regionsArray,
          })
        }
      }
      case "Dwelling Area": {
        if (edit) {
          return VisionAPI.updateDwellingArea(values.EntityId as number, {
            name: values.EntityName as string,
            regions: !values?.onlyFormFields ? regionsArray : undefined,
            category: parseInt(values.EntityCategory || "") as number,
            subcategory: parseInt(values.EntitySubCategory || "") as number,
            branch: values.branch,
          })
        } else {
          return VisionAPI.addDwellingArea({
            name: values.EntityName as string,
            regions: regionsArray,
            category: parseInt(values.EntityCategory || "") as number,
            subcategory: parseInt(values.EntitySubCategory || "") as number,
            branch: values.branch,
          })
        }
      }
    }
  }
  const { mutate: addEntity, isLoading: addEntityLoading } = useMutation(handleAddNewEntity, {
    onSuccess: () => {
      NotificationUtils.toast(`${formik.values.EntityType} entity ${edit ? "updated" : "created"} successfully`, {
        snackBarVariant: "positive",
      })
      // refetch entities & regions
      switch (formik.values.EntityType) {
        case "Entrance Gate": {
          queryClient.invalidateQueries("fetchEntranceEntities")
          break
        }
        case "Tenant": {
          queryClient.invalidateQueries("fetchShopsEntities")
          break
        }
        case "Corridor": {
          queryClient.invalidateQueries("fetchCorridorEntities")
          break
        }
        case "Car Parking": {
          queryClient.invalidateQueries("fetchCarParkingEntities")
          break
        }
        case "Dwelling Area": {
          queryClient.invalidateQueries("fetchDwellingAreasEntities")
          break
        }
      }
      queryClient.invalidateQueries("fetchRegions")
      // close dialog
      handleCloseDialog()
    },
  })
  const handleEditForm = () => {
    formik.setFieldValue("onlyFormFields", true)
    formik.handleSubmit()
  }

  // set default entity type in entity creation form to be the active tab
  useEffect(() => {
    formik.setFieldValue("EntityType", activeTab)
    // eslint-disable-next-line
  }, [activeTab])

  return (
    <Fragment>
      <WarningDialog
        confirmationText={`cancel entity ${edit ? "editing" : "creation"} process?`}
        isOpen={isCancelMessageOpen}
        onCancel={handleCancelCloseConfirmation}
        onConfirm={handleCloseDialog}
      />

      <Dialog
        onClick={(event) => event.stopPropagation()}
        open={open}
        scroll={activeStep === 2 && !smallScreen ? undefined : "paper"}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={styles.dialog}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            minWidth:
              activeStep === 0
                ? "780px"
                : activeStep === 1
                ? "940px"
                : activeStep === 2
                ? formik.values.EntityType === "Car Parking"
                  ? "1724px"
                  : "1054px"
                : "auto",
            minHeight: { xl: activeStep === 2 ? "927px" : "", xs: "0px !important" },
          },
        }}
      >
        <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2-bold" className={styles.title}>
              {activeStep === 0
                ? edit
                  ? "Edit Entity"
                  : "Create Entity"
                : activeStep === 1
                ? "Select Cameras"
                : `Draw Regions For ${formik.values.EntityType} Entity : ${formik.values.EntityName}`}
            </Typography>
            <HighlightOffIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
          </div>

          {/* Start of First Screen*/}
          {/* Add Entity Form*/}
          {activeStep === 0 ? (
            <EntityForm formik={formik} edit={edit} categories={categories} isLoadingCategories={isLoadingCategories} />
          ) : activeStep === 1 ? (
            // start of second screen
            // Camera Selection
            <CamerasSelection formik={formik} edit={edit} entityData={entityData} />
          ) : (
            //start of 3rd and final screen
            //Region Setup
            <RegionCreator formik={formik} edit={edit} />
          )}

          {/*Start of Second Screen*/}
          {/* Mobile Stepper pagination*/}
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            className={styles.stepper}
            nextButton={
              <Tooltip
                placement="top"
                key={activeStep}
                title={
                  activeStep === 0
                    ? edit
                      ? "Save form fields' changes"
                      : "Proceed to select cameras after filling required fields"
                    : activeStep === 1
                    ? "Proceed to draw regions ,at least one valid camera with a sample frame is required to continue"
                    : !hasMinimumFulfillment()
                    ? "Draw at least one region for a selected camera in order to create entity"
                    : "Create New Entity"
                }
              >
                <span>
                  <Button
                    size="regular"
                    variant="primary"
                    onClick={
                      activeStep === 2
                        ? () => {
                            formik.handleSubmit()
                          }
                        : activeStep === 0 && edit
                        ? handleEditForm
                        : handleNext
                    }
                    disabled={
                      activeStep === 0 &&
                      [
                        !formik.values.EntityName,
                        !!formik.errors.EntityCategory,
                        !!formik.errors.EntitySubCategory,
                        !!formik.errors.EntityArea,
                      ].some((element) => element === true)
                        ? true
                        : activeStep === 1 && formik.values.ValidCameras.length < 1
                        ? true
                        : activeStep === 2 && !hasMinimumFulfillment()
                        ? true
                        : addEntityLoading
                        ? true
                        : false
                    }
                  >
                    {
                      <Fragment>
                        <span style={{ marginRight: addEntityLoading ? 10 : 0 }}>
                          {activeStep === 2
                            ? edit
                              ? "Save"
                              : "Create Entity"
                            : activeStep === 0 && edit
                            ? "Save Changes"
                            : "Next"}
                        </span>
                        {addEntityLoading && <CircularProgress size={20} />}
                      </Fragment>
                    }
                  </Button>
                </span>
              </Tooltip>
            }
            backButton={
              <Button
                size="regular"
                variant={activeStep === 0 && edit ? "secondary" : "primary"}
                onClick={activeStep === 0 && edit ? handleNext : handleBack}
                style={{ visibility: !edit && activeStep === 0 ? "hidden" : "visible" }}
                disabled={[
                  !formik.values.EntityName,
                  formik.errors.EntityCategory,
                  formik.errors.EntitySubCategory,
                  formik.errors.EntityArea,
                ].some((element) => element === true)}
              >
                {activeStep === 0 && edit ? "Edit Regions" : "Back"}
              </Button>
            }
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
export default NewEntity
