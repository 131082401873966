import React, { Fragment, ChangeEvent, useCallback, useMemo } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { Grid, CircularProgress, Paper } from "@mui/material"

import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { useFormik } from "formik"
import * as Yup from "yup"

import { VisionAPI } from "../../API/VisionAPI"
import { routes } from "../../routes/routes"
import { IUserAddEdit } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import UserInformation from "./UserInformation"
import ActionButton from "./components/ActionButton"
import NotificationsChannels from "./components/NotificationsChannels"
import TransferList from "./components/TransferList"

import styles from "./UserAddEdit.module.scss"

type Permission = definitions["Permission"]
type UserRetrieve = definitions["UserRetrieve"]
type Branch = definitions["Branch"]
type UserNotificationSetting = definitions["UserNotificationSetting"]
type NotificationsType = definitions["UserNotificationSetting"]["notification_type"]

type Location = {
  state: { edit: boolean }
}

type Params = {
  id: string
}

const UserAddEdit = () => {
  const location: Location = useLocation()
  const navigate = useNavigate()
  const params = useParams<Params>() //to extract camera id from pathname
  const edit = location.state?.edit
  const queryClient = useQueryClient()

  // get user information
  const { data: userData, isFetching: userLoading } = useQuery<UserRetrieve>(
    ["fetchUserData", parseInt(params!.id!)],
    async ({ queryKey }) => VisionAPI.fetchUser(queryKey[1] as number),
    {
      enabled: edit && !!parseInt(params!.id!),
    }
  )

  const validationFormik = () => {
    return Yup.object({
      username: Yup.string().min(3, "Username can't be less than 3 characters").required("Username is required"),
      password: Yup.string()
        .min(6, "Password can't be less than 6 characters")
        .when("edit", {
          is: false,
          then: Yup.string().required("Password is required"),
        }),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .when("edit", {
          is: false,
          then: Yup.string().required("Confirm Password is required"),
        }),
      email: Yup.string()
        .email("Invalid email address")
        .when("notification_setting", {
          is: (notification_setting: UserNotificationSetting[]) =>
            notification_setting?.some((ns) => ns.notification_channels.includes("MAIL")),
          then: Yup.string().required("Email is required because you chose email as a notification channel"),
          otherwise: Yup.string().notRequired(),
        }),
      telegram_id: Yup.string()
        .matches(/^-?\d+$/, "Telegram ID must be a number")
        .test("len", "Telegram ID can't be more than 20 digits", (val) => !val || val.replace(/^-/, "").length <= 20)
        .when("notification_setting", {
          is: (notification_setting: UserNotificationSetting[]) =>
            notification_setting?.some((ns) => ns.notification_channels.includes("TEL")),
          then: Yup.string().required("Telegram ID is required because you chose telegram as a notification channel"),
          otherwise: Yup.string().notRequired(),
        }),
    })
  }

  const initialValues = useMemo(
    () => ({
      username: userData?.username ?? "",
      password: undefined,
      confirm_password: "",
      email: userData?.email ?? "",
      first_name: userData?.first_name ?? "",
      last_name: userData?.last_name ?? "",
      telegram_id: userData?.telegram_id ?? undefined,
      is_active: userData?.is_active ?? false,
      user_permissions: userData?.user_permissions ?? [],
      notification_setting: userData?.notification_setting ?? undefined,
      branches: userData?.branches ?? [],
      edit: edit,
    }),
    [userData, edit]
  )

  const formik = useFormik<IUserAddEdit>({
    initialValues: initialValues,
    validationSchema: validationFormik(),
    enableReinitialize: true,
    onSubmit: (values) => (edit ? editUser(values) : addUser(values)),
  })

  const resetNotificationChannels = () => {
    formik.setFieldValue("notification_setting", initialValues.notification_setting)
  }

  const { data: branches } = useQuery<Branch[], AxiosError>("fetchBranches", VisionAPI.fetchBranches)

  const { mutate: addUser, isLoading: addUserLoading } = useMutation(
    async (values: IUserAddEdit) => VisionAPI.addUser(values),
    {
      onSuccess: (data) => {
        NotificationUtils.toast(`${data?.username} has been successfully added.`, {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchUserBranches")
        setTimeout(() => {
          navigate(`/${routes.users}`)
        }, 1000)
      },
    }
  )

  const { mutate: editUser, isLoading: editUserLoading } = useMutation(
    async (values: IUserAddEdit) => VisionAPI.updateUser(parseInt(params!.id!), values),
    {
      onSuccess: (result) => {
        NotificationUtils.toast(`${result?.username || "user"} has been successfully updated.`, {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchUserBranches")
        setTimeout(() => {
          navigate(`/${routes.users}`)
        }, 1000)
      },
    }
  )

  const { data: permissions } = useQuery<Permission[]>("fetchPermissions", VisionAPI.fetchPermissions)

  const handleChangeChosenPermissions = useCallback(
    (permissions: Permission[]) => {
      formik.setFieldValue(
        "user_permissions",
        permissions?.map((permission) => permission.id)
      )
    },
    [formik]
  )

  const handleChangeChosenBranches = useCallback(
    (branches: Branch[]) => {
      formik.setFieldValue(
        "branches",
        branches?.map((branch) => branch.id)
      )
    },
    [formik]
  )

  /**
   * Updates the `notification_setting` field in Formik based on the given type and event.
   *
   * @param {(NotificationsType)} type - The type of the channel data entry to update.
   * @param {ChangeEvent<HTMLInputElement>} event - The event containing the target id and checked status.
   */
  const updateChannels = (type: NotificationsType, event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    // Create a deep copy of the notification_setting array from Formik's values to ensure we do not mutate the original array
    const notification_setting: UserNotificationSetting[] = formik.values.notification_setting
      ? JSON.parse(JSON.stringify(formik.values.notification_setting))
      : []

    // Find the entry with the matching type
    const entryIndex = notification_setting.findIndex((item) => item.notification_type === type)

    if (entryIndex !== -1) {
      const entry = notification_setting[entryIndex]
      const channelIndex = entry.notification_channels.indexOf(value as "AV" | "MAIL" | "TEL")

      if (checked && channelIndex === -1) {
        // Add the channel if it's checked and not already present
        entry.notification_channels.push(value as "AV" | "MAIL" | "TEL")
      } else if (!checked && channelIndex !== -1) {
        // Remove the channel if it's unchecked and present
        entry.notification_channels.splice(channelIndex, 1)

        // Remove the entry if no channels are left
        if (entry.notification_channels.length === 0) {
          notification_setting.splice(entryIndex, 1)
        }
      }
    } else if (checked) {
      // If no entry with the given type, create a new one only if checked is true
      notification_setting.push({
        notification_type: type,
        notification_channels: [value as "AV" | "MAIL" | "TEL"],
      })
    }

    // Update Formik's notification_setting field
    formik.setFieldValue("notification_setting", notification_setting)
  }

  return (
    <div>
      <Typography
        variant="h2-regular"
        tooltip={edit ? "Edit user information and permissions" : "Create new user to get access to the system"}
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        {edit ? "Edit User" : "Create User"}
      </Typography>

      {/* user info */}
      <Grid container spacing={2}>
        {edit && userLoading ? (
          <div className={styles.loading}>
            <CircularProgress size={40} />
          </div>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <UserInformation edit={edit} formik={formik} />
            </Grid>
            <Grid item xs={12} md={12}>
              <TransferList
                edit={edit}
                handleChangeChosenData={handleChangeChosenPermissions}
                availableData={permissions!}
                chosenData={userData?.user_permissions!}
                type="roles"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TransferList
                edit={edit}
                handleChangeChosenData={handleChangeChosenBranches}
                availableData={branches}
                chosenData={userData?.branches}
                type="branches"
              />
            </Grid>
            {/* Subscribed section */}
            <Grid item xs={12}>
              <Paper className={styles.paper} style={{ padding: 20 }} elevation={0}>
                <div className={styles.header}>
                  <Typography variant="h2-bold" variantColor={2}>
                    Notification permissions & Channels
                  </Typography>
                  <Button onClick={resetNotificationChannels} variant="secondary">
                    Reset Changes
                  </Button>
                </div>
                <NotificationsChannels
                  handleUpdateChannel={updateChannels}
                  subscriptions={formik.values.notification_setting}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} className={styles.ctaContainer}>
              <ActionButton
                title={edit ? "Apply Changes" : "Create User"}
                edit={!!edit}
                onClick={formik?.submitForm}
                isLoading={addUserLoading || editUserLoading}
                disabled={!formik.isValid}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </div>
  )
}

export default UserAddEdit
