import React, { FC, useState, ChangeEvent, useEffect, Fragment } from "react"
import { useMutation, useQueryClient, useQuery } from "react-query"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, IconButton, CircularProgress, Card, Box } from "@mui/material"

import {
  Typography,
  Button,
  Checkbox,
  InputText,
  Select,
  Switch,
  NotificationUtils,
} from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps, useFormik } from "formik"
import * as Yup from "yup"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import TagsList from "../../../components/TagsList"
import WarningDialog from "../../../components/WarningDialog"
import { useBranchesStore } from "../../../store"
import { definitions } from "../../../types/Generated/apiTypes"
import ImagePreview from "./ImagePreview"

import styles from "./CameraAddEdit.module.scss"

type CameraCreate = definitions["CameraCreate"]

type Camera = definitions["CameraRetrieveUpdate"]
type PaginatedCamera = definitions["PaginatedCamerasList"]
type Tag = definitions["Tag"]
type Node = definitions["NodeUpdateRetrieve"]

interface Services {
  mcp: boolean
  heatmap: boolean
  car: boolean
  counter: boolean
  face: boolean
  violence: boolean
  motion: boolean
  fire: boolean
}

interface Props {
  isOpen: boolean
  handleClose: () => void
  camera?: Camera | PaginatedCamera
  isEdit?: boolean
}
type ServiceKey = keyof Services

const availableServices: ServiceKey[] = ["mcp", "heatmap", "car", "counter", "face", "violence", "motion", "fire"]

const CameraAddEdit: FC<Props> = ({ isOpen, handleClose, isEdit, camera }) => {
  const queryClient = useQueryClient()
  const [cameraActive, setCameraActive] = useState(isEdit ? (camera?.active! === true ? true : false) : true)
  const [chosenServices, setChosenServices] = useState<string[]>([])
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [services, setServices] = useState<Services>(
    isEdit
      ? {
          mcp: camera?.services!.includes("mcp") ? true : false,
          heatmap: camera?.services!.includes("heatmap") ? true : false,
          car: camera?.services!.includes("car") ? true : false,
          counter: camera?.services!.includes("counter") ? true : false,
          face: camera?.services!.includes("face") ? true : false,
          violence: camera?.services!.includes("violence") ? true : false,
          motion: camera?.services!.includes("motion") ? true : false,
          fire: camera?.services!.includes("fire") ? true : false,
        }
      : {
          mcp: false,
          heatmap: false,
          car: false,
          counter: false,
          face: false,
          violence: false,
          motion: false,
          fire: false,
        }
  )

  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const { data: fetchedTags, isLoading: isTagsLoading } = useQuery<Tag[], AxiosError>("fetchCameraTags", () =>
    VisionAPI.fetchCameraTags()
  )

  const { data: nodesList, isLoading: nodesListLoading } = useQuery<Node[], AxiosError>(
    ["fetchNodesList", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchNodesList({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  useEffect(() => {
    let chosenServices = Object.keys(services).filter((key) => services[key as keyof typeof services])
    setChosenServices(chosenServices)
    formik?.setFieldValue("services", chosenServices)
    // eslint-disable-next-line
  }, [services])

  const handleChangeServices = (event: ChangeEvent<HTMLInputElement>) => {
    setServices({ ...services, [event.target.id]: event.target.checked })
  }

  const handleChangeStatus = () => {
    setCameraActive(!cameraActive)
    formik?.setFieldValue("active", !cameraActive)
  }

  const validationFormik = () => {
    let validationSchema = Yup.object({
      name: Yup.string().required("Camera Name can't be empty"),
    })

    return validationSchema
  }

  // add new camera
  const { mutate: createCamera, isLoading: loadingNewCamera } = useMutation(
    (values: CameraCreate) => VisionAPI.createCamera(values),
    {
      onSuccess: (data) => {
        NotificationUtils.toast(`Camera ${data.name} has been created successfully`, {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchCamerasPaginated")
        handleClose()
        handleResetDialog()
      },
    }
  )

  // edit camera
  const { mutate: updateCamera, isLoading: loadingEditCamera } = useMutation(
    (values: CameraCreate) => VisionAPI.editCamera(values, camera?.id),
    {
      onSuccess: (data) => {
        NotificationUtils.toast(`Camera ${data.name} has been updated successfully`, {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchCamerasPaginated")
        queryClient?.invalidateQueries("fetchSingleCamera")
        queryClient?.invalidateQueries("fetchCameraLogs")
        queryClient?.invalidateQueries("fetchCamerasPaginated")
        queryClient?.invalidateQueries("fetchTags")
        handleClose()
      },
    }
  )

  const formik: FormikProps<CameraCreate> = useFormik<CameraCreate>({
    initialValues: {
      name: camera?.name || "",
      url: camera?.url || "",
      tags: camera?.tags || [],
      services:
        camera?.services ||
        (chosenServices as (
          | "counter"
          | "heatmap"
          | "car"
          | "mcp"
          | "face"
          | "violence"
          | "motion"
          | "fire"
          | "intrusion"
        )[]),
      active: camera?.active || cameraActive,
      node: camera?.node_info?.id || undefined,
      branch: selectedBranch,
    },
    enableReinitialize: isEdit ? true : false,
    validationSchema: validationFormik,
    onSubmit: (values, { resetForm }) => {
      if (isEdit) {
        updateCamera(values)
        resetForm()
      } else {
        createCamera(values)
      }
    },
  })

  const handleResetDialog = () => {
    formik.resetForm()
    setCameraActive(isEdit ? (camera?.active! === true ? true : false) : true)
    setChosenServices([])
    setServices({
      mcp: false,
      heatmap: false,
      car: false,
      counter: false,
      face: false,
      violence: false,
      motion: false,
      fire: false,
    })
    formik?.setFieldValue("tags", [])
  }

  const handleTriggerCloseConfirmation = () => {
    setIsConfirmationOpen(true)
  }

  const handleCancelCloseConfirmation = () => {
    setIsConfirmationOpen(false)
  }

  const handleConfirmClose = () => {
    setIsConfirmationOpen(false)
    handleClose()
    handleResetDialog()
  }

  const handleCloseClick = () => {
    // close dialog without warning and confirmation if fields hadn't been touched yet.
    if (formik.dirty) handleTriggerCloseConfirmation()
    else handleConfirmClose()
  }

  return (
    <Fragment>
      <WarningDialog
        content={`Are you sure you want to cancel camera ${isEdit ? "editing" : "creation"} process?`}
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelCloseConfirmation}
        dialogTitle={`Cancel ${isEdit ? "editing" : "creation"}`}
        actionTitle={`Cancel ${isEdit ? "editing" : "creation"}`}
        cancelTitle="Continue"
      />

      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={styles.wrapper} style={{ paddingTop: 32, flexShrink: 0 }}>
          <div className={styles.header}>
            <Typography variant="h2-bold">{!isEdit ? "Create Camera" : "Edit Camera"}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseClick}
              aria-label="close"
              className={styles.iconContainer}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Card className={styles.createCamera}>
            {/* Camera name and status */}
            <Box className={styles.nameAndStatus}>
              {/* Camera name */}
              <InputText
                id="name"
                label="Camera name"
                value={formik?.values.name}
                handleChange={formik?.handleChange}
                error={formik?.touched.name && Boolean(formik?.errors.name) && `${formik?.errors.name}`}
                placeholder="E.g. CAMERA 1"
                size={320}
                handleBlur={formik?.handleBlur}
                fullWidth
                required
              />

              {/* Camera status [ON/OFF] */}
              <div className={styles.cameraStatus}>
                <Switch checked={cameraActive} onChange={handleChangeStatus} />
              </div>
            </Box>
            <Box mt={2}>
              <InputText
                id="url"
                label="Camera URL"
                placeholder="E.g. rtmp://19.223.453.923/"
                handleChange={formik?.handleChange}
                value={formik?.values.url}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <ImagePreview url={formik?.values.url} />
            </Box>

            <Box mt={2}>
              <Select
                id="node"
                label="Camera Node"
                placeholder="Camera Node"
                required
                loading={nodesListLoading}
                fullWidth
                optionsWithValues={
                  nodesList && Array.isArray(nodesList)
                    ? nodesList?.map((node) => {
                        return {
                          label: node.name,
                          value: node.id!,
                        }
                      })
                    : []
                }
                value={formik?.values.node}
                handleChange={(event) => {
                  formik.setFieldValue("node", event.target.value)
                }}
                disabled={nodesListLoading}
              />
            </Box>

            {/* services */}
            <Box mt={2}>
              <Typography gutterBottom variant="span">
                Service Selection *
              </Typography>
              <div className={styles.servicesWrapper}>
                {availableServices.map((service, index) => (
                  <Checkbox
                    key={index}
                    checked={services[service]}
                    onChange={handleChangeServices}
                    name={service}
                    label={service.charAt(0).toUpperCase() + service.slice(1)}
                  />
                ))}
              </div>
            </Box>

            {/* tags */}
            <Box mt={2}>
              <TagsList
                formik={formik}
                isLoading={isTagsLoading}
                placeholder="E.g. People"
                label="Camera tags"
                options={fetchedTags!}
                selectedTags={formik.values.tags}
                size={649}
              />
            </Box>
          </Card>
          <div style={{ display: "flex", justifyContent: !isEdit ? "space-between" : "flex-end" }}>
            {!isEdit && (
              <Button
                disabled={
                  formik?.values?.name !== "" ||
                  formik?.values?.services?.length !== 0 ||
                  formik?.values?.tags?.length !== 0 ||
                  formik?.values?.url !== "" ||
                  !!formik?.values?.node
                    ? false
                    : true
                }
                onClick={handleResetDialog}
              >
                Clear
              </Button>
            )}

            <Button
              disabled={
                !formik?.values?.name || !formik?.values?.node || loadingEditCamera || loadingNewCamera ? true : false
              }
              onClick={formik?.submitForm}
              variant="primary"
            >
              <span style={{ marginRight: loadingEditCamera || loadingNewCamera ? 10 : 0 }}>
                {isEdit ? "Save" : "Create"}
              </span>
              {(loadingEditCamera || loadingNewCamera) && <CircularProgress size={20} />}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default CameraAddEdit
