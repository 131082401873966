import { format } from "date-fns"

import { getApiBaseUrl } from "../lib/axios"
import Auth from "./auth"

export function getYesterday() {
  const now = new Date()
  const dayStart = new Date()
  const dayEnd = new Date()

  dayStart.setDate(now.getDate() - 1)
  dayStart.setHours(4, 0, 0, 0)
  dayEnd.setDate(now.getDate())
  dayEnd.setHours(3, 59, 59, 999)
  return [dayStart, dayEnd]
}
export function getWeekAgo() {
  const now = new Date()
  const dayStart = new Date()
  const dayEnd = new Date()

  dayStart.setDate(now.getDate() - 7)
  dayStart.setHours(0, 0, 0, 0)
  dayEnd.setDate(now.getDate() - 7)
  const nowHour = now.getHours()
  const nowMinutes = now.getMinutes()
  dayEnd.setHours(nowHour, nowMinutes, 59, 999)
  return [dayStart, dayEnd]
}

export function getCurrentDay() {
  const now = new Date()
  const dayStart = new Date()
  const dayEnd = new Date()

  dayStart.setHours(0, 0, 0, 0)
  dayEnd.setDate(now.getDate())
  dayEnd.setHours(23, 59, 59, 999)

  return [dayStart, dayEnd]
}

export function convertToPieChartLabels(logs, idLabel, valueLabel) {
  return logs.map(function (obj) {
    return {
      id: obj[idLabel],
      label: obj[idLabel],
      value: obj[valueLabel],
    }
  })
}

/**
 * If a log has missing data for a particular timestamp, replace that log with the missing data log.
 * and then add all the rest of the missing data that has no match in original logs (either timestamp or entity).
 * @param {Array} logsData - Array of logs data.
 * @param {Array} missingData - Array of missing data logs.
 * @param {Object} entranceEntities - Array of entrance entities.
 * @returns {Array} - Updated logs data.
 */
export function replaceMissingData(logsData, missingData = [], entranceEntities) {
  // Create a map for quicker entity lookup
  const entityMap = Object.fromEntries(Object.values(entranceEntities)?.map((entity) => [entity.id, entity]))

  // Create a new array to store the updated logs data
  const updatedLogsData = logsData.map((data) => {
    // Create a deep copy of the original data object
    // to avoid modifying the original
    const updatedData = JSON.parse(JSON.stringify(data))
    const entityId = data.entity

    // Update logs array within the new data object
    updatedData.logs = data.logs.map((log) => {
      const timestamp = log.timestamp
      // Find the corresponding entity in entranceEntities
      const entity = entityMap[entityId]

      // If entity doesn't exist, return the original log
      if (!entity) {
        return log
      }

      // Find the corresponding missing log entry for the same timestamp and entity
      const missingLog = missingData?.find(
        (missingEntry) =>
          new Date(missingEntry.info_entity.timestamp).getTime() === new Date(timestamp).getTime() &&
          missingEntry.logs.some((missingLog) => missingLog.entity_id === entityId)
      )

      if (missingLog) {
        // Create a deep copy of the log object to avoid modifying the original
        const updatedLog = JSON.parse(JSON.stringify(log))

        // Check if both count_in_sum and count_out_sum are not equal to 0
        const countInSum = missingLog.logs.find((log) => log.entity_id === entityId).count_in_sum
        const countOutSum = missingLog.logs.find((log) => log.entity_id === entityId).count_out_sum

        if (countInSum !== 0 || countOutSum !== 0) {
          // Replace count_in_sum and count_out_sum with missingData values
          updatedLog.count_in_sum = countInSum
          updatedLog.count_out_sum = countOutSum

          // Add missingInfo properties to the log
          updatedLog.missingEntityName = entity.name
          updatedLog.missingFileId = missingLog.info_entity.info_id
          updatedLog.missingFileName = missingLog.info_entity.title
          updatedLog.isModified = true

          return updatedLog
        }
      }

      return log
    })

    return updatedData
  })

  // Handle entries in missingData without an entity match or timestamp match
  missingData.forEach((missingEntry) => {
    const timestamp = missingEntry.info_entity.timestamp

    missingEntry.logs.forEach((missingLog) => {
      const entityId = missingLog.entity_id
      const entity = entityMap[entityId]

      // Check if the entity exists in logsData
      const entityExists = updatedLogsData.some((data) => data.entity === entityId)

      // Check if the timestamp exists for the given entity in logsData
      const timestampExists = updatedLogsData.some(
        (data) =>
          data.entity === entityId &&
          data.logs.some((log) => new Date(log.timestamp).getTime() === new Date(timestamp).getTime())
      )

      if (!entityExists || !timestampExists) {
        // Check if both count_in_sum and count_out_sum are not equal to 0
        const countInSumNotZero = missingLog.count_in_sum !== 0
        const countOutSumNotZero = missingLog.count_out_sum !== 0

        if (countInSumNotZero || countOutSumNotZero) {
          const updatedLog = JSON.parse(JSON.stringify(missingLog))

          updatedLog.timestamp = timestamp

          // Add missingInfo properties to the log
          updatedLog.missingEntityName = entity ? entity.name : null
          updatedLog.missingFileId = missingEntry.info_entity.info_id
          updatedLog.missingFileName = missingEntry.info_entity.title
          updatedLog.isModified = true

          // If the entity doesn't exist, create a new entry in updatedLogsData
          if (!entityExists) {
            updatedLogsData.push({
              entity: entityId,
              logs: [updatedLog],
            })
          } else {
            // If the entity exists but the timestamp doesn't, add the updatedLog to the existing entity
            const existingEntity = updatedLogsData.find((data) => data.entity === entityId)
            existingEntity.logs.push(updatedLog)
          }
        }
      }
    })
  })

  return updatedLogsData
}

// calculating cumulative sum of counts over hours (count in , count out, occupancy)
//                                                 (male count , female count)
//                                                 (adult count, children count)
// used to fill in table of hourly data in HourlyData component that is used for entrance gates and tenants.
/**
 * Calculate sums per timestamp and handle missingInfo.
 * @param {Array} counterLogs - Array of counter logs.
 * @param {number|null|undefined} [dayFilter] - Optional day filter value. It can be a number, null, or undefined.
 * @param {Object} [entranceEntities] - Optional Array of entrance entities.
 * @param {Array|boolean} [missingData] - Optional Array of missing data logs.
 * @returns {Array} - Calculated sums per timestamp.
 */
export function calculateSumPerTime(counterLogs, dayFilter, entranceEntities, missingData) {
  let arr = []
  const timeDict = {}
  // replace original data with missing data if missing data exists

  let updatedLogsData = []
  if (missingData && missingData.length > 0) {
    updatedLogsData = replaceMissingData(counterLogs, missingData, entranceEntities)
  } else {
    updatedLogsData = counterLogs
  }

  // Iterate through each entity's logs
  updatedLogsData?.forEach((elem) => {
    elem.logs.forEach((log) => {
      // Check if timestamp exists in timeDict
      if (log.timestamp in timeDict) {
        const existingObj = arr[timeDict[log.timestamp]]

        // Update existingObj with log values
        existingObj["Count In"] += log.count_in_sum
        existingObj["Male Count"] += log.male_count_in
        existingObj["Female Count"] += log.female_count_in
        existingObj["Unknown Age Count"] += log.unknown_age_count_in
        existingObj["Adult Count"] += log.adult_count
        existingObj["Children Count"] += log.child_count
        existingObj["Unknown Gender Count"] += log.unknown_gender_count_in
        existingObj["Count Out"] += log.count_out_sum
        existingObj["Dwell Left"] += log.dwell_left
        existingObj["Dwell Entered"] += log.dwell_entered
        existingObj["Stood Left"] += log.stood_left
        existingObj["Stood Entered"] += log.stood_entered
        existingObj["Dwelling counts"] += log.dwell_entered + log.dwell_left + log.stood_entered + log.stood_left
        existingObj["Dwelling time"] += Math.floor(log.duration / 60)
        existingObj["Difference"] += log.count_in_sum - log.count_out_sum

        // Handle isModified flag and missingInfo
        if (log.isModified) {
          existingObj.isModified = true

          // Add or update missingInfo array
          const missingInfo = {
            fileName: log.missingFileName || "",
            entities: log.missingEntityName || "", // Initialize as a string
            countIn: log.count_in_sum,
            countOut: log.count_out_sum,
            fileId: log.missingFileId,
          }

          const existingMissingInfo = existingObj.missingInfo || []
          const existingMissingInfoIndex = existingMissingInfo.findIndex((info) => {
            // Check if the filenames match
            const isSameFile = info.fileId === missingInfo.fileId

            return isSameFile
          })

          if (existingMissingInfoIndex !== -1) {
            // If matching entry found, update the entry
            const existingInfo = existingMissingInfo[existingMissingInfoIndex]

            // Sum counts regardless of entity uniqueness
            existingInfo.countIn += log.count_in_sum
            existingInfo.countOut += log.count_out_sum

            // Append the entity to the existing string, if not already present
            if (!existingInfo.entities.includes(log.missingEntityName)) {
              existingInfo.entities += `, ${log.missingEntityName}`
            }
          } else {
            // If no matching entry found, add a new entry
            existingMissingInfo.push(missingInfo)
          }

          existingObj.missingInfo = existingMissingInfo
        }
      } else {
        // Create a new object for the timestamp
        const newObj = {
          timestamp: log.timestamp,
          "Count In": log.count_in_sum,
          "Male Count": log.male_count_in,
          "Female Count": log.female_count_in,
          "Adult Count": log.adult_count,
          "Unknown Age Count": log.unknown_age_count_in,
          "Unknown Gender Count": log.unknown_gender_count_in,
          "Children Count": log.child_count,
          "Count Out": log.count_out_sum,
          "Dwell Left": log.dwell_left,
          "Dwell Entered": log.dwell_entered,
          "Stood Left": log.stood_left,
          "Stood Entered": log.stood_entered,
          "Dwelling counts": log.dwell_entered + log.dwell_left + log.stood_entered + log.stood_left,
          "Dwelling time": Math.floor(log.duration / 60),
          Difference: log.count_in_sum - log.count_out_sum,
        }

        // Handle isModified flag and missingInfo
        if (log.isModified) {
          newObj.isModified = true

          // Add missingInfo array
          newObj.missingInfo = [
            {
              fileName: log.missingFileName || "",
              entities: log.missingEntityName || "", // Initialize as a string
              countIn: log.count_in_sum,
              countOut: log.count_out_sum,
              fileId: log.missingFileId,
            },
          ]
        }

        // Update timeDict with the index of the new object
        timeDict[newObj.timestamp] = arr.length

        // Push the new object to the result array
        arr.push(newObj)
      }
    })
  })

  // Filter based on dayFilter
  arr =
    dayFilter !== null && dayFilter >= 0 && dayFilter <= 6
      ? arr.filter((elem) => new Date(elem.timestamp).getDay() === dayFilter)
      : arr

  // Sort by timestamp
  arr.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))

  // Return the final array
  return arr
}

// used in HourlyData in entrance gates stats card and hourly data line graph
// converting data from logs with time stamps
// into a data object suitable to represent count in,count out and occupancy as lines in a line graph
// x => represents hours of the day , y => represents count (in/out/occupancy) number for that hour of the day
// which is ready to be plotted using nivo line graph
/**
 * Convert data to hourly line graph format.
 * @param {Array} logsSummedByTime - Array of counter logs.
 * @param {"Gates counts" | "Dwelling counts" | "Dwelling time" | } [dataType] - Array of counter logs.
 * @returns {Array} - Converted data suitable for a line graph.
 */
export function convertDataToHourlyLineGraph(logsSummedByTime, dataType) {
  let convertedData = []
  if (dataType === "Gates counts" || !dataType) {
    //resetting array after type declaration
    convertedData = [
      { id: "Count Out", data: [] },
      { id: "Count In", data: [] },
      { id: "Occupancy", data: [] },
    ]
    let occupancy = 0
    // Iterate through each element in arr
    logsSummedByTime.forEach((elem) => {
      // Update occupancy based on Count In and Count Out
      occupancy += elem["Count In"] - elem["Count Out"]
      // Push data to convertedData for Count Out
      convertedData[0].data.push({
        x: new Date(elem.timestamp),
        y: elem["Count Out"],
        missingInfo: elem.isModified ? elem.missingInfo : undefined,
      })
      // Push data to convertedData for Count In
      convertedData[1].data.push({
        x: new Date(elem.timestamp),
        y: elem["Count In"],
        missingInfo: elem.isModified ? elem.missingInfo : undefined,
      })
      // Push data to convertedData for Occupancy
      convertedData[2].data.push({ x: new Date(elem.timestamp), y: occupancy > 0 ? occupancy : 0 })
    })
    return convertedData
  } else {
    convertedData = [{ id: dataType, data: [] }]
    logsSummedByTime.forEach((elem) => {
      convertedData[0].data.push({
        x: new Date(elem.timestamp),
        y: elem[dataType],
      })
    })
    return convertedData
  }
}

export function processMissingLogs(logs, entities, dayToFilter) {
  // Initialize the result array with two entries for Count In and Count Out
  const result = [
    { id: "Count In", data: [] },
    { id: "Count Out", data: [] },
  ]

  // Create a mapping of entity IDs to entity names for quick lookup
  const entityNamesById = {}
  Object.values(entities).forEach((entity) => {
    const entityId = entity.id || -1 // Default to -1 if id is missing
    entityNamesById[entityId] = entity.name
  })

  // Filter logs based on the provided dayToFilter if specified
  let filteredLogs = logs
  if (!!dayToFilter) {
    filteredLogs = logs.filter((log) => {
      const logDay = new Date(log.info_entity.timestamp).getDay()
      return logDay === dayToFilter
    })
  }

  // Iterate through the filtered logs
  filteredLogs.forEach((log) => {
    const timestamp = log.info_entity.timestamp
    const countInSum = log?.logs?.reduce((sum, entry) => sum + entry.count_in_sum, 0)
    const countOutSum = log?.logs?.reduce((sum, entry) => sum + entry.count_out_sum, 0)

    // Map entity IDs to entity names
    const entitiesName = log.logs.map((entry) => entityNamesById[entry.entity_id])

    // Create missingInfo object
    const missingInfo = {
      fileName: log.info_entity.title,
      entities: entitiesName.join(", "),
      countIn: countInSum,
      countOut: countOutSum,
    }

    // Process Count In log entry
    const countInDataEntry = result[0].data.find(
      (entry) => new Date(entry.x).getTime() === new Date(timestamp).getTime()
    )
    if (countInDataEntry) {
      // If an entry exists, update the existing entry
      countInDataEntry.y += countInSum
      countInDataEntry.missingInfo.push(missingInfo)
    } else {
      // If no entry exists, create a new entry
      result[0].data.push({
        x: new Date(timestamp),
        y: countInSum,
        missingInfo: [missingInfo],
      })
    }

    // Process Count Out log entry
    const countOutDataEntry = result[1].data.find(
      (entry) => new Date(entry.x).getTime() === new Date(timestamp).getTime()
    )
    if (countOutDataEntry) {
      // If an entry exists, update the existing entry
      countOutDataEntry.y += countOutSum
      countOutDataEntry.missingInfo.push(missingInfo) // Add missingInfo to "Count Out"
    } else {
      // If no entry exists, create a new entry
      result[1].data.push({
        x: new Date(timestamp),
        y: countOutSum,
        missingInfo: [missingInfo],
      })
    }
  })

  // Sort the data arrays based on timestamp
  result[0].data.sort((a, b) => a.x - b.x)
  result[1].data.sort((a, b) => a.x - b.x)

  // Return the processed array
  return result
}

export function convertDatatoDailyLineGraph(counterLogs) {
  const arr = calculateSumPerTime(counterLogs)
  let arr2 = [
    { id: "Count In", data: [] },
    { id: "Count Out", data: [] },
    { id: "Occupancy", data: [] },
  ]
  let occupancy = 0
  arr.forEach((elem) => {
    occupancy += elem["Count In"] - elem["Count Out"]
    arr2[0].data.push({ x: new Date(elem.timestamp), y: elem["Count In"] })
    arr2[1].data.push({ x: new Date(elem.timestamp), y: elem["Count Out"] })
    arr2[2].data.push({ x: new Date(elem.timestamp), y: occupancy > 0 ? occupancy : 0 })
  })
  return arr2
}

export function calculateCountTotals(data, type) {
  const visitors = {
    count_in_sum: 0,
    count_out_sum: 0,
    dwell_left: 0,
    dwell_entered: 0,
    stood_left: 0,
    stood_entered: 0,
    male_count_in: 0,
    female_count_in: 0,
    male_count_out: 0,
    female_count_out: 0,
    inside: 0,
    inside_male: 0,
    inside_female: 0,
    adult_count: 0,
    child_count: 0,
    unknown_age_count_sum: 0,
    unknown_gender_count_sum: 0,
  }
  if (type === "tenantDetails") {
    data.forEach((log) => {
      visitors.count_in_sum += log["Count In"]
      visitors.count_out_sum += log["Count Out"]
      visitors.dwell_left += log["Dwell Left"]
      visitors.dwell_entered += log["Dwell Entered"]
      visitors.stood_left += log["Stood Left"]
      visitors.stood_entered += log["Stood Entered"]
      visitors.male_count_in += log["Male Count"]
      visitors.female_count_in += log["Female Count"]
      visitors.adult_count += log["Adult Count"]
      visitors.child_count += log["Children Count"]
    })
  } else {
    data.forEach((item) => {
      item["logs"].forEach((log) => {
        visitors.count_in_sum += log.count_in_sum
        visitors.count_out_sum += log.count_out_sum
        visitors.male_count_in += log.male_count_in
        visitors.female_count_in += log.female_count_in
        visitors.male_count_out += log.male_count_out
        visitors.female_count_out += log.female_count_out
        visitors.adult_count += log.adult_count
        visitors.child_count += log.child_count
        visitors.dwell_left += log.dwell_left
        visitors.dwell_entered += log.dwell_entered
        visitors.stood_left += log.stood_left
        visitors.stood_entered += log.stood_entered
        visitors.unknown_age_count_sum += log.unknown_age_count_in
        visitors.unknown_gender_count_sum += log.unknown_gender_count_in
      })
    })
  }

  visitors.inside = visitors.count_in_sum - visitors.count_out_sum
  visitors.inside = visitors.inside < 0 ? 0 : visitors.inside
  visitors.inside_male = visitors.male_count_in - visitors.male_count_out
  visitors.inside_male = visitors.inside_male < 0 ? 0 : visitors.inside_male
  visitors.inside_female = visitors.female_count_in - visitors.female_count_out
  visitors.inside_female = visitors.inside_female < 0 ? 0 : visitors.inside_female

  return visitors
}
/**
 * Convert logs data into an array of objects with gate name and total count (in/out).
 * Used in entrances gates' PaginatedBarGraph component (bar graph).
 * @param {Object} entities - Array of entrance entities.
 * @param {Array} logs - Array of logs data (missing data is included).
 * @returns {Array} - Bar graph and table-ready data.
 */
export function convertLogsDataToGateStats(entities, logs) {
  let gateStats = [
    {
      id: "",
      entity: "",
      "Count In": 0,
      "Count Out": 0,
      "Missing Count In": 0,
      "Missing Count Out": 0,
      male_count: 0,
      female_count: 0,
      adult_count: 0,
      child_count: 0,
      color: "",
      missingInfo: "",
      isModified: false,
    },
  ]
  gateStats = []
  if (Object.values(entities) && Object.values(entities).length > 0) {
    logs.forEach((obj) => {
      let [countIn, countOut, maleCount, femaleCount, adultCount, childCount] = [0, 0, 0, 0, 0, 0]
      let [missingCountIn, missingCountOut] = [0, 0]
      const missingInfoSet = new Set()
      let isModified = false

      obj["logs"].forEach((log) => {
        maleCount += log.male_count_in
        femaleCount += log.female_count_in
        adultCount += log.adult_count
        childCount += log.child_count

        // Check if the log is modified
        if (log.isModified) {
          isModified = true
          missingCountIn += log.count_in_sum
          missingCountOut += log.count_out_sum

          // Add missingFileName to the set for uniqueness
          if (log.missingFileName) {
            missingInfoSet.add(log.missingFileName)
          }
        } else {
          // Add to the total count if not modified
          countIn += log.count_in_sum
          countOut += log.count_out_sum
        }
      })

      const gateStat = {
        id: entities[obj.entity]?.id,
        entity: entities[obj.entity]?.name,
        "Count In": countIn,
        "Count Out": countOut,
        "Missing Count In": missingCountIn,
        "Missing Count Out": missingCountOut,
        male_count: maleCount,
        female_count: femaleCount,
        adult_count: adultCount,
        child_count: childCount,
        color: "var(--indigo-background-2)",
        missingInfo: Array.from(missingInfoSet).join(", "),
        isModified: isModified,
      }
      gateStats.push(gateStat)
    })

    // Filter out zero entries
    if (gateStats.length > 0) {
      gateStats = gateStats.filter(function (val) {
        return val["Count In"] !== 0 || val["Missing Count In"] !== 0
      })
    }

    // Sort and highlight top gate
    if (gateStats.length > 0) {
      // Sorting gates descending from highest count to lowest
      gateStats.sort(
        (a, b) => parseFloat(b["Count In"] + b["Missing Count In"]) - parseFloat(a["Count In"] + a["Missing Count In"])
      )

      // Highlighting the top gate
      gateStats[0].color = "var(--indigo-background-1)"
    }
  }
  return gateStats
}

export function calculateTotalTarget(targetData, startDate, endDate) {
  let result = 0
  let start = format(startDate, "yyyy-MM-dd")
  let end = format(endDate, "yyyy-MM-dd")
  if (Object.values(targetData) && Object.values(targetData).length > 0) {
    for (let i = 0; i < Object.values(targetData).length; i++) {
      if (Object.values(targetData)[i].date === start) {
        for (let j = i; j < Object.values(targetData).length; j++) {
          result += Object.values(targetData)[j].target
          if (Object.values(targetData)[j].date === end) {
            return result
          }
        }
      }
    }
  }
  return result
}
// used in AvgCountWeek bar graph
// converting data from logs with time stamps
// into logs that has a day mark and count for each type (age distributed and gender distributed)
// which is ready to be plotted using nivo bar graph
export function convertToAverageDailyBarChartLabels(logs) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  let result = [
    {
      Day: "",
      "Male Count": 0,
      "Female Count": 0,
      "Adult Count": 0,
      "Children Count": 0,
      "Unknown Gender Count": 0,
      "Unknown Age Count": 0,
    },
  ]
  let countDays = []
  let tmpM = []
  let tmpF = []
  let tmpA = []
  let tmpC = []
  let tmpUA = []
  let tmpUG = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 7; i++) {
      result[i] = {
        Day: days[i],
        "Male Count": 0,
        "Female Count": 0,
        "Adult Count": 0,
        "Children Count": 0,
        "Unknown Gender Count": 0,
        "Unknown Age Count": 0,
      }
      countDays[i] = 0
      tmpM[i] = 0
      tmpF[i] = 0
      tmpA[i] = 0
      tmpC[i] = 0
      tmpUA[i] = 0
      tmpUG[i] = 0
    }
    let lastCountedDay = -1
    for (let i = 0; i < logs.length; i++) {
      tmpM[new Date(logs[i].timestamp).getDay()] += logs[i]["Male Count"]
      tmpF[new Date(logs[i].timestamp).getDay()] += logs[i]["Female Count"]
      tmpA[new Date(logs[i].timestamp).getDay()] += logs[i]["Adult Count"]
      tmpC[new Date(logs[i].timestamp).getDay()] += logs[i]["Children Count"]
      tmpUA[new Date(logs[i].timestamp).getDay()] += logs[i]["Unknown Age Count"]
      tmpUG[new Date(logs[i].timestamp).getDay()] += logs[i]["Unknown Gender Count"]

      if (new Date(logs[i].timestamp).getDay() !== lastCountedDay) {
        countDays[new Date(logs[i].timestamp).getDay()] += 1
        lastCountedDay = new Date(logs[i].timestamp).getDay()
      }
    }

    if (countDays.includes(0)) {
      for (let i = 0; i < countDays.length; i++) {
        if (countDays[i] === 0) {
          countDays[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      result[i]["Male Count"] = parseInt(tmpM[i] / countDays[i])
      result[i]["Female Count"] = parseInt(tmpF[i] / countDays[i])
      result[i]["Adult Count"] = parseInt(tmpA[i] / countDays[i])
      result[i]["Children Count"] = parseInt(tmpC[i] / countDays[i])
      result[i]["Unknown Age Count"] = parseInt(tmpUA[i] / countDays[i])
      result[i]["Unknown Gender Count"] = parseInt(tmpUG[i] / countDays[i])
    }
  }
  return result
}

export function convertToHourlyWeekdaysBarChartLabels(logs) {
  let result = []
  let countHours = []
  let tmp = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) {
          result[i] = { Hour: i + 12 + " AM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " AM", "Count In": 0 }
        }
      } else {
        if (i > 12) {
          result[i] = { Hour: i - 12 + " PM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " PM", "Count In": 0 }
        }
      }
      tmp[i] = 0
      countHours[i] = 0
    }

    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() >= 0 && new Date(logs[i].timestamp).getDay() <= 4) {
        tmp[new Date(logs[i].timestamp).getHours()] += logs[i]["Count In"]
        countHours[new Date(logs[i].timestamp).getHours()] += 1
      }
    }

    if (countHours.includes(0)) {
      for (let i = 0; i < countHours.length; i++) {
        if (countHours[i] === 0) {
          countHours[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      result[i]["Count In"] = parseInt((tmp[i] / countHours[i]).toFixed(2))
    }

    for (let i = 0; i < 4; i++) {
      let first = result.splice(0, 1)
      result.push(first[0])
    }
  }
  return result
}

export function convertToHourlyWeekendBarChartLabels(logs) {
  let result = []
  let countHours = []
  let tmp = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) {
          result[i] = { Hour: i + 12 + " AM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " AM", "Count In": 0 }
        }
      } else {
        if (i > 12) {
          result[i] = { Hour: i - 12 + " PM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " PM", "Count In": 0 }
        }
      }
      tmp[i] = 0
      countHours[i] = 0
    }

    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() === 5 || new Date(logs[i].timestamp).getDay() === 6) {
        tmp[new Date(logs[i].timestamp).getHours()] += logs[i]["Count In"]
        countHours[new Date(logs[i].timestamp).getHours()] += 1
      }
    }

    if (countHours.includes(0)) {
      for (let i = 0; i < countHours.length; i++) {
        if (countHours[i] === 0) {
          countHours[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      result[i]["Count In"] = parseInt((tmp[i] / countHours[i]).toFixed(2))
    }

    for (let i = 0; i < 4; i++) {
      let first = result.splice(0, 1)
      result.push(first[0])
    }
  }
  return result
}
// used in HourlyAvgData line graph
// converting data from logs with time stamps
// into logs that has a hours marks and (x,y) points combination
// x => represents hours of the day through the week , y => represents count in number for that hour of the day
// excluding days of weekend
// which is ready to be plotted using nivo bar graph
export function convertToHourlyAvgWeekdaysCountGraphLogs(logs) {
  let result = []
  let countHours = []
  let tmpCin = []
  let arr2 = [{ id: "Weekdays", data: [{ x: "", y: 0 }] }]
  arr2 = [{ id: "Weekdays", data: [] }]

  if (logs && logs.length > 0) {
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) {
          result[i] = { Hour: i + 12 + " AM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " AM", "Count In": 0 }
        }
      } else {
        if (i > 12) {
          result[i] = { Hour: i - 12 + " PM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " PM", "Count In": 0 }
        }
      }
      tmpCin[i] = 0
      countHours[i] = 0
    }
    // exclude days of weekend
    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() >= 0 && new Date(logs[i].timestamp).getDay() <= 4) {
        tmpCin[new Date(logs[i].timestamp).getHours()] += logs[i]["Count In"]
        countHours[new Date(logs[i].timestamp).getHours()] += 1
      }
    }

    if (countHours.includes(0)) {
      for (let i = 0; i < countHours.length; i++) {
        if (countHours[i] === 0) {
          countHours[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      result[i]["Count In"] = parseInt((tmpCin[i] / countHours[i]).toFixed(2))
    }

    for (let i = 0; i < 4; i++) {
      let first = result.splice(0, 1)
      result.push(first[0])
    }
    result.forEach((elem) => {
      arr2[0].data.push({ x: elem.Hour, y: elem["Count In"] })
    })
  }
  return arr2
}
// used in HourlyAvgData line graph
// converting data from logs with time stamps
// into logs that has a hours marks and (x,y) points combination
// x => represents hours of the day through the week , y => represents count in number for that hour of the day
// includes only days of weekends
// which is ready to be plotted using nivo bar graph
export function convertToHourlyAvgWeekendsCountGraphLogs(logs) {
  let result = []
  let countHours = []
  let tmpCin = []
  let arr2 = [{ id: "Weekends", data: [{ x: "", y: 0 }] }]
  arr2 = [{ id: "Weekends", data: [] }]
  if (logs && logs.length > 0) {
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) {
          result[i] = { Hour: i + 12 + " AM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " AM", "Count In": 0 }
        }
      } else {
        if (i > 12) {
          result[i] = { Hour: i - 12 + " PM", "Count In": 0 }
        } else {
          result[i] = { Hour: i + " PM", "Count In": 0 }
        }
      }
      tmpCin[i] = 0
      countHours[i] = 0
    }

    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() === 5 || new Date(logs[i].timestamp).getDay() === 6) {
        tmpCin[new Date(logs[i].timestamp).getHours()] += logs[i]["Count In"]
        countHours[new Date(logs[i].timestamp).getHours()] += 1
      }
    }

    if (countHours.includes(0)) {
      for (let i = 0; i < countHours.length; i++) {
        if (countHours[i] === 0) {
          countHours[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      result[i]["Count In"] = parseInt((tmpCin[i] / countHours[i]).toFixed(2))
    }

    for (let i = 0; i < 4; i++) {
      let first = result.splice(0, 1)
      result.push(first[0])
    }
    result.forEach((elem) => {
      arr2[0].data.push({ x: elem.Hour, y: elem["Count In"] })
    })
  }
  return arr2
}

export function convertToHourlyWeekdaysBarChartLabelsOccupancy(logs) {
  let result = []
  let countHours = []
  let tmp = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) {
          result[i] = { Hour: i + 12 + " AM", Occupancy: 0 }
        } else {
          result[i] = { Hour: i + " AM", Occupancy: 0 }
        }
      } else {
        if (i > 12) {
          result[i] = { Hour: i - 12 + " PM", Occupancy: 0 }
        } else {
          result[i] = { Hour: i + " PM", Occupancy: 0 }
        }
      }
      tmp[i] = 0
      countHours[i] = 0
    }

    let tmpDays = {}

    for (let i = 0; i < logs.length; i++) {
      let currentDay = new Date(logs[i].timestamp).getDay()
      let currentHour = new Date(logs[i].timestamp).getHours()
      if (currentDay >= 0 && currentDay <= 4) {
        let hours = tmpDays[currentDay] || {}
        hours[currentHour] = logs[i]["Count In"] - logs[i]["Count Out"]
        tmpDays[currentDay] = hours
      }
    }

    // accumulate for each hour in each day separately
    for (const day in tmpDays) {
      if (tmpDays.hasOwnProperty(day)) {
        const hours = tmpDays[day]

        for (const hour in hours) {
          if (hours.hasOwnProperty(hour)) {
            const currentOccupancy = hours[hour]
            if (hour !== 0) {
              hours[hour] = currentOccupancy + (hours[hour - 1] || 0)
            }
          }
        }
      }
    }

    // accumulate occupancy of same hours of all days
    for (let i = 0; i < tmp.length; i++) {
      let tmpOccupancy = 0
      for (const day in tmpDays) {
        if (tmpDays.hasOwnProperty(day)) {
          const hours = tmpDays[day]
          if (hours[i]) {
            tmpOccupancy += hours[i]
            countHours[i] += 1
          }
        }
      }
      tmp[i] = tmpOccupancy
    }

    if (countHours.includes(0)) {
      for (let i = 0; i < countHours.length; i++) {
        if (countHours[i] === 0) {
          countHours[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      let occupancy = parseInt((tmp[i] / countHours[i]).toFixed(2))
      result[i]["Occupancy"] = occupancy > 0 ? occupancy : 0
    }

    for (let i = 0; i < 4; i++) {
      let first = result.splice(0, 1)
      result.push(first[0])
    }
  }
  return result
}

export function convertToHourlyWeekendBarChartLabelsOccupancy(logs) {
  let result = []
  let countHours = []
  let tmp = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) {
          result[i] = { Hour: i + 12 + " AM", Occupancy: 0 }
        } else {
          result[i] = { Hour: i + " AM", Occupancy: 0 }
        }
      } else {
        if (i > 12) {
          result[i] = { Hour: i - 12 + " PM", Occupancy: 0 }
        } else {
          result[i] = { Hour: i + " PM", Occupancy: 0 }
        }
      }
      tmp[i] = 0
      countHours[i] = 0
    }

    let tmpDays = {}

    for (let i = 0; i < logs.length; i++) {
      let currentDay = new Date(logs[i].timestamp).getDay()
      let currentHour = new Date(logs[i].timestamp).getHours()
      if (currentDay === 5 || currentDay === 6) {
        let hours = tmpDays[currentDay] || {}
        hours[currentHour] = logs[i]["Count In"] - logs[i]["Count Out"]
        tmpDays[currentDay] = hours
      }
    }

    // accumulate for each hour in each day separately
    for (const day in tmpDays) {
      if (tmpDays.hasOwnProperty(day)) {
        const hours = tmpDays[day]

        for (const hour in hours) {
          if (hours.hasOwnProperty(hour)) {
            const currentOccupancy = hours[hour]
            if (hour !== 0) {
              hours[hour] = currentOccupancy + (hours[hour - 1] || 0)
            }
          }
        }
      }
    }

    // accumulate occupancy of same hours of all days
    for (let i = 0; i < tmp.length; i++) {
      let tmpOccupancy = 0
      for (const day in tmpDays) {
        if (tmpDays.hasOwnProperty(day)) {
          const hours = tmpDays[day]
          if (hours[i]) {
            tmpOccupancy += hours[i]
            countHours[i] += 1
          }
        }
      }
      tmp[i] = tmpOccupancy
    }

    if (countHours.includes(0)) {
      for (let i = 0; i < countHours.length; i++) {
        if (countHours[i] === 0) {
          countHours[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      let occupancy = parseInt((tmp[i] / countHours[i]).toFixed(2))
      result[i]["Occupancy"] = occupancy > 0 ? occupancy : 0
    }

    for (let i = 0; i < 4; i++) {
      let first = result.splice(0, 1)
      result.push(first[0])
    }
  }
  return result
}

export function convertToDailyStackedBarChartLabels(entities, data) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const keys = {}
  let result = []

  if (data && data.length > 0) {
    for (let i = 0; i < Object.values(entities).length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (Object.values(entities)[i].id === data[j].entity) {
          keys[Object.values(entities)[i].name] = 0
        }
      }
    }

    let tmpKey = {}
    for (let i = 0; i < 7; i++) {
      for (let k in keys) tmpKey[k] = keys[k]
      tmpKey.day = days[i]
      result[i] = tmpKey
      tmpKey = {}
    }

    let tmp = []
    for (let i = 0; i < data.length; i++) {
      //insert zeros into tmp
      for (let x = 0; x < 7; x++) {
        tmp[x] = 0
      }
      for (let j = 0; j < data[i].logs.length; j++) {
        tmp[new Date(data[i].logs[j].timestamp).getDay()] += data[i].logs[j].count_in_sum
      }
      //copy into result
      for (let k = 0; k < result.length; k++) {
        result[k][Object.values(entities)[i].name] = tmp[k]
      }
    }
  }
  return result
}

export function convertToWeekendBarChartLabels(logs) {
  const days = ["Fri", "Sat"]
  let keys = {}
  let result = []
  let tmpCounts = []
  let tmpTimestamps = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 2; i++) {
      keys = { day: days[i] }
      result.push(keys)
    }

    let lastCountedDay = -1
    let j = -1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() !== lastCountedDay) {
        j++
        lastCountedDay = new Date(logs[i].timestamp).getDay()
        tmpCounts[j] = 0
        tmpTimestamps[j] = logs[i].timestamp
      }
      tmpCounts[j] += logs[i]["Count In"]
    }

    let k = 1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(tmpTimestamps[i]).getDay() === 5) {
        result[0]["Week" + k] = tmpCounts[i]
        result[0][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
      } else if (new Date(tmpTimestamps[i]).getDay() === 6) {
        result[1]["Week" + k] = tmpCounts[i]
        result[1][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
        k++
      }
    }
  }
  return result
}

export function convertToWeekdaysBarChartLabels(logs) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu"]
  let keys = {}
  let result = []
  let tmpCounts = []
  let tmpTimestamps = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 5; i++) {
      keys = { day: days[i] }
      result.push(keys)
    }

    let lastCountedDay = -1
    let j = -1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() !== lastCountedDay) {
        j++
        lastCountedDay = new Date(logs[i].timestamp).getDay()
        tmpCounts[j] = 0
        tmpTimestamps[j] = logs[i].timestamp
      }
      tmpCounts[j] += logs[i]["Count In"]
    }

    let k = 1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(tmpTimestamps[i]).getDay() >= 0 && new Date(tmpTimestamps[i]).getDay() < 4) {
        result[new Date(tmpTimestamps[i]).getDay()]["Week" + k] = tmpCounts[i]
        result[new Date(tmpTimestamps[i]).getDay()][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
      } else if (new Date(tmpTimestamps[i]).getDay() === 4) {
        result[new Date(tmpTimestamps[i]).getDay()]["Week" + k] = tmpCounts[i]
        result[new Date(tmpTimestamps[i]).getDay()][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
        k++
      }
    }
  }
  return result
}

export function convertToWeekendBarChartLabelsOccupancy(logs) {
  const days = ["Fri", "Sat"]
  let keys = {}
  let result = []
  let tmpCounts = []
  let tmpTimestamps = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 2; i++) {
      keys = { day: days[i] }
      result.push(keys)
    }

    let lastCountedDay = -1
    let j = -1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() !== lastCountedDay) {
        j++
        lastCountedDay = new Date(logs[i].timestamp).getDay()
        tmpCounts[j] = 0
        tmpTimestamps[j] = logs[i].timestamp
      }
      tmpCounts[j] += logs[i]["Count In"] - logs[i]["Count Out"]
    }

    for (let i = 0; i <= j; i++) {
      if (tmpCounts[i] < 0) {
        tmpCounts[i] = 0
      }
    }

    let k = 1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(tmpTimestamps[i]).getDay() === 5) {
        result[0]["Week" + k] = tmpCounts[i]
        result[0][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
      } else if (new Date(tmpTimestamps[i]).getDay() === 6) {
        result[1]["Week" + k] = tmpCounts[i]
        result[1][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
        k++
      }
    }
  }
  return result
}

export function convertToWeekdaysBarChartLabelsOccupancy(logs) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu"]
  let keys = {}
  let result = []
  let tmpCounts = []
  let tmpTimestamps = []

  if (logs && logs.length > 0) {
    for (let i = 0; i < 5; i++) {
      keys = { day: days[i] }
      result.push(keys)
    }

    let lastCountedDay = -1
    let j = -1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() !== lastCountedDay) {
        j++
        lastCountedDay = new Date(logs[i].timestamp).getDay()
        tmpCounts[j] = 0
        tmpTimestamps[j] = logs[i].timestamp
      }
      tmpCounts[j] += logs[i]["Count In"] - logs[i]["Count Out"]
    }

    for (let i = 0; i <= j; i++) {
      if (tmpCounts[i] < 0) {
        tmpCounts[i] = 0
      }
    }

    let k = 1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(tmpTimestamps[i]).getDay() >= 0 && new Date(tmpTimestamps[i]).getDay() < 4) {
        result[new Date(tmpTimestamps[i]).getDay()]["Week" + k] = tmpCounts[i]
        result[new Date(tmpTimestamps[i]).getDay()][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
      } else if (new Date(tmpTimestamps[i]).getDay() === 4) {
        result[new Date(tmpTimestamps[i]).getDay()]["Week" + k] = tmpCounts[i]
        result[new Date(tmpTimestamps[i]).getDay()][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
        k++
      }
    }
  }
  return result
}

export function convertToHeatmapChartLabels(logs) {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  let keys = {}
  let formattedKeys = {}
  let result = []
  let tmpCounts = []
  let tmpTimestamps = []
  let newformatResult = [{ id: "", data: [{ x: "", y: 0, pointDate: "", color: "" }] }]
  newformatResult = []
  if (logs && logs.length > 0) {
    for (let i = 0; i < 6; i++) {
      keys = { week: "Week" + (i + 1) }
      formattedKeys = {
        id: "Week " + (i + 1),
      }
      result.push(keys)
      newformatResult.push(formattedKeys)
    }

    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < days.length; j++) {
        result[i][days[j]] = 0
      }
    }
    for (let i = 0; i < result.length; i++) {
      newformatResult[i].data = []
      for (let j = 0; j < days.length; j++) {
        const dataPointsArr = newformatResult[i].data
        const dataPoint = { x: days[j], y: 0 }
        dataPointsArr.push(dataPoint)
        newformatResult[i].data = dataPointsArr
      }
    }
    let lastCountedDay = -1
    let j = -1
    for (let i = 0; i < logs.length; i++) {
      if (new Date(logs[i].timestamp).getDay() !== lastCountedDay) {
        j++
        lastCountedDay = new Date(logs[i].timestamp).getDay()
        tmpCounts[j] = 0
        tmpTimestamps[j] = logs[i].timestamp
      }
      tmpCounts[j] += logs[i]["Count In"]
    }

    let weekCount = 0
    for (let i = 0; i < tmpCounts.length; i++) {
      if (
        result[weekCount] !== undefined &&
        days[new Date(tmpTimestamps[i]).getDay()] !== undefined &&
        result[weekCount][days[new Date(tmpTimestamps[i]).getDay()]] !== undefined &&
        tmpCounts[i] !== undefined
      ) {
        result[weekCount][days[new Date(tmpTimestamps[i]).getDay()]] = tmpCounts[i]
        result[weekCount][tmpCounts[i]] = format(new Date(tmpTimestamps[i]), "dd MMM yyyy")
      }
      if (new Date(tmpTimestamps[i]).getDay() === 6) {
        weekCount++
      }
    }
  }
  let maxValue = 0
  for (let i = 0; i < result.length; i++) {
    let arrayOfPoints = newformatResult[i].data
    for (let k = 0; k < arrayOfPoints.length; k++) {
      const correspondingY = result[i][arrayOfPoints[k].x]
      const pointDate = result[i][correspondingY.toString()]
      if (correspondingY > maxValue) {
        maxValue = correspondingY
      }
      arrayOfPoints[k].pointDate = pointDate
      arrayOfPoints[k].y = correspondingY
    }
    newformatResult[i].data = arrayOfPoints
  }
  for (let i = 0; i < newformatResult.length; i++) {
    const arrayOfPoints = newformatResult[i].data
    for (let k = 0; k < arrayOfPoints.length; k++) {
      arrayOfPoints[k].color = `rgb(40,140,240,${arrayOfPoints[k].y / maxValue})`
    }
  }

  return newformatResult
}

export const ExportReport = async (startDate, endDate, includeStaffData, shouldIncludeMissingData) => {
  try {
    const target = `${getApiBaseUrl()}/api/gates/report?from_date=${startDate}&to_date=${endDate}&include_staff=${includeStaffData}&include_missing_data=${shouldIncludeMissingData}`

    const res = await fetch(target, {
      method: "get",
      headers: {
        "content-type": "text/csv;charset=UTF-8",
        Authorization: "Bearer " + Auth.getAccessToken(),
      },
    })

    if (res.status === 200) {
      const data = await res.text()
      return data
    } else {
      return Promise.reject(new Error("Error fetching gates report"))
    }
  } catch (err) {
    console.log(err)
  }
}
