import { ChangeEvent, useState, FC, useRef } from "react"

import {
  Checkbox,
  InputText,
  Menu,
  MenuItem,
  RadioButton,
  RadioButtonGroup,
  Typography,
} from "@synapse-analytics/synapse-ui"

import { CameraServicesFilterOptions } from "../types/Custom/Interfaces"
import ArrowAdornment from "./ArrowAdornment"

import styles from "./ServicesFilter.module.scss"

interface Props {
  servicesMode: "and" | "or"
  handleServicesMode: (event: ChangeEvent<HTMLInputElement>) => void
  selectedServices: CameraServicesFilterOptions
  handleChangeServices: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const ServicesFilter: FC<Props> = ({ servicesMode, selectedServices, handleServicesMode, handleChangeServices }) => {
  const [servicesAnchorEl, setServicesAnchorEl] = useState<null | HTMLElement>(null)
  const servicesSelectRef = useRef<HTMLInputElement>(null)
  const isServicesMenuOpen = Boolean(servicesAnchorEl)

  const handleCloseServicesMenu = (): void => {
    setServicesAnchorEl(null)
  }

  const handleToggleServicesMenu = (): void => {
    if (servicesSelectRef?.current) {
      if (isServicesMenuOpen) {
        handleCloseServicesMenu()
      } else {
        setServicesAnchorEl(servicesSelectRef.current.parentElement)
        servicesSelectRef?.current.focus()
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <InputText
        value="Services"
        isSelect
        anchoringRef={servicesSelectRef}
        endAdornment={<ArrowAdornment handleAdornmentClick={handleToggleServicesMenu} isOpen={isServicesMenuOpen} />}
        handleInputClick={handleToggleServicesMenu}
        hideDescription
        size={140}
      />
      <Menu
        open={Boolean(servicesAnchorEl)}
        onClose={handleCloseServicesMenu}
        anchorEl={servicesAnchorEl}
        menuMaxContent
      >
        <div className={styles.andOrSwitch}>
          <Typography variant="label" variantColor={2} className={styles.andOrSwitchDesc}>
            Choose 'AND' or 'OR' to filter cameras based on selected services
          </Typography>
          <RadioButtonGroup name="camera-modes" alignment="horizontal" spacing={3} style={{ maxHeight: 30 }}>
            <RadioButton
              label="OR"
              value="or"
              id="or"
              readOnly
              checked={servicesMode === "or"}
              onChange={handleServicesMode}
            />
            <RadioButton
              label="AND"
              value="and"
              id="and"
              readOnly
              checked={servicesMode === "and"}
              onChange={handleServicesMode}
            />
          </RadioButtonGroup>
        </div>
        {Object.keys(selectedServices).map((keyName: keyof CameraServicesFilterOptions, i) => (
          <MenuItem key={i} isSelected={selectedServices[`${keyName}`]}>
            <Checkbox
              checked={selectedServices[`${keyName}`]}
              onChange={handleChangeServices}
              name={keyName}
              label={`${keyName.charAt(0).toUpperCase() + keyName.slice(1)}`}
              color="primary"
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
export default ServicesFilter
