import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import domtoimage from "dom-to-image"
import FileSaver from "file-saver"
import JSZip from "jszip"
import { Moment } from "moment"

import { ExportingRefs } from "../types/Custom/Interfaces"
import { ColoredGraphData } from "../types/Custom/Types"

export const adjustDate = (date: moment.Moment, time: "start" | "end"): moment.Moment => {
  /**
   * utcOffset(0) to standardize on UTC, and local() on the date before passing it to the date picker.
   * This will convert it back to the user's local time zone for display.
   */
  const adjustedDate = date.utcOffset(0).local()
  if (time === "start") {
    adjustedDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  } else {
    adjustedDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
  }
  return adjustedDate
}

/**
 * Function to extract the page number from pagination backend link
 * @param {string} link - The pagination link
 * @returns {number | undefined} - The extracted page number or undefined if not found
 */
export const extractPageFromBackEndPaginationLink = (link: string): number | undefined => {
  // Check if the link contains '?'
  const hasQueryParam = link.includes("?")

  if (!hasQueryParam) {
    // '?' character not found, return undefined
    return undefined
  }

  // Extract the query parameters from the URL
  const queryString = link.split("?")[1]
  const params = new URLSearchParams(queryString)

  // Get the value of the 'page' parameter
  const pageParam = params.get("page")

  // Parse and return the page number
  return pageParam ? parseInt(pageParam) : undefined // Return undefined if 'page' parameter is not found
}

/**
 * Export graphs as images and save them in a zip file.
 * @param {Moment} startDate - The start date for the analytics period.
 * @param {Moment} endDate - The end date for the analytics period.
 * @param {string} title - The title to use for the zip file.
 * @returns {void}
 */
export const exportGraphs = (
  cardsRefs: ExportingRefs,
  startDate: Moment | null,
  endDate: Moment | null,
  title: string
) => {
  // Check if startDate, endDate, and isLoading are truthy
  // Get an array of all refs
  const arrayOfRefs = Object.values(cardsRefs)

  // Initialize variables for creating the zip file and storing images
  let zip = new JSZip()
  let images: any[] = []
  let tmp = 0

  // Iterate over each ref
  arrayOfRefs.forEach((item) => {
    // Check if the item and item.current are objects with appendChild property
    if (item && item.current && typeof item.current === "object" && "appendChild" in item.current) {
      // Cast item.current to HTMLElement
      const node = item.current as HTMLElement

      // Check if the first child of the node and its first child are instances of HTMLElement
      if (
        node.children[0] &&
        node.children[0] instanceof HTMLElement &&
        node.children[0].childNodes[0] instanceof HTMLElement
      ) {
        // Cast the first child's first child to HTMLElement
        const textNode = node.children[0].childNodes[0] as HTMLElement

        // Get the inner text of the textNode
        const text = textNode.innerText

        // Convert the node to an image blob using domtoimage
        domtoimage
          .toBlob(node)
          .then((blob: any) => {
            // Push the blob to the images array
            images.push(blob)
          })
          .then(() => {
            // Add the image blob to the zip file with a filename based on the text
            zip.file(`${text}.jpeg`, images[tmp], { binary: true })
            tmp++
            // If all images are processed, generate the zip file and save it
            if (tmp === arrayOfRefs.length) {
              zip.generateAsync({ type: "blob" }).then((blob) => {
                FileSaver.saveAs(
                  blob,
                  `${title} Analytics - From  ${startDate?.format("DD-MM-YYYY hh-mm A")} To ${endDate?.format(
                    "MM-DD-YYYY hh-mm A"
                  )}.zip`
                )
              })
            }
          })
      }
    }
  })
}

/**
 * Parses error data received from Axios response into an array of error messages.
 * @param data Error data object received from Axios response.
 * @returns Array of error messages formatted as `${key}: ${value}`.
 */
export const parseErrorMessages = (data: any): string[] => {
  return Object.entries(data).map(([key, value]: [string, any]) => `${key}: ${value}`)
}

/**
 * Handles Axios errors, displaying appropriate error notifications.
 * @param error AxiosError object containing error details.
 * @param operationType Specifies whether the error is from a 'query' or 'mutation'.
 */
export const handleAxiosError = (error: AxiosError, operationType: "query" | "mutation") => {
  const defaultMessage = operationType === "mutation" ? "An error occurred. Please try again." : "An error occurred."

  if (error.response?.data) {
    const errorMessages = parseErrorMessages(error.response.data)
    NotificationUtils.toast(errorMessages.join("\n"), {
      snackBarVariant: "negative",
    })
  } else {
    NotificationUtils.toast(defaultMessage, {
      snackBarVariant: "negative",
    })
  }
}

/**
 * Extracts uppercase initials from a full name. If a last name is provided,
 * it uses the first character of the first name and last name. If no last
 * name, it takes the first two characters of the first name (if a space exists)
 * or the first two characters of the first name.
 *
 * @param {string} name - The full name.
 * @returns {string} Uppercase initials.
 */
export const getNameInitials = (name?: string): string => {
  if (!name) return ""
  const trimmedName = name.trim()
  if (trimmedName.includes(" ")) {
    return trimmedName
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
  } else {
    return name.slice(0, 2).toUpperCase()
  }
}

interface Log {
  [key: string]: any // Allow any additional properties
  color?: string // The color property is optional
}

export function sortAndColorizeLogs(data: Log[], countKey: keyof Log): Log[] {
  if (data && data.length > 0) {
    // Sort the array by the 'count' property in descending order
    data.sort((a, b) => (b[countKey] as number) - (a[countKey] as number))

    // Set the color property based on the index in the sorted array
    data.forEach((log, index) => {
      log.color = index === 0 ? "var(--indigo-background-1)" : "var(--indigo-background-2)"
    })
  }

  return data
}

/**
 * Converts raw data into a format suitable for a colored line graph.
 *
 * @param {Array<Object>} data - The raw data to be converted.
 * @param {string} logsKey - The key in the data object that contains the log entries.
 * @param {string} indexByKey - The key in the data object that contains the log entries are indexed by.
 * @param {string} logTimeStampKey - The key in the log entries that contains the timestamp.
 * @param {string} logCountKey - The key in the log entries that contains the count value.
 * @returns {Array<{ id: string, color: string, data: Array<{ x: Date, y: number }> }>}
 * An array of objects formatted for a colored line graph.
 */
export function convertToColoredLineGraphData({
  data,
  logsKey,
  indexByKey,
  logTimeStampKey,
  logCountKey,
}: {
  data?: Array<{ [key: string]: any }>
  logsKey: string
  indexByKey: string
  logTimeStampKey: string
  logCountKey: string
}): Array<{ id: string; color: string; data: Array<{ x: Date; y: number }> }> {
  let tmp: Array<{ id: string; color: string; data: Array<{ x: Date; y: number }> }> = []
  if (!data || data.length < 1) return []
  for (let log of data) {
    if (log[logsKey]) {
      const randomNum = Math.random() * (1 - 0.1) + 0.1
      const randomColor = "#" + Math.floor((randomNum !== 0 ? randomNum : 1) * 16777215).toString(16)
      const logPointsArray: Array<{ x: Date; y: number }> = []

      for (let point of log[logsKey]) {
        if (point[logCountKey] > 0) {
          const logTimeStamp = new Date(point[logTimeStampKey])
          typeof point[logTimeStampKey] === "number" && logTimeStamp.setHours(point[logTimeStampKey], 0, 0, 0)
          logPointsArray.push({ x: logTimeStamp, y: point[logCountKey] })
        }
      }

      // Ensure that data logs are sorted in ascending hour order
      logPointsArray.sort((a, b) => a.x.getTime() - b.x.getTime())

      const formattedObj = {
        id: log[indexByKey],
        color: randomColor,
        data: logPointsArray,
      }

      tmp.push(formattedObj)
    }
  }

  return tmp
}

/**
 * Generates a default checkbox state object from the provided graph data.
 *
 * This function takes an array of ColoredGraphData and returns an object where each key
 * is the ID of a graph data item and its value is a boolean (defaulting to false).
 *
 * @param {ColoredGraphData[]} data - The array of graph data from which to generate default checkbox states.
 * @returns {{ [key: string]: Boolean }} - An object where keys are graph data item IDs and values are false.
 */
export const getDefaultCheckboxes = (
  data: ColoredGraphData[]
): {
  [key: string]: Boolean
} => {
  if (!data || data?.length < 1) return { "": false }

  let statesObj: { [key: string]: Boolean } = {}
  // Loop through each item in graphData and set its default state to false
  for (let log of data) {
    statesObj[log.id] = false
  }
  return statesObj
}

/**
 * Calculates the appropriate date slice for a given date range.
 *
 * @param {Moment | null} startDate - The start date of the range. If `null`, the calculation will not proceed.
 * @param {Moment | null} endDate - The end date of the range. If `null`, the calculation will not proceed.
 * @returns {"hour" | "day" | undefined} - Returns "hour" if the difference between `endDate` and `startDate` is less than 1 day,
 *                                  otherwise returns "day". Returns `undefined` if either `startDate` or `endDate` is `null`.
 */
export const calculateDateSlice = (startDate: Moment | null, endDate: Moment | null): "hour" | "day" | undefined => {
  if (!!startDate && !!endDate) {
    const diff = endDate.diff(startDate, "days")
    return diff < 1 ? "hour" : "day"
  }
  return undefined
}
