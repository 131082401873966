import React, { useState, FC, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import DeleteOutline from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"
import { CircularProgress, Paper } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import MaterialTable, { Action, MTableToolbar } from "@material-table/core"
import { Typography, Button, NotificationUtils } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../API/VisionAPI"
import NoLogs from "../../assets/NoHistoryLogs.svg"
import { EntityRowData } from "../../types/Custom/Interfaces"
import Auth from "../../utils/auth"
import CustomPagination from "./components/CustomPagination"

import styles from "./EntitiesTable.module.scss"

interface Props {
  title: string
  data: EntityRowData[]
  isLoading?: boolean
  maxBodyHeight: string
  type: string
  openEdit: (selectedEntity: EntityRowData) => void
}
const EntitiesTable: FC<Props> = ({ title, data, isLoading, maxBodyHeight, type, openEdit }) => {
  const queryClient = useQueryClient()

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedEntityToDelete, setSelectedEntityToDelete] = useState<EntityRowData>()

  const handleEditEntityOpen = (rowData: EntityRowData) => {
    openEdit(rowData)
  }

  const handleOpenDeleteDialog = (rowData: EntityRowData) => {
    setOpenDeleteDialog(true)
    setSelectedEntityToDelete(rowData)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const { mutate: deleteCarParkingEntity, isLoading: isDeletingParkingEntity } = useMutation(
    (entity: number) => VisionAPI.deleteCarParking(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Car parking entity deleted successfully", {
          snackBarVariant: "positive",
        })
        await queryClient.invalidateQueries("fetchCarParkingEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteCorridor, isLoading: isDeletingCorridorEntity } = useMutation(
    (entity: number) => VisionAPI.deleteCorridor(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Corridor entity deleted successfully", {
          snackBarVariant: "positive",
        })
        await queryClient.invalidateQueries("fetchCorridorEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteDwellingArea, isLoading: isDeletingDwellingAreaEntity } = useMutation(
    (entity: number) => VisionAPI.deleteDwellingArea(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Dwelling area entity deleted successfully", {
          snackBarVariant: "positive",
        })
        await queryClient.invalidateQueries("fetchDwellingAreasEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteGate, isLoading: isDeletingGateEntity } = useMutation(
    (entity: number) => VisionAPI.deleteGate(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Entrance gate entity deleted successfully", {
          snackBarVariant: "positive",
        })
        await queryClient.invalidateQueries("fetchEntranceEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteShop, isLoading: isDeletingShopEntity } = useMutation(
    (entity: number) => VisionAPI.deleteShop(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Tenant entity deleted successfully", {
          snackBarVariant: "positive",
        })
        await queryClient.invalidateQueries("fetchShopsEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const isDeletingEntity =
    isDeletingParkingEntity ||
    isDeletingCorridorEntity ||
    isDeletingDwellingAreaEntity ||
    isDeletingGateEntity ||
    isDeletingShopEntity

  const handleDelete = () => {
    if (selectedEntityToDelete) {
      const { type, id } = selectedEntityToDelete
      switch (type) {
        case "Entrance Gate":
          deleteGate(id)
          break
        case "Tenant":
          deleteShop(id)
          break
        case "Corridor":
          deleteCorridor(id)
          break
        case "Car Parking":
          deleteCarParkingEntity(id)
          break
        default:
          deleteDwellingArea(id)
          break
      }
    }
  }

  let columns = [
    {
      title: "Entity Name",
      field: "name",
      searchable: false,
    },
    { title: "Camera", field: "camera_name", searchable: false },
  ]

  let actions: Action<EntityRowData>[] = [
    {
      icon: () => <EditIcon />,
      tooltip: "Edit Entity",
      onClick: (event: any, data: any) => handleEditEntityOpen(data),
      hidden: type === "Cameras Table" ? true : false,
      position: "row",
    },
    {
      icon: () => <DeleteOutline />,
      tooltip: "Delete Selected Entities",
      onClick: (event: any, data: any) => handleOpenDeleteDialog(data),
      hidden: type === "Cameras Table" ? true : false,
    },
  ]

  return (
    <Fragment>
      <MaterialTable
        isLoading={isLoading}
        title={title}
        columns={columns}
        actions={Auth.getRegionsPermission() ? actions : []}
        data={data}
        options={{
          padding: "default",
          maxBodyHeight: maxBodyHeight,
          defaultExpanded: () => false,
          paging: true,
          pageSize: 15,
          exportAllData: false,
          search: false,
          selection: false,
          actionsColumnIndex: -1,
          showTitle: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: "var(--neutral-background-active)",
            color: "var(--neutral-text-enabled)",
            fontSize: 14,
            fontWeight: 700,
            padding: "12px 24px",
          },
          rowStyle: (rowData, index, level) => {
            if (index % 2) {
              return {
                padding: "16px !important",
                backgroundColor: "var(--greyScale-background-1)",
                borderBottom: 0,
                borderRadius: "4px 4px 4px 4px",
                color: "var(--neutral-text-enabled)",
                fontSize: 16,
                fontWeight: 400,
              }
            } else {
              return {
                padding: "16px !important",
                borderBottom: 0,
                backgroundColor: "var(--greyScale-background-3)",
                borderRadius: "4px 4px 4px 4px",
                color: "var(--neutral-text-enabled)",
                fontSize: 16,
                fontWeight: 400,
              }
            }
          },
        }}
        localization={{
          header: {
            actions: "",
          },
          body: {
            emptyDataSourceMessage: (
              <div className={styles.noLogs}>
                <img src={NoLogs} alt="No Entity Logs" className={styles.noLogsImage} />
                <div>
                  <Typography variant="h2-regular" variantColor={2}>
                    No logs yet
                  </Typography>
                </div>
              </div>
            ),
          },
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                height: "0px",
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Pagination: (props) => {
            return <CustomPagination {...props} />
          },
          Container: (props) => <Paper {...props} elevation={0} className={styles.table} />,
        }}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock={true}
      >
        <DialogTitle id="alert-dialog-title">Delete Entities</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You're about to Delete <strong>{selectedEntityToDelete?.name}</strong>.
          </DialogContentText>
          <DialogContentText>You can't undo this action. Are you sure you want to proceed?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="dangerous">
            Delete {isDeletingEntity && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
export default EntitiesTable
