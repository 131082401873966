import React, { FC, useMemo, Fragment } from "react"

import UnknownIcon from "@mui/icons-material/NoAccounts"
import { Card, Tooltip } from "@mui/material"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"

import adultsIcon from "../assets/adultsIcon.svg"
import childrenIcon from "../assets/childrenIcon.svg"
import femaleIcon from "../assets/femaleIcon.svg"
import maleIcon from "../assets/maleIcon.svg"

import styles from "./GenderAgeCard.module.scss"

interface Props {
  femaleStat?: number
  maleStat?: number
  unknownGenderStat?: number
  childCount?: number
  adultCount?: number
  unknownAgeStat?: number
  dwellAndEnter?: number
  dwellAndLeft?: number
  stoodAndEnter?: number
  stoodAndLeft?: number
  categoryFairShare?: number
  subcategoryFairShare?: number
  graphTooltip?: boolean
  includeDwelling?: boolean
  includeFairShare?: boolean
  pointDate?: string
  loading: boolean
}
const GenderAgeCard: FC<Props> = ({
  femaleStat,
  maleStat,
  unknownGenderStat = 0,
  childCount,
  adultCount,
  unknownAgeStat = 0,
  dwellAndEnter,
  dwellAndLeft,
  stoodAndEnter,
  stoodAndLeft,
  categoryFairShare,
  subcategoryFairShare,
  loading,
  graphTooltip,
  includeDwelling,
  includeFairShare,
  pointDate,
}) => {
  const femalePercentage = useMemo(() => {
    return maleStat && femaleStat ? (femaleStat / (maleStat + femaleStat + unknownGenderStat)) * 100 : 50
  }, [maleStat, femaleStat, unknownGenderStat])

  const malePercentage = useMemo(() => {
    return maleStat && femaleStat ? (maleStat / (maleStat + femaleStat + unknownGenderStat)) * 100 : 100
  }, [maleStat, femaleStat, unknownGenderStat])

  const childrenPercentage = useMemo(() => {
    return childCount && adultCount ? (childCount / (childCount + adultCount + unknownAgeStat)) * 100 : 50
  }, [childCount, adultCount, unknownAgeStat])

  const adultPercentage = useMemo(() => {
    return childCount && adultCount ? (adultCount / (childCount + adultCount + unknownAgeStat)) * 100 : 100
  }, [childCount, adultCount, unknownAgeStat])

  const dwellAndEnterPercentage = useMemo(() => {
    return dwellAndEnter && dwellAndLeft ? (dwellAndEnter / (dwellAndLeft + dwellAndEnter)) * 100 : 50
  }, [dwellAndEnter, dwellAndLeft])

  const stoodAndEnterPercentage = useMemo(() => {
    return stoodAndEnter && stoodAndLeft ? (stoodAndEnter / (stoodAndLeft + stoodAndEnter)) * 100 : 50
  }, [stoodAndEnter, stoodAndLeft])

  const categoryFairSharePercentage = useMemo(() => {
    return categoryFairShare && categoryFairShare > 0 ? (categoryFairShare / (1 + categoryFairShare)) * 100 : 0
  }, [categoryFairShare])

  const subcategoryFairSharePercentage = useMemo(() => {
    return subcategoryFairShare && subcategoryFairShare > 0
      ? (subcategoryFairShare / (subcategoryFairShare + 1)) * 100
      : 0
  }, [subcategoryFairShare])

  return (
    <Card
      className={styles.distributionCard}
      style={{ minWidth: graphTooltip ? 400 : 0, background: graphTooltip ? "var(--greyScale-background-1)" : "" }}
    >
      {graphTooltip && pointDate && (
        <Typography variant="label" variantColor={2} gutterBottom align="center">
          {pointDate}
        </Typography>
      )}
      {/* Gender Distribution */}
      <div>
        <Typography className={styles.distributionTitle} variant="label" variantColor={2}>
          Gender Distribution
        </Typography>
        {loading ? (
          <div className={styles.distributionContainer}>
            <Skeleton variant="rectangular" width="100%" height={26} />
          </div>
        ) : (
          <div className={styles.distributionContainer}>
            {!!femaleStat && (
              <div
                className={`${styles.LeftSideDivision}`}
                style={{
                  width: `${femalePercentage}%`,
                }}
              >
                <Tooltip placement="right" title={`Female : ${femaleStat.toLocaleString()}`}>
                  <div
                    className={styles.leftSideBar}
                    style={{
                      background: "var(--pink-background-2)",
                    }}
                  >
                    <Typography variant="span" variantColor={2}>
                      {femaleStat.toLocaleString()}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            )}

            <div
              className={`${styles.LeftSideDivision}`}
              style={{
                width: `${malePercentage}%`,
              }}
            >
              <Tooltip placement="right" title={`Male : ${!!maleStat ? maleStat?.toLocaleString() : "No data yet"}`}>
                <div
                  className={styles.leftSideBar}
                  style={{
                    background: !!maleStat ? "var(--blue-background-2)" : "var(--indigo-background-2)",
                  }}
                >
                  <Typography variant="span" variantColor={2}>
                    {!!maleStat ? maleStat?.toLocaleString() : "No data yet"}
                  </Typography>
                </div>
              </Tooltip>
            </div>
            {!!unknownGenderStat && (
              <div className={styles.rightSideDivision}>
                <Tooltip placement="right" title={`Unknown gender : ${unknownGenderStat?.toLocaleString()}`}>
                  <div className={styles.rightSideBar} style={{ background: "var(--greyScale-background-2)" }}>
                    <Typography variant="span" variantColor={2}>
                      {unknownGenderStat?.toLocaleString()}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            )}
            <div className={styles.barsDescription}>
              <Typography variant="span" variantColor={2}>
                <img src={femaleIcon} alt="female_icon" className={styles.icon} />
                Female
              </Typography>
              <Typography variant="span" variantColor={2}>
                <img src={maleIcon} alt="male_icon" className={styles.icon} />
                Male
              </Typography>
              {!!unknownGenderStat && (
                <Typography variant="span" variantColor={2} className={styles.unknown}>
                  <UnknownIcon className={styles.icon} fontSize="small" />
                  Unknown
                </Typography>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Age Distribution */}
      <div style={{ marginTop: 12 }}>
        <Typography className={styles.distributionTitle} variant="label" variantColor={2}>
          Age Distribution
        </Typography>
        {loading ? (
          <div className={styles.distributionContainer}>
            <Skeleton variant="rectangular" width="100%" height={26} />
          </div>
        ) : (
          <div className={styles.distributionContainer}>
            {!!childCount && (
              <div
                className={`${styles.LeftSideDivision}`}
                style={{
                  width: `${childrenPercentage}%`,
                }}
              >
                <Tooltip placement="right" title={`Children : ${childCount.toLocaleString()}`}>
                  <div
                    className={styles.leftSideBar}
                    style={{
                      background: "var(--teal-background-2)",
                    }}
                  >
                    <Typography variant="span" variantColor={2}>
                      {childCount.toLocaleString()}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            )}
            <div
              className={`${styles.LeftSideDivision}`}
              style={{
                width: `${adultPercentage}%`,
              }}
            >
              <Tooltip
                placement="right"
                title={`Adults : ${!!adultCount ? adultCount?.toLocaleString() : "No data yet"}`}
              >
                <div
                  className={styles.leftSideBar}
                  style={{
                    background: `${!!adultCount ? "var(--purple-background-2)" : "var(--indigo-background-2)"}`,
                  }}
                >
                  <Typography variant="span" variantColor={2}>
                    {!!adultCount ? adultCount?.toLocaleString() : "No data yet"}
                  </Typography>
                </div>
              </Tooltip>
            </div>
            {!!unknownAgeStat && (
              <div className={styles.rightSideDivision}>
                <Tooltip placement="right" title={`Unknown age : ${unknownAgeStat!.toLocaleString()}`}>
                  <div
                    className={styles.rightSideBar}
                    style={{
                      background: `${
                        !!unknownAgeStat ? "var(--greyScale-background-2)" : "var(--indigo-background-2)"
                      }`,
                    }}
                  >
                    <Typography variant="span" variantColor={2}>
                      {unknownAgeStat?.toLocaleString()}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            )}
            <div className={styles.barsDescription}>
              <Typography variantColor={2} variant="span">
                <img src={childrenIcon} alt="children_icon" className={styles.icon} />
                Children
              </Typography>
              <Typography variantColor={2} variant="span">
                <img src={adultsIcon} alt="adults_icon" className={styles.icon} />
                Adults
              </Typography>
              {!!unknownAgeStat && (
                <Typography variant="span" variantColor={2} className={styles.unknown}>
                  <UnknownIcon className={styles.icon} fontSize="small" />
                  Unknown
                </Typography>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Dwelling Distribution */}
      {includeDwelling && (
        <div style={{ marginTop: 12 }}>
          <Typography className={styles.distributionTitle} variant="label" variantColor={2}>
            Dwelling Distribution
          </Typography>
          {loading ? (
            <div className={styles.distributionContainer}>
              <Skeleton variant="rectangular" width="100%" height={26} />{" "}
            </div>
          ) : (
            <div className={styles.distributionContainer}>
              {!!dwellAndEnter && (
                <div
                  className={`${styles.LeftSideDivision}`}
                  style={{
                    width: `${dwellAndEnterPercentage}%`,
                  }}
                >
                  <Tooltip placement="right" title={`Dwelled and entered : ${dwellAndEnter.toLocaleString()}`}>
                    <div
                      className={styles.leftSideBar}
                      style={{
                        background: "#FFDBCC",
                      }}
                    >
                      <Typography variant="span" variantColor={2}>
                        {dwellAndEnter!.toLocaleString()}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
              )}
              <div className={styles.rightSideDivision}>
                <Tooltip
                  placement="right"
                  title={`Dwelled and left : ${dwellAndLeft! > 0 ? dwellAndLeft!.toLocaleString() : "No data yet"}`}
                >
                  <div
                    className={styles.rightSideBar}
                    style={{ background: !!dwellAndEnter ? "#CCFFD1" : "var(--indigo-background-2)" }}
                  >
                    <Typography variant="span" variantColor={2}>
                      {dwellAndLeft! > 0 ? dwellAndLeft!.toLocaleString() : "No data yet"}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
              <div className={styles.barsDescription}>
                <Typography variantColor={2} variant="span" noWrap>
                  Dwelled and entered
                </Typography>
                <Typography variantColor={2} variant="span" noWrap>
                  Dwelled and left
                </Typography>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Standing Distribution : Tenant Details */}
      {includeDwelling && (
        <>
          <div
            style={{
              marginTop: "12px",
              paddingBottom: "12px",
              borderBottom: includeFairShare ? "2px solid var(--neutral-border-disabled)" : 0,
            }}
          >
            <Typography className={styles.distributionTitle} variant="label" variantColor={2}>
              Standing Distribution
            </Typography>
            {loading ? (
              <div className={styles.distributionContainer}>
                <Skeleton variant="rectangular" width="100%" height={26} />
              </div>
            ) : (
              <div className={styles.distributionContainer}>
                {!!stoodAndEnter && (
                  <div
                    className={`${styles.LeftSideDivision}`}
                    style={{
                      width: `${stoodAndEnterPercentage}%`,
                    }}
                  >
                    <Tooltip placement="right" title={`Stood and entered : ${stoodAndEnter.toLocaleString()}`}>
                      <div
                        className={styles.leftSideBar}
                        style={{
                          background: "#F7F4A6",
                        }}
                      >
                        <Typography variant="span" variantColor={2}>
                          {stoodAndEnter!.toLocaleString()}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                )}
                <div className={styles.rightSideDivision}>
                  <Tooltip
                    placement="right"
                    title={`Stood and left : ${stoodAndLeft! > 0 ? stoodAndLeft!.toLocaleString() : "No data yet"}`}
                  >
                    <div
                      className={styles.rightSideBar}
                      style={{ background: !!stoodAndEnter ? "#DCCCFF" : "var(--indigo-background-2)" }}
                    >
                      <Typography variant="span" variantColor={2}>
                        {stoodAndLeft! > 0 ? stoodAndLeft!.toLocaleString() : "No data yet"}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div className={styles.barsDescription}>
                  <Typography variantColor={2} variant="span" noWrap>
                    Stood and entered
                  </Typography>
                  <Typography variantColor={2} variant="span" noWrap>
                    Stood and left
                  </Typography>
                </div>
              </div>
            )}
          </div>
          {includeFairShare && (
            <>
              <Typography variant="p" variantColor={2} className={styles.cardTitle} align="center">
                Fair Share
              </Typography>
              <div className={styles.fairShareCard}>
                <div>
                  <Typography variant="label" className={styles.fairShareTitle}>
                    Fair Share Category Distribution
                  </Typography>
                  {loading ? (
                    <div className={styles.fairShareContainer}>
                      <Skeleton variant="rectangular" height={26} />
                    </div>
                  ) : (
                    <div className={styles.fairShareContainer}>
                      {categoryFairShare && categoryFairShare > 0 ? (
                        <div className={styles.hiddenDivision}>
                          <div
                            className={styles.hiddenBar}
                            style={{
                              background: "transparent",
                            }}
                          ></div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                              variant="span"
                              variantColor={2}
                              color="positive"
                              className={styles.barDescription}
                              style={{ color: "var(--blue-text-1)" }}
                            >
                              0
                            </Typography>
                            {Math.floor(categoryFairShare) !== 1 && (
                              <Typography
                                variant="span"
                                variantColor={2}
                                color="positive"
                                className={styles.barDescription}
                                style={{ color: "var(--blue-text-1)", marginRight: -8 }}
                              >
                                1.0
                              </Typography>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Fragment></Fragment>
                      )}
                      {!!categoryFairShare && (
                        <div
                          className={styles.fairShareDivision}
                          style={{
                            width: `${categoryFairSharePercentage}%`,
                          }}
                        >
                          <div
                            className={styles.categoryFairShareBar}
                            style={{
                              background:
                                categoryFairShare! < 1 ? "var(--red-background-2)" : "var(--green-background-2)",
                            }}
                          ></div>
                          <Typography className={styles.preBarDescription} variantColor={2} variant="span">
                            {categoryFairShare!.toLocaleString()}
                          </Typography>
                        </div>
                      )}

                      <div className={styles.rightSideDivision}>
                        <div className={styles.rightSideBar} style={{ background: "var(--indigo-background-2)" }}>
                          <Typography variant="span" className={styles.categoryFairShare} variantColor={2}>
                            {(!categoryFairShare || (categoryFairShare && categoryFairShare < 0)) && "No data yet"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: 12 }}>
                  <Typography className={styles.fairShareTitle} variant="label">
                    Fair Share Sub-Category Distribution
                  </Typography>
                  {loading ? (
                    <div className={styles.fairShareContainer}>
                      <Skeleton variant="rectangular" height={26} />
                    </div>
                  ) : (
                    <div className={styles.fairShareContainer}>
                      {subcategoryFairShare && subcategoryFairShare > 0 ? (
                        <div className={styles.hiddenDivision}>
                          <div
                            className={styles.hiddenBar}
                            style={{
                              background: "transparent",
                            }}
                          ></div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                              variant="span"
                              variantColor={2}
                              color="positive"
                              className={styles.barDescription}
                              style={{ color: "var(--blue-text-1)" }}
                            >
                              0
                            </Typography>
                            {Math.floor(subcategoryFairShare) !== 1 && (
                              <Typography
                                variant="span"
                                variantColor={2}
                                color="positive"
                                className={styles.barDescription}
                                style={{ color: "var(--blue-text-1)", marginRight: -8 }}
                              >
                                1.0
                              </Typography>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Fragment></Fragment>
                      )}
                      {!!subcategoryFairShare && (
                        <div
                          className={styles.subCategoryFairShareDivision}
                          style={{
                            width: `${subcategoryFairSharePercentage}%`,
                          }}
                        >
                          <div
                            className={styles.categoryFairShareBar}
                            style={{
                              background:
                                subcategoryFairShare! < 1 ? "var(--red-background-2)" : "var(--green-background-2)",
                            }}
                          ></div>
                          <Typography className={styles.preBarDescription} variantColor={2} variant="span">
                            {subcategoryFairShare!.toLocaleString()}
                          </Typography>
                        </div>
                      )}

                      <div className={styles.rightSideDivision}>
                        <div className={styles.rightSideBar} style={{ background: "var(--indigo-background-2)" }}>
                          <Typography variant="span" variantColor={2} className={styles.subCategoryFairShare}>
                            {!subcategoryFairShare && "No data yet"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Card>
  )
}
export default GenderAgeCard
