// Azka Vision Routes
// Follows the following convention : Persona-screen-description
// E.g. persona : analytics , screen: gates live => analytics-gates-live
export const routes = {
  home: "dashboard",
  subscriptionEnd: "subscription-ended",
  noPermissions: "no-permissions",
  campaign: "marketing-campaign-planner",
  404: "404",

  // Analytics Routes
  branchesAnalytics: "analytics-multiple-branches",
  cameraHeatmap: "analytics-camera-heatmap",
  dwelling: "analytics-dwelling-areas",
  dwellingAreas: "analytics-dwelling-areas",
  dwellingAreaDetails: "analytics-dwelling-area-details",
  gates: "analytics-entrance-gates",
  liveGates: "analytics-gates-live",
  historyGates: "analytics-gates-history",
  tenants: "analytics-tenants",
  tenantsList: "analytics-tenants-list",
  tenantDetails: "analytics-tenant-details",
  missingData: "analytics-missing-data",
  corridors: "analytics-corridors",
  carsAnalytics: "analytics-cars-statistics",
  heatmapHistory: "analytics-heatmap-history",
  floorsMCP: "analytics-floors-MCP",
  camerasMCP: "analytics-cameras-MCP",

  // Admin Routes
  cameraHealth: "admin-camera-health",
  newCamera: "admin-camera-list/add-new-camera",
  cameraList: "admin-camera-list",
  cameraDetails: "admin-camera-list/admin-camera-details",
  cameraViewChanges: "admin-camera-view-changes",
  entitiesAndRegions: "admin-entities-and-regions",
  nodesList: "admin-nodes-list",
  uploadFootage: "admin-camera-list/uploadFootage",
  users: "admin-users-panel",
  createUser: "admin-users-panel/admin-create-user",
  carsList: "admin-cars-lists",
  categoriesList: "admin-categories-list",
  floors: "admin-floor",
  apiIntegration: "admin-API-integration",

  // Security Routes
  carsLogs: "security-cars-logs",
  liveStreams: "security-live-streams",
  securityIncidents: "security-incidents",

  // Mixed/Other Routes
  heatmapLive: "floors-heatmap/analytics-heatmap-live",
}
