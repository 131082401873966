import React, { FC, useEffect, useMemo } from "react"
import { useQuery } from "react-query"

import { Card, Grid } from "@mui/material"

import { InputText, RadioButton, RadioButtonGroup, Select } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps } from "formik"

import { VisionAPI } from "../../../API/VisionAPI"
import { INewEntity } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./EntityForm.module.scss"

type Category = definitions["Category"]
type ActiveModules = definitions["ActiveModules"]

interface Props {
  formik: FormikProps<INewEntity>
  edit?: boolean
  categories?: Category[]
  isLoadingCategories?: boolean
}

const EntityForm: FC<Props> = ({ formik, categories, isLoadingCategories, edit }) => {
  // fetch weather codes for single campaign
  const { data: activeModules } = useQuery<ActiveModules, AxiosError>(
    "fetchActiveModules",
    VisionAPI.fetchActiveModules
  )

  const entityTypes = useMemo(() => {
    const entities = ["Entrance Gate", "Corridor", "Car Parking"]
    if (activeModules?.tenants) {
      entities.splice(1, 0, "Tenant")
    }
    if (activeModules?.dwelling) {
      entities.splice(-1, 0, "Dwelling Area")
    }
    return entities
  }, [activeModules])

  const adjustSubcategories = (categoryNumber: any) => {
    if (edit || categoryNumber !== formik.values.EntityCategory) {
      const selectedCategory: any = categories?.find((category: any) => category.id === categoryNumber)
      if (selectedCategory) {
        formik.setFieldValue("subcategories", selectedCategory?.subcategories)
        !edit && formik.setFieldValue("EntitySubCategory", undefined)
        formik.setFieldTouched("EntitySubCategory", false)
      }
    }
  }

  // adjust subcategories of selected category in edit mode
  useEffect(() => {
    if (
      edit &&
      (formik.values.EntityType === "Tenant" || formik.values.EntityType === "Dwelling Area") &&
      categories &&
      !isLoadingCategories
    ) {
      adjustSubcategories(formik.values?.EntityCategory)
    }
    // eslint-disable-next-line
  }, [edit, formik.values.EntityCategory, formik.values.EntityType, categories, isLoadingCategories])

  return (
    <Card className={styles.entityFormCard}>
      <div className={styles.EntityFormContent}>
        <RadioButtonGroup
          id="Entity Type"
          name="Entity Type"
          onChange={formik.handleChange}
          label="Entity Type"
          alignment="horizontal"
          spacing={3}
          style={{ marginBottom: 8, display: edit ? "none" : "" }}
          hideDescription
        >
          {entityTypes.map((entity, index) => (
            <RadioButton
              key={index}
              value={entity}
              checked={formik.values.EntityType === entity}
              label={entity}
              id={entity}
              readOnly
            />
          ))}
        </RadioButtonGroup>
        <Grid container spacing={1}>
          <Grid
            item
            md={formik.values.EntityType !== "Entrance Gate" && formik.values.EntityType !== "Car Parking" ? 6 : 12}
            sm={12}
          >
            <InputText
              value={formik?.values.EntityName}
              id="EntityName"
              required
              label="Entity Name"
              placeholder="E.g. Adidas Store"
              fullWidth
              handleBlur={formik.handleBlur}
              error={formik.touched.EntityName && Boolean(formik.errors.EntityName) && formik.errors.EntityName}
              handleChange={formik.handleChange}
            />
          </Grid>
          {(formik.values.EntityType === "Tenant" || formik.values.EntityType === "Dwelling Area") && (
            <Grid item md={6} sm={12}>
              <Select
                value={formik?.values.EntityCategory}
                id="EntityCategory"
                label="Category"
                key={`selected_${formik?.values.EntityCategory}`}
                required
                placeholder="E.g. Fashion"
                fullWidth
                handleBlur={formik.handleBlur}
                error={
                  !formik.values.EntityCategory &&
                  formik.touched.EntityCategory &&
                  Boolean(formik.errors.EntityCategory) &&
                  formik.errors.EntityCategory
                }
                handleChange={(e) => {
                  const selectedCategory = e.target.value
                  formik.setFieldValue("EntityCategory", selectedCategory)
                  adjustSubcategories(selectedCategory)
                }}
                optionsWithValues={categories?.map((category: Category) => {
                  return {
                    label: category.name,
                    value: category.id!,
                  }
                })}
              />
            </Grid>
          )}
          {(formik.values.EntityType === "Corridor" || formik.values.EntityType === "Tenant") && (
            <Grid item md={6} sm={12}>
              <InputText
                value={formik?.values.EntityArea || ""}
                id="EntityArea"
                type="number"
                required
                label="Area"
                placeholder="E.g. 500"
                fullWidth
                handleBlur={formik.handleBlur}
                error={formik.touched.EntityArea && Boolean(formik.errors.EntityArea) && formik.errors.EntityArea}
                handleChange={formik.handleChange}
              />
            </Grid>
          )}
          {(formik.values.EntityType === "Tenant" || formik.values.EntityType === "Dwelling Area") && (
            <Grid item md={6} sm={12}>
              <Select
                value={formik?.values.EntitySubCategory}
                key={`subcategories_of_${formik.values.EntityCategory}`}
                id="EntitySubCategory"
                disabled={!formik?.values.EntityCategory}
                label="Sub-Category"
                required
                placeholder="E.g. Shoes"
                fullWidth
                handleBlur={formik.handleBlur}
                error={
                  formik.touched.EntitySubCategory &&
                  Boolean(formik.errors.EntitySubCategory) &&
                  formik.errors.EntitySubCategory
                }
                handleChange={(e) => {
                  formik.setFieldValue("EntitySubCategory", e.target.value)
                }}
                optionsWithValues={
                  formik.values.subcategories &&
                  formik.values.subcategories.map((subcategory) => {
                    return {
                      label: subcategory.name,
                      value: subcategory.id,
                    }
                  })
                }
                disabledTipTitle="Please Select A Category First"
                disabledToolTipPlacement="bottom"
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Card>
  )
}
export default EntityForm
