import React from "react"

import { Box } from "@mui/material"

import { ResponsiveLine } from "@nivo/line"
import { Typography, Select } from "@synapse-analytics/synapse-ui"

import Placeholder from "../../../assets/placeholder.svg"

import styles from "./CampaignGraph.module.scss"

const CampaignGraph = ({ convertDataToGraph, campaign, handleChangeDays, day }) => {
  const campaignData = convertDataToGraph(campaign)
  const campaignDays = campaign?.goal_achieved_per_day

  return (
    <div className={styles.graphWrapper}>
      <div className={styles.daysWrapper}>
        <Typography variant="h3-regular" className={styles.graphTitle}>
          Hourly line Count in
        </Typography>

        {/* Campaign daily [24hour] Counts graph */}
        {day && (
          <div className={styles.formControl}>
            <Select
              id="days Select"
              value={day}
              label="Campaign in days"
              handleChange={handleChangeDays}
              placeholder="Select A day"
              description="Pick a day"
              options={campaignDays && campaignDays?.map((campaignDay) => campaignDay?.day)}
              autosize
            />
          </div>
        )}
      </div>

      {campaignData && campaignData?.length > 0 ? (
        <Box sx={{ height: "400px" }}>
          <ResponsiveLine
            data={campaignData}
            tooltip={({ point }) => {
              return (
                <div
                  style={{
                    background: "white",
                    color: "inherit",
                    fontSize: "inherit",
                    borderRadius: "2px",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                    padding: "5px 9px",
                  }}
                >
                  <div>
                    <span>Time: {point.data.x}</span>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                      <tbody>
                        <tr key={point.id}>
                          <td style={{ padding: "3px 5px" }}>
                            <span
                              key="chip"
                              style={{
                                display: "block",
                                width: "12px",
                                height: "12px",
                                background: point.serieColor,
                              }}
                            />
                          </td>
                          <td style={{ padding: "3px 5px" }}>Count in:</td>
                          <td style={{ padding: "3px 5px" }}>
                            <strong key="value">{point.data.yFormatted}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            }}
            margin={{ top: 50, right: 20, bottom: 60, left: 50 }}
            enablePoints={false}
            // I switched the file to js because Nivo not supported all types
            curve="cardinal"
            xFormat="time:%d %b %I %p"
            yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (v) => (v.substring(0, v.length - 2) % 2 === 0 ? v : ""),
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "counts",
              legendOffset: -40,
              legendPosition: "middle",
              format: (e) => Math.floor(e) === e && e,
            }}
            pointSize={10}
            colors={{ scheme: "category10" }}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        </Box>
      ) : (
        <div className={styles.noLogs}>
          <img src={Placeholder} alt="NoData" className={styles.noLogsImage} />
          <Typography variant="h3-regular" align="center">
            No data to display
          </Typography>
        </div>
      )}
    </div>
  )
}

export default CampaignGraph
