import React, { FC, useEffect, useState, Fragment, ChangeEvent, useMemo } from "react"
import { useQuery } from "react-query"

import { Grid, SvgIconProps } from "@mui/material"

import { Switch, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"
import { NumberParam, useQueryParam, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import PaginatedBarGraph from "../../../components/GraphCards/PaginatedBarGraphCard"
import Insights from "../../../components/Insights"
import { useBranchesStore } from "../../../store"
import { definitions } from "../../../types/Generated/apiTypes"
import gatesIcon from "../assets/gate.svg"
import peopleGroupIcon from "../assets/peopleGroup.svg"
import AvgCountWeek from "./AvgCountWeek"
import HourlyAvgData from "./HourlyAvgData"
import HourlyData from "./HourlyData"
import NumericStats from "./NumericStats"
import TotalCountsHeatmap from "./TotalCountsHeatmap"
import WeekDayFilter from "./WeekDayFilter"

import styles from "./CounterGates.module.scss"

type Gate = definitions["Gate"]

type MissingData = definitions["MissingSliceCounterLog"]
type CounterLogs = definitions["CounterLogs"]
type InsightsData = definitions["CounterInsights"]

type FormattedInsights = InsightsData & {
  icon?: SvgIconProps
  color?: string
  variation?: string
}
type refs = {
  lineGraphRef: any
  gatesGraphRef: any
  avgGraphRef: any
  hourlyAvgGraphRef: any
  heatmapGraphRef: any
  visitorsRef: any
  occupancyRef: any
}
interface Props {
  isLive: boolean
  startDate?: string
  endDate?: string
  timeGrain: string | null
  interval?: Duration
  shouldIncludeMissingData?: boolean
  shouldIncludeStaff?: boolean
  setLoadingState?: (isLoading: boolean) => void
  handleSwitchMissingData?: (event: ChangeEvent<HTMLInputElement>) => void
  handleSwitchStaffInclusion?: (event: ChangeEvent<HTMLInputElement>) => void
  refs: refs
}

const CounterGates: FC<Props> = ({
  isLive,
  startDate,
  endDate,
  timeGrain,
  interval,
  setLoadingState,
  shouldIncludeMissingData,
  shouldIncludeStaff,
  handleSwitchMissingData,
  handleSwitchStaffInclusion,
  refs,
}) => {
  const [selectedDay, setSelectedDay] = useQueryParam("day", withDefault(NumberParam, null))

  const [formattedInsightsData, setFormattedInsightsData] = useState<FormattedInsights[]>()
  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const { data: counterLogs, isLoading: gateLogsLoading } = useQuery<CounterLogs[]>(
    [
      "fetchGatesLogs",
      startDate, // 1
      endDate, // 2
      timeGrain, // 3
      selectedBranch, // 4
      shouldIncludeStaff, // 5
      !!selectedDay && selectedDay >= 0 && selectedDay <= 6 // 6
        ? selectedDay + 1
        : null,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchGatesCounterStats({
        from_date: queryKey[1] as string, // start date
        to_date: queryKey[2] as string, // end date
        date_slice: queryKey[3] as string, // time grain
        branch: queryKey[4] as number, // selected branch
        include_staff: queryKey[5] as boolean, // staff filter
        week_day: queryKey[6] as number, // selected week day filter
      }),
    {
      enabled: !!endDate && !!timeGrain && !!selectedBranch,
      refetchInterval: isLive ? 30000 : false,
    }
  )
  const { data: lastWeekLogs, isLoading: lastWeekLoading } = useQuery<CounterLogs[]>(
    [
      "fetchGatesStatsLastWeek",
      moment().subtract(7, "days").format("YYYY-MM-DD"),
      moment().subtract(7, "days").format("YYYY-MM-DD"),
      "hour",
      selectedBranch,
      shouldIncludeStaff,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchGatesCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        include_staff: queryKey[5] as boolean,
      }),
    {
      enabled: !!isLive && !!selectedBranch,
    }
  )
  const { data: avgCounterLogs, isLoading: avgLogsLoading } = useQuery<CounterLogs[]>(
    [
      "fetchAvgGatesLogs",
      startDate, // 1
      endDate, // 2
      "hour", // 3
      selectedBranch, // 4
      shouldIncludeStaff, // 5
      !!selectedDay && selectedDay >= 0 && selectedDay <= 6 // 6
        ? selectedDay + 1
        : null,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchGatesCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        include_staff: queryKey[5] as boolean,
        week_day: queryKey[6] as number,
      }),
    {
      enabled:
        !isLive &&
        !!endDate &&
        !!timeGrain &&
        !!interval &&
        (interval!.days! >= 7 || interval!.months! >= 1) &&
        !!selectedBranch,
    }
  )
  const { data: insightsData, isLoading: insightsDataLoading } = useQuery<InsightsData[]>(
    ["fetchCounterInsights", startDate, endDate, selectedBranch],
    ({ queryKey }) =>
      VisionAPI.fetchCounterInsights({
        from_dt: queryKey[1] as string,
        to_dt: queryKey[2] as string,
        branch: queryKey[3] as number,
      }),
    {
      enabled: !!endDate && !isLive && !!selectedBranch,
    }
  )

  const { data: entranceEntitiesData, isLoading: entranceEntitiesLoading } = useQuery<Gate[], AxiosError>(
    ["fetchEntranceEntities", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchEntrances({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  // Use useMemo to format Gates
  const entranceEntities = useMemo(() => {
    let entranceEntityObj: {
      [key: string]: Gate
    } = {}
    entranceEntitiesData?.forEach((gate: Gate) => (entranceEntityObj[gate.id!] = { ...gate }))
    return entranceEntityObj
  }, [entranceEntitiesData])

  const { data: missingData, isLoading: missingDataLoading } = useQuery<MissingData[]>(
    ["fetchGatesMissingLogs", startDate, endDate, timeGrain, selectedBranch],
    ({ queryKey }) =>
      VisionAPI.fetchMissingCounterLogs({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        branch: queryKey[4] as number,
        entities_type: "gate",
      }),
    {
      enabled: !!endDate && !!timeGrain && shouldIncludeMissingData && !isLive && !!selectedBranch,
    }
  )

  // a universal loading state that is true if any of logs are loading
  useEffect(() => {
    if (setLoadingState) {
      if ([avgLogsLoading, gateLogsLoading, lastWeekLoading, missingDataLoading].some((element) => element === true)) {
        setLoadingState(true)
      } else {
        setLoadingState(false)
      }
    }
  }, [avgLogsLoading, gateLogsLoading, lastWeekLoading, missingDataLoading, setLoadingState])

  // format insights data to add icon and color to each log depending on its type
  useEffect(() => {
    if (!insightsDataLoading) {
      const insightsDataArr: FormattedInsights[] = []
      if (insightsData && insightsData.length > 0)
        for (let log of insightsData) {
          let formattedLog: FormattedInsights = { ...log }
          formattedLog.color = "#292D33"
          // specifying if it is an increase or decrease or a variation (trending icon specification)
          if (log.type.includes("increase")) {
            formattedLog.variation = "increase"
          } else if (log.type.includes("decrease")) {
            formattedLog.variation = "decrease"
          } else {
            formattedLog.variation = "vary"
          }
          if (log.type.includes("count")) {
            formattedLog.icon = <img src={peopleGroupIcon} alt="people_group_icon" />
          } else {
            formattedLog.icon = <img src={gatesIcon} alt="gates_icon" />
          }
          insightsDataArr.push(formattedLog)
        }
      // to update insights data on changing from live to history and keep it while picking date
      setFormattedInsightsData((prevValue) => (endDate !== null ? insightsDataArr.reverse() : prevValue))
    }
  }, [insightsData, insightsDataLoading, endDate])

  const handleDaySelected = (dayValue: number) => {
    if (selectedDay === dayValue) {
      setSelectedDay(null)
    } else {
      setSelectedDay(dayValue)
    }
  }

  return (
    <div className={styles.wrapper}>
      {!isLive && (
        <WeekDayFilter
          handleSelectDay={handleDaySelected}
          selectedDay={selectedDay}
          startDate={startDate}
          endDate={endDate}
          interval={interval}
        />
      )}
      {!isLive && <Insights data={formattedInsightsData} loading={insightsDataLoading} />}
      <div className={styles.titleWrapper}>
        <Typography variant="a" variantColor={2}>
          Stats
        </Typography>
        <div className={styles.switches}>
          <Switch checked={shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
          {!isLive && (
            <Switch
              checked={shouldIncludeMissingData}
              onChange={handleSwitchMissingData}
              label="Include missing data"
            />
          )}
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <NumericStats
            isLive={isLive}
            logsData={counterLogs!}
            loading={[gateLogsLoading, lastWeekLoading].some((element) => element === true)}
            lastWeekData={isLive ? lastWeekLogs : undefined}
            visitorsRef={refs?.visitorsRef}
            occRef={refs?.occupancyRef}
          />
        </Grid>
        <Grid item lg={9} md={8} sm={12} xs={12}>
          <HourlyData
            timeGrain={timeGrain === "hour" ? "hour" : "day"}
            loading={[gateLogsLoading, entranceEntitiesLoading, missingDataLoading].some((element) => element === true)}
            logsData={counterLogs!}
            missingData={shouldIncludeMissingData ? missingData : []}
            entranceEntities={entranceEntities}
            reference={refs?.lineGraphRef}
            interval={interval!}
            dayToFilter={selectedDay}
            shouldIncludeMissingData={shouldIncludeMissingData}
            isGates
          />
        </Grid>
      </Grid>
      {/* Bar Chart Top Performing Gates */}
      <PaginatedBarGraph
        logsData={counterLogs!}
        loading={[gateLogsLoading, entranceEntitiesLoading, missingDataLoading].some((element) => element === true)}
        entranceEntities={entranceEntities}
        reference={refs?.gatesGraphRef}
        endDate={endDate}
        startDate={startDate}
        key={isLive ? "live" : "history"}
        shouldIncludeMissingData={shouldIncludeMissingData}
        missingData={shouldIncludeMissingData ? missingData : []}
      />
      {/* Advanced Analytics for interval > 28 days */}
      {!isLive && (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <AvgCountWeek
                logsData={avgCounterLogs!}
                loading={avgLogsLoading}
                interval={interval!}
                reference={refs?.avgGraphRef}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12} sm={12}>
              <HourlyAvgData
                logsData={avgCounterLogs!}
                loading={avgLogsLoading}
                interval={interval!}
                reference={refs?.hourlyAvgGraphRef}
                dayToFilter={selectedDay}
              />
            </Grid>
          </Grid>
          <TotalCountsHeatmap
            logsData={avgCounterLogs!}
            loading={avgLogsLoading}
            interval={interval!}
            reference={refs?.heatmapGraphRef}
          />
        </Fragment>
      )}
    </div>
  )
}
export default CounterGates
